import { FinancialVerificationIntegration } from 'app/models/options/enums';
import React from 'react';
import { Field } from 'redux-form';
import { FinicityConnectComponent, TruvConnectComponent } from '.';

export const FinancialVerificationConnection = ({ ...props }: any) => {
  const { label, name, title, showIf, values } = props;
  const show = showIf ? showIf : [];
  const renderComponent =
    values.financialVerificationIntegration === FinancialVerificationIntegration.TRUV ? TruvConnectComponent
      : (values.financialVerificationIntegration === FinancialVerificationIntegration.FINICITY ? FinicityConnectComponent : undefined)
      || undefined;
  return (
    <Field
      component = {renderComponent}
      label     = {label}
      name      = {name}
      title     = {title}
      type      = 'text'
      showIf    = {show}
    />
  );
};
