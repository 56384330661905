import { TFunction } from 'i18next';
import { routes } from 'app/routes/route-list';
import { Question } from 'app/models/types';
import { I18NFormat } from 'app/i18n/format';
import { FieldName } from 'app/models/fields/names';
import { FormOption } from 'app/models/options/options';

export type LocalizeNamespace = FormContext;

export enum FormContext {
  AGRICULTURAL           = 'AG',
  AGLINEOFCREDIT         = 'AGLOC',
  AGPURCHASEEQIPMENT     = 'AGPE',
  AGREFINANCEPROPERTY    = 'AGRP',
  AGPURCHASEACREAGE      = 'AGPA',
  AUTOLOAN               = 'ATO',
  AUTOPREQUAL            = 'APQ',
  AUTOPREQUALEDIT        = 'APQE',
  AUTOPREQUALRENEW       = 'APQR',
  COMMERCIALLANDING      = 'CLP',
  COMMERCIALBRIDGELOAN   = 'COBL',
  COMMERCIALEQUIPMENT    = 'COEQ',
  COMMERCIALPURCHASE     = 'CP',
  COMMERCIALREFINANCE    = 'CR',
  COMMERCIALVEHICLE      = 'COVE',
  CONTACT                = 'CON',
  CLOSINGCOSTPURCHASE    = 'CCP',
  CLOSINGCOSTREFINANCE   = 'CCR',
  CREDITBOOSTER          = 'CB',
  CREDITCARD             = 'CC',
  FINANCIAL_STATEMENT    = 'FS',
  HOME_EQUITY_RATES      = 'HERA',
  HOME_EQUITY_TURBO      = 'HEXP',
  LFCONSTRUCTION         = 'LFCO',
  LFHOMEEQUITY           = 'LFHE',
  LFLAND                 = 'LFLA',
  LFLANDING              = 'LFL',
  LFPURCHASE             = 'LFP',
  LFREFINANCE            = 'LFR',
  PURCHASE_TURBO         = 'PTU',
  REFERRAL               = 'REF',
  REFINANCE_TURBO        = 'RTU',
  REVERSE                = 'REV',
  SBLINEOFCREDIT         = 'SBLC',
  SBTERM                 = 'SBTE',
  SERVLANDING            = 'SVL',
  SERVPURCHASING         = 'SBP',
  SERVREFINANCE          = 'SBR',
  SFHOMEEQUITY           = 'SFHE',
  OVERDRAFTPROTECTION    = 'OP',
  PERSONALLOAN           = 'PL',
  OTHER_VEHICLE          = 'OV',
  EXPRESS                = 'XP',
  SFPURCHASE             = 'SFP',
  SFREFINANCE            = 'SFR',
}

/**
 * Takes in a pathname and returns a matching FormContext
 * If no matching FormContext is found, 'global' is returned
 *
 * @param {string} pathname
 * @returns {(FormContext | 'global')}
 */
export const getFormContextFromPath = (pathname: string): FormContext | 'global' => {
  const map = {
    [routes.agricultural]            : FormContext.AGRICULTURAL,
    [routes.agLineOfCredit]          : FormContext.AGLINEOFCREDIT,
    [routes.agPurchaseEquipment]     : FormContext.AGPURCHASEEQIPMENT,
    [routes.agRefinanceProperty]     : FormContext.AGREFINANCEPROPERTY,
    [routes.agPurchaseAcreage]       : FormContext.AGPURCHASEACREAGE,
    [routes.application]             : FormContext.LFLANDING,
    [routes.applicationConstruction] : FormContext.LFCONSTRUCTION,
    [routes.applicationHomeEquity]   : FormContext.LFHOMEEQUITY,
    [routes.applicationLand]         : FormContext.LFLAND,
    [routes.applicationPurchase]     : FormContext.LFPURCHASE,
    [routes.applicationRefinance]    : FormContext.LFREFINANCE,
    [routes.autoLoan]                : FormContext.AUTOLOAN,
    [routes.autoPrequal]             : FormContext.AUTOPREQUAL,
    [routes.autoPrequalEdit]         : FormContext.AUTOPREQUALEDIT,
    [routes.autoPrequalRenew]        : FormContext.AUTOPREQUALRENEW,
    [routes.commercial]              : FormContext.COMMERCIALLANDING,
    [routes.commercialBridgeLoan]    : FormContext.COMMERCIALBRIDGELOAN,
    [routes.commercialEquipment]     : FormContext.COMMERCIALEQUIPMENT,
    [routes.commercialPurchase]      : FormContext.COMMERCIALPURCHASE,
    [routes.commercialRefinance]     : FormContext.COMMERCIALREFINANCE,
    [routes.commercialVehicle]       : FormContext.COMMERCIALVEHICLE,
    [routes.closingCostsPurchase]    : FormContext.CLOSINGCOSTPURCHASE,
    [routes.closingCostsRefinance]   : FormContext.CLOSINGCOSTREFINANCE,
    [routes.contact]                 : FormContext.CONTACT,
    [routes.creditBooster]           : FormContext.CREDITBOOSTER,
    [routes.creditCard]              : FormContext.CREDITCARD,
    [routes.employeeReferral]        : FormContext.REFERRAL,
    [routes.financialStatement]      : FormContext.FINANCIAL_STATEMENT,
    [routes.homeEquity]              : FormContext.SFHOMEEQUITY,
    [routes.homeEquityRates]         : FormContext.HOME_EQUITY_RATES,
    [routes.homeEquityTurbo]         : FormContext.HOME_EQUITY_TURBO,
    [routes.otherVehicle]            : FormContext.OTHER_VEHICLE,
    [routes.overdraftProtection]     : FormContext.OVERDRAFTPROTECTION,
    [routes.personalLoan]            : FormContext.PERSONALLOAN,
    [routes.purchase]                : FormContext.SFPURCHASE,
    [routes.purchaseTurbo]           : FormContext.PURCHASE_TURBO,
    [routes.refinance]               : FormContext.SFREFINANCE,
    [routes.refinanceTurbo]          : FormContext.REFINANCE_TURBO,
    [routes.reverse]                 : FormContext.REVERSE,
    [routes.sbLineOfCredit]          : FormContext.SBLINEOFCREDIT,
    [routes.sbTerm]                  : FormContext.SBTERM,
  };
  return map[pathname] || 'global';
};

/**
 * Localizes an array of options.
 *
 * @remarks
 * This allows for localizing/modifying option titles.
 *
 * @TODO [i18n] Change the `defaultOptions.map` to `t('question.name.options).map()`)
 * The `defaultOptions.map` is currently being used to help with setting the initial
 * resource bundle values
 *
 * @param {(FieldName | string)} name
 * @param {TFunction} t
 * @param {FormOption<any>[]} [defaultOptions=[]]
 * @returns {FormOption<any>[]}
 */
export const localizeOptions = (name: FieldName | string, t: TFunction, defaultOptions: FormOption<any>[] = []): FormOption<any>[] => {
  return defaultOptions.map((option, idx) => {
    return {
      title   : t(`question.${name}.options.${option.value}`, { defaultValue: option.title }),
      value   : option.value,
    };
  });
};

/**
 * Localizes a field label
 *
 * @param {(FieldName | string)} name
 * @param {TFunction} t
 * @param {(string | undefined)} label
 * @returns {string}
 */
export const localizeLabel = (name: FieldName | string, t: TFunction, label: string = ''): string => {
  return label && t(`question.${name}.label`, { defaultValue: label });
};

/**
 * Localizes a form field value for display
 *
 * @example
 * localizeDisplayValue(FieldNames.loanAmount, t, 10000, 'currency.0') // $10,000
 * localizeDisplayValue(FieldNames.loanAmount, t, 10000) // 10000
 *
 * @remarks
 * This is useful in situations where clients may want values formatted differently.
 * (ie: A percentage with 3 decimals or 2)
 *
 * @param {(FieldName | string)} name FieldName
 * @param {TFunction} t
 * @param {*} displayValue Form Value
 * @param {I18NFormat} [format]
 * @returns {string}
 */
export const localizeDisplayValue = (name: FieldName | string, t: TFunction, displayValue: any, format?: I18NFormat): string => {
  let interpolate = 'displayValue';
  if (format) {
    /* ie displayValue, currency.0 */
    interpolate += `, ${format}`;
  }
  return t(`question.${name}.displayValue`, {
    defaultValue: `{{ ${interpolate} }}`,
    displayValue,
  });
};

/**
 * Iterates over the array of questions localizing any questions that
 * have a truthy value on the disclaimer, label, options, popover, or title keys.
 *
 * @param {Question[]} [questions=[]]
 * @param {TFunction} t
 * @returns {any[]}
 */
export const localizeQuestions = (questions: Question[] = [], t: TFunction): any[] => {
  return questions.map((question) => {
    const { label, popover, title, name, options, disclaimer } = question;
    return {
      ...question,
      disclaimer: disclaimer && t(`question.${name}.disclaimer`, { defaultValue: disclaimer }),
      label     : localizeLabel(name, t, label),
      options   : options && localizeOptions(name, t, options),
      popover   : popover && t(`question.${name}.popover`, { defaultValue: popover }),
      title     : title   && t(`question.${name}.title`, { defaultValue: title }),
    };
  });
};
