import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'app/components/Button';
import { ClientLink } from '../reducers/app-config';

type ClientLinkButtonProps = {
  links : Partial<ClientLink>[];

};

const ClientLinkButtonsComponent = (props: ClientLinkButtonProps) => {
  const { links } = props;

  return (
    <>
      {
        links.map(({ title, url }) => {
          return <>
            <Button
              classes = { { root: 'longButton' } }
              color   = 'primary'
              variant = 'contained'
              rel     = 'noopener'
              target  = '_blank'
              href    = { url }
            >
              { title }
            </Button>
            <br/>
          </>;
        })
      }
    </>
  );
};

export const ClientLinkButtons = connect()(ClientLinkButtonsComponent);
