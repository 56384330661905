import { reducer, formValueSelector } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { FormName } from '@lenderful/domain';

// Reducer
// -------
// @see https://redux.js.org/basics/reducers#reducers
export const formReducer = reducer;

// Selectors
// ---------
// @see https://github.com/reduxjs/reselect#reselect
// @see https://redux.js.org/recipes/computing-derived-data
// @see https://redux.js.org/introduction/learning-resources#selectors
export const homeEquityValueSelector = formValueSelector(FormName.HOMEEQUITY);
export const longFormValueSelector   = formValueSelector(FormName.LONGFORM);
export const loanAmountSelector      = (state) => homeEquityValueSelector(state, FieldNames.loanAmount);
