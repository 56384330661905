import React from 'react';
import {
  colors,
  createStyles,
  Grid,
  Typography,
  withStyles,
  WithStyles,
  Theme,
} from '@material-ui/core';
import { FieldNames } from 'app/models/fields/names';
import { useTranslation } from 'react-i18next';
import * as FormActions from 'app/actions/form/actions';
import { InjectedFormProps, isInvalid } from 'redux-form';
import { Popover } from 'app/components/Popover';
import { Loader } from 'app/components/Loader';
import { Brick } from 'app/components/Brick';
import { connect, DispatchProp } from 'react-redux';
import { localizeLabel } from 'app/i18n/helpers';
import { PurchaseDownPayment } from 'app/components/FormFields/PurchaseDownPayment';
import { Button } from 'app/components/Button';
import PreQualAmountIcon from '../../assets/img/modern-architecture.svg';
import { FormName } from '@lenderful/domain';

const styles = (theme: Theme) => createStyles({
  accentHeader: {
    background: theme.palette.secondary.main,
    padding   : '1rem',
    textAlign : 'center',
  },
  contentWrapper: {
    padding: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      padding: '1.75rem 1rem 0 1rem',
    },
  },
  iconWrapper: {
    width : '50px',
    margin: '0 auto 5px',
  },
  bricksWrapper: {
    padding: '0 .5rem',
    margin : '1.5rem 0 4rem',
    width  : '100%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '3rem',
      padding     : 0,
      borderTop   : `1px solid ${colors.grey[300]}`,
    },
  },
  mainTitle: {
    marginTop   : 0,
    marginBottom: '.5rem',
    display     : 'block',
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  brickWrapper: {
    padding: '.75rem',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  brickDisplay: {
    width     : '100%',
    fontWeight: 500,
    fontSize  : 18,
  },
  brickControls: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft   : 0,
      textAlign     : 'center',
      justifyContent: 'center',
      marginTop     : '1rem',
    },
  },
  brickLabel: {
    fontSize       : 16,
    lineHeight     : '16px',
    fontWeight     : 400,
    letterSpacing  : '.2px',
    marginBottom   : '.25rem',
    transform      : 'translate(0, 1.5px) scale(0.85)',
    transformOrigin: 'top left',
  },
  subTitle: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  editableField: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '5px',
    },
  },
});

interface Props extends WithStyles<typeof styles>, Pick<InjectedFormProps, 'change' | 'valid' | 'invalid'>, DispatchProp {
  values    : any;
  handleAutoPrequalRecalculateRates: (args) => Promise<void>
}

const PrequalAmountComponent = withStyles(styles)(({ classes, values = {}, ...props }: Props) => {
  const { t }                                 = useTranslation();
  const [isEdit, setIsEdit]                   = React.useState(false);
  const [localSubmitting, setLocalSubmitting] = React.useState(false);
  const prequalAmount                         = values[FieldNames.prequalAmount];
  const downDollar                            = values[FieldNames.downDollar];
  const downPercent                           = downDollar / prequalAmount;
  const loanAmount                            = prequalAmount - downDollar;
  const prequalAmountIcon                     = `${window.location.origin}${PreQualAmountIcon}`;
  const editButtonText                        = isEdit ?
    t('prequalAmount.buttonSave', { defaultValue: 'Save' }):
    t('prequalAmount.buttonEdit', { defaultValue: 'Edit' });
  const downPaymentPopoverText = t('prequalAmount.downPaymentPopover', {
    defaultValue: 'You can increase your down payment to lower your loan amount and decrease your monthly payment. If you have been pre-qualified for an amount less than you requested, increasing your down payment may allow for a higher pre-qualification.',
  });
  const loanAmountPopoverText = t('prequalAmount.loanAmountPopover', {
    defaultValue:
      'The loan amount is the difference between your home purchase price (pre-qualified amount) and your down payment. Increasing your down payment will lower the loan amount.',
  });
  const handleSaveDownPayment = () => {
    setLocalSubmitting(true);
    /* Recalculate prequal amount with new down payment */
    props.handleAutoPrequalRecalculateRates(values).then(() => {
      setLocalSubmitting(false);
      setIsEdit(false);
    }).catch(() => {
      setLocalSubmitting(false);
    });
  };
  const handleEditDownPayment = () => {
    setIsEdit(true);
  };
  return (
    <Grid container>
      <Grid item xs={12} className={classes.accentHeader}>
        <Grid container>
          {localSubmitting && <Loader />}
          <Grid item xs={12}>
            <img src={prequalAmountIcon} width="100%" alt="Modern Architecture icon" className={classes.iconWrapper} />
          </Grid>
          <Grid item xs={12}>
            <Typography classes={{ root: 'smallTitle white' }}>
              {t('prequalAmount.intro', {
                defaultValue: "You're Pre-Qualified Up To:",
              })}
            </Typography>
            <Typography variant="h4" classes={{ root: 'bold white' }}>
              {t('prequalAmount.prequalAmount', {
                defaultValue: '{{ prequalAmount, currency.0 }}',
                prequalAmount,
              })}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentWrapper}>
        <Typography variant="h6" align="center" className={classes.mainTitle}>
          {t('prequalAmount.title', { defaultValue: 'You\'re almost ready to go!' })}
        </Typography>
        <Typography variant="body2" align="center" className={classes.subTitle}>
          {t('prequalAmount.subtitle', { defaultValue: 'Your buying factors:' })}
        </Typography>
        <Grid container className={classes.bricksWrapper}>
          <Grid item xs={12} md={5}className={classes.brickWrapper}>
            <Brick>
              <Grid container>
                <Grid item xs={12}>
                  <Typography variant="body1" className={classes.brickLabel}>
                    {localizeLabel(FieldNames.loanAmount, t, 'Loan Amount')}
                    <Popover text={loanAmountPopoverText} />
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2" className={classes.brickDisplay}>
                    {t('prequalAmount.displayLoanAmount', {
                      defaultValue: '{{ loanAmount, currency.0 }}',
                      loanAmount,
                    })}
                  </Typography>
                </Grid>
              </Grid>
            </Brick>
          </Grid>
          <Grid item xs={12} md={7} className={classes.brickWrapper}>
            <Brick>
              <Grid container alignItems="center">
                <Grid item xs={12} md={9} className={classes.editableField}>
                  {isEdit ? (
                    <PurchaseDownPayment
                      {...props}
                      values    = {values}
                      validator = {{
                        type: 'downPaymentGreaterThanInitial',
                      }}
                      hideHomeValue                 = {true}
                      title                         = {null}
                      hideTitle                     = {true}
                      downPaymentFormControlClasses = {{ root: 'noMargin' }}
                      // @TODO: [LP-1280] Refactor PurchaseDownPayment popover to be on downPercent field but with nonbreaking/fixed styling
                    />
                  ) : (
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography variant="body1" className={classes.brickLabel}>
                          {localizeLabel(FieldNames.downDollar, t, 'Down Payment')}
                          <Popover text={downPaymentPopoverText} />
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="body2" className={classes.brickDisplay}>
                          {t('prequalAmount.downPayment', {
                            defaultValue:
                              '{{ downDollar, currency.0}} ({{ downPercent, percent.0  }})',
                            downDollar,
                            downPercent,
                          })}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  item
                  container
                  xs         = {12}
                  md         = {3}
                  className  = {classes.brickControls}
                  alignItems = "center"
                  justifyContent    = "flex-end"
                >
                  <Button
                    classes = {{ sizeSmall: 'brickButton' }}
                    size    = "small"
                    onClick = {isEdit ? handleSaveDownPayment : handleEditDownPayment}
                    variant = "contained"
                    disabled= {props.invalid || localSubmitting}
                  >
                    {editButtonText}
                  </Button>
                </Grid>
              </Grid>
            </Brick>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

const mapStateToProps = (state) => {
  return {
    invalid: isInvalid(FormName.AUTOPREQUAL)(state),
  };
};

const mapDispatchToProps = FormActions;

export const PrequalAmount = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrequalAmountComponent);
