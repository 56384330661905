import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  AGRICULTURAL_PE_BUSINESS_INFO,
  AGRICULTURAL_PE_EQUIPMENT_INFO,
  AGRICULTURAL_PE_EXISTING_AG_CUSTOMER,
  AGRICULTURAL_PE_BORROWER_EMPLOYMENT,
  AGRICULTURAL_PE_BORROWER_HOME_INFO,
  AGRICULTURAL_PE_BORROWER_INCOME_SOURCE,
  AGRICULTURAL_PE_BORROWER_LIVING_SITUATION, AGRICULTURAL_PE_BORROWER_QUESTIONS,
  AGRICULTURAL_PE_BORROWER_RENTER_INFO,
  AGRICULTURAL_PE_BORROWER_RETIREMENT,
  AGRICULTURAL_PE_BUSINESS_FINANCIALS,
  AGRICULTURAL_PE_BUSINESS_OWNERS,
  AGRICULTURAL_PE_EXISTING_CUSTOMER_INFO,
  AGRICULTURAL_PE_FINAL,
  AGRICULTURAL_PE_LOAN_HOLDER,
  AGRICULTURAL_PE_PERSONAL_INFO,
} from 'app/models/sections/constants';
import { AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS } from '../questions/commercial-agricultural-purchase-equipment';
import {
  isEmployee, isEmploymentOther,
  isExistingAG,
  isHomeowner,
  isRenting, isRetired,
  isSelfEmployed,
} from '../fields/conditionals';
import { getCommercialBridgeLoanQuestionsByName } from '../questions/commercial-bridge-loan';
import { getCommercialDeclarationQuestions } from '../questions/declarations';
import { ConfirmationCommercial } from '../../components/ConfirmationCommercial';
import { handleAGFormSubmit } from '../../actions/form/actions';
import { BorrowerType } from '@lenderful/domain';

export const getAgriculturalPurchaseEquipmentSectionById = (id: number): Section => {
  return AGRICULTURAL_PURCHASE_EQUIPMENT_SECTIONS.find((section) => section.id === id) as Section;
};

const AGRICULTURAL_PURCHASE_EQUIPMENT_SECTIONS: Section[] = [
  {
    id        : AGRICULTURAL_PE_EQUIPMENT_INFO,
    title     : 'Tell us more about your Ag equipment purchase',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.agEquipmentType,
      FieldNames.commercialDescription,
      FieldNames.commercialExpectedCost,
      FieldNames.downDollar,
      FieldNames.commercialLifeExpectancy,
      FieldNames.commercialLoanTiming,
    ]),
  },
  {
    id             : AGRICULTURAL_PE_EXISTING_AG_CUSTOMER,
    questions      : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.isAGExistingCustomer,
    ]),
  },
  {
    id        : AGRICULTURAL_PE_BUSINESS_INFO,
    title     : 'Tell us about your business',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialEmployeeCount,
      FieldNames.commercialEntityType,
      FieldNames.commercialYearStarted,
    ]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BUSINESS_FINANCIALS,
    title     : 'Tell us about your business financials',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.commercialRevenueTrend3,
      FieldNames.commercialRevenueLastYear,
      FieldNames.commercialRevenueExplanation,
      FieldNames.commercialProfitTrend3,
      FieldNames.commercialProfitExplanation,
      FieldNames.commercialCollateralList,
    ]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BUSINESS_OWNERS,
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_LOAN_HOLDER,
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.commercialLoanHolder,
    ]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id       : AGRICULTURAL_PE_PERSONAL_INFO ,
    title    : 'Enter your personal information',
    questions: AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BORROWER_LIVING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([FieldNames.livingRentOrOwn]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id       : AGRICULTURAL_PE_BORROWER_HOME_INFO,
    title    : 'Provide your homeowner information',
    questions: AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    showIf: [(values) => isHomeowner(values) && !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BORROWER_RENTER_INFO,
    title     : 'Provide your renter information',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
    ]),
    showIf : [(values) => isRenting(values) && !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([FieldNames.employmentType]),
    showIf: [(values) => !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BORROWER_EMPLOYMENT,
    title     : 'Tell us about your income and employment',
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.commercialEmploySameBusiness,
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf       : [(values) => (isEmployee(values) || isSelfEmployed(values)) && !isExistingAG(values)],
  },
  {
    id        : AGRICULTURAL_PE_BORROWER_RETIREMENT,
    questions : AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS.getByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf       : [(values) => (isRetired(values) ||  isEmploymentOther(values)) && !isExistingAG(values)],
  },
  {
    id           : AGRICULTURAL_PE_EXISTING_CUSTOMER_INFO,
    title        : 'Please enter your Loan and Business Information',
    questions    : getCommercialBridgeLoanQuestionsByName([
      FieldNames.existingLoanNumber,
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
    ]),
    showIf       : [isExistingAG],
    submitAction : handleAGFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : AGRICULTURAL_PE_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getCommercialDeclarationQuestions(BorrowerType.PRIMARY),
    showIf: [(values) => !isExistingAG(values)],
    submitAction : handleAGFormSubmit,
    submitText   : 'Submit',
  },
  {
    id              : AGRICULTURAL_PE_FINAL,
    ifNoQuestions   : ConfirmationCommercial,
    hideProgressBar : true,
  },
];
