import React, { lazy } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { RootState } from 'app/store/types';
import { ajaxOrderAndBridgeToken } from 'app/actions/form/verification/actions';
import { FieldNames } from 'app/models/fields/names';
import { goToSection } from '../../actions/form/actions';
import { getVerificationOrder, getVerificationUser } from 'app/reducers/verification';
import { getFormName } from 'app/routes/helpers';
import {
  HOME_EQUITY_TURBO_BORROWER_DECLARATION,
  HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_DECLARATION_QUESTIONS,
  PURCHASE_TURBO_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_BORROWER_DECLARATION,
  REFINANCE_TURBO_EMPLOYMENT_TYPE,
} from 'app/models/sections/constants';
import { useState } from 'react';
import { hasExitedTruv, isAssetVerificationComplete, isIncomeVerificationComplete } from 'app/models/fields/conditionals';
import { FormName } from '@lenderful/domain';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps;

const TruvConnectBaseComponent = (props: Props) => {

  const TruvBridge = lazy(() => import('@truv/react'));

  const [isOpened, setOpened] = useState(false);

  const {
    ajaxCreateOrderToken,
    exitTruv,
    incomeComplete,
    assetsComplete,
    formName,
    formData,
    order,
  } = props;

  React.useEffect(() => {
    if (!hasExitedTruv(formData)) {
      const createOrderAndBridgeToken = async () => {
        return await ajaxCreateOrderToken();
      };
      createOrderAndBridgeToken();
    }
  }, [ajaxCreateOrderToken, formName]);

  React.useEffect(() => {

    if (order && !isOpened) {
      setOpened(true);
    }
  }, [isOpened, order]);

  return (
    <>
      <TruvBridge
        bridgeToken = {order?.bridge_token}
        isOrder     = {true}
        onClose     = {() => {
          setOpened(false);
          exitTruv(formName, formData);
        }}
        onSuccess = {(public_token, metadata) => {
          console.debug('----  onSuccess');
        }}
        onEvent = {(type, payload) => {
          if (type === 'UNSUPPORTED_BROWSER') {
            exitTruv(formName, formData);
          }
          if (type === 'SCREEN_VIEW' && payload.view_name === 'SUCCESS') {
            if (payload.product_type === 'income') {
              incomeComplete(formName);
            }
            if (payload.product_type === 'assets') {
              assetsComplete(formName);
            }
          }
        }}
        isOpened = {isOpened}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  ajaxCreateOrderToken: async () => {
    return dispatch(await ajaxOrderAndBridgeToken());
  },
  exitTruv : (formName, formData) => {
    dispatch(change(formName, FieldNames.hasExitedTruv, true));
    dispatch(change(formName, FieldNames.isFinancialVerificationComplete, true));

    let redirectLocation;
    if (isIncomeVerificationComplete(formData) && isAssetVerificationComplete(formData)) {
      switch (formName) {
        case FormName.HOME_EQUITY_TURBO:
          redirectLocation = HOME_EQUITY_TURBO_BORROWER_DECLARATION;
          break;
        case FormName.REFINANCE_TURBO:
          redirectLocation = REFINANCE_TURBO_BORROWER_DECLARATION;
          break;
        case FormName.PURCHASE_TURBO:
          redirectLocation = PURCHASE_TURBO_DECLARATION_QUESTIONS;
      }
    } else {
      switch (formName) {
        case FormName.HOME_EQUITY_TURBO:
          redirectLocation = HOME_EQUITY_TURBO_EMPLOYMENT_TYPE;
          break;
        case FormName.REFINANCE_TURBO:
          redirectLocation = REFINANCE_TURBO_EMPLOYMENT_TYPE;
          break;
        case FormName.PURCHASE_TURBO:
          redirectLocation = PURCHASE_TURBO_EMPLOYMENT_TYPE;
      }
    }

    return dispatch(goToSection(redirectLocation));
  },
  incomeComplete: (formName) => {
    dispatch(change(formName, FieldNames.isIncomeVerificationComplete, true));
  },
  assetsComplete: (formName) => {
    dispatch(change(formName, FieldNames.isAssetVerificationComplete, true));
  },
});

const mapStateToProps = (state: RootState) => {
  const formName = getFormName(state.router.location.pathname);
  return {
    formName,
    order   : getVerificationOrder(state),
    user    : getVerificationUser(state) || undefined,
    formData: getFormValues(formName)(state),
  };
};

export const TruvConnectComponent = connect(mapStateToProps, mapDispatchToProps)(TruvConnectBaseComponent);
