import { NotificationLevel } from 'app/providers/notifications/Notification';
import { useNotifications } from 'app/providers/notifications/NotificationProvider';
import { isRouteEnabled } from 'app/reducers/app-config';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

/**
 * This component is only responsible for checking to see if the client has access
 * to the current route/form.  If, not we display a disabled message to the user.
 */
const RouteCheckComponent = (props: ReturnType<typeof mapStateToProps>) => {
  const { displayNotification } = useNotifications();

  useEffect(() => {
    if (!props.isRouteEnabled) {
      displayNotification('This route is currently disabled', NotificationLevel.ERROR);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRouteEnabled]);

  return <></>;
};

const mapStateToProps = (state: any) => ({
  isRouteEnabled: isRouteEnabled(state),
});

export default connect(mapStateToProps)(RouteCheckComponent);