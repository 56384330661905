import { FieldNames } from 'app/models/fields/names';
import { Section } from 'app/models/types';
import { handleAgFormTypeSelect } from '../../actions/form/actions';
import { AGRICULTURAL_LOAN_TYPE } from './constants';
import { getAgriculturalQuestionsByName } from 'app/models/questions/commercial-agricultural';

export const getAgriculturalSectionById = (id: number): Section => {
  return AGRICULTURAL_SECTIONS.find((section) => section.id === id) as Section;
};

const AGRICULTURAL_SECTIONS: Section[] = [
  {
    id              : AGRICULTURAL_LOAN_TYPE,
    questions       : getAgriculturalQuestionsByName([FieldNames.loanType]),
    hideBackButton  : true,
    hideProgressBar : true,
    submitAction    : handleAgFormTypeSelect,
    submitText      : 'Next',
  },
];
