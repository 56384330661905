/**
 * Notification to display to the user
 */
export type Notification = {
  /**
   * message to display
   */
  message: string;
  /**
   * standardization around colors for the notification based on level
   */
  level?: NotificationLevel;
  /**
   * custom icon to display in notification
   */
  icon?: JSX.Element;
}

/**
 * tyoes of notifications
 */
export enum NotificationLevel {
  SUCCESS = 'SUCCESS',
  WARN  = 'WARN',
  ERROR = 'ERROR',
}