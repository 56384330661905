import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  isEmployee,
  isEmploymentOther,
  isExistingCommercial,
  isHomeowner,
  isRenting,
  isRetired,
  isSelfEmployed,
} from 'app/models/fields/conditionals';
import { ConfirmationCommercial } from 'app/components/ConfirmationCommercial';
import { getCommercialDeclarationQuestions } from '../questions/declarations';
import {
  COMMERCIAL_EQUIPMENT_TYPE,
  COMMERCIAL_EQUIPMENT_LOAN_INFO,
  COMMERCIAL_EQUIPMENT_BUSINESS_INFO,
  COMMERCIAL_EQUIPMENT_BUSINESS_FINANCIALS,
  COMMERCIAL_EQUIPMENT_BUSINESS_MANAGEMENT,
  COMMERCIAL_EQUIPMENT_LOAN_HOLDER,
  COMMERCIAL_EQUIPMENT_PERSONAL_INFO,
  COMMERCIAL_EQUIPMENT_BORROWER_LIVING_SITUATION,
  COMMERCIAL_EQUIPMENT_BORROWER_HOME_INFO,
  COMMERCIAL_EQUIPMENT_BORROWER_RENTER_INFO,
  COMMERCIAL_EQUIPMENT_BORROWER_INCOME_SOURCE,
  COMMERCIAL_EQUIPMENT_BORROWER_EMPLOYMENT,
  COMMERCIAL_EQUIPMENT_BORROWER_RETIREMENT,
  COMMERCIAL_EQUIPMENT_BORROWER_QUESTIONS,
  COMMERCIAL_EQUIPMENT_FINAL,
  COMMERCIAL_INVENTORY_EXPECTED_COSTS,
  COMMERCIAL_EQUIPMENT_EXPECTED_COSTS,
  COMMERCIAL_EQUIPMENT_DESCRIPTION,
  COMMERCIAL_INVENTORY_DESCRIPTION,
  COMMERCIAL_EQUIPMENT_DOWN_PAYMENT,
  COMMERCIAL_EQUIPMENT_BUSINESS_NEED,
  COMMERCIAL_INVENTORY_BUSINESS_NEED,
  COMMERCIAL_INVENTORY_SHELF_LIFE,
  COMMERCIAL_EQUIPMENT_LIFE_EXPECTANCY,
  COMMERCIAL_EQUIPMENT_PURCHASE_TIMING,
  COMMERCIAL_EQUIPMENT_BUSINESS_OWNERS,
  COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER,
  COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER_INFO,
} from 'app/models/sections/constants';
import {
  getCommercialEquipmentQuestionsById,
  getCommercialEquipmentQuestionsByName,
} from '../questions/commercial-equipment';
import { FormTerms } from '../../components/FormAgreements/FormTerms';
import React from 'react';
import { handleCommercialBackButton, handleCommercialFormSubmit } from 'app/actions/form/actions';
import { getCommercialBridgeLoanQuestionsByName } from '../questions/commercial-bridge-loan';
import { BorrowerType } from '@lenderful/domain';

export const getCommercialEquipmentSectionById = (id: number): Section => {
  return COMMERCIAL_EQUIPMENT_SECTIONS.find((section) => section.id === id) as Section;
};

const COMMERCIAL_EQUIPMENT_SECTIONS: Section[] = [
  {
    id        : COMMERCIAL_EQUIPMENT_TYPE,
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialEquipmentOrInventory,
    ]),
    agreement: <FormTerms />,
    hideBackButton: handleCommercialBackButton,
  },
  {
    id        : COMMERCIAL_EQUIPMENT_LOAN_INFO,
    questions : getCommercialEquipmentQuestionsById([
      COMMERCIAL_INVENTORY_EXPECTED_COSTS,
      COMMERCIAL_EQUIPMENT_EXPECTED_COSTS,
      COMMERCIAL_EQUIPMENT_DESCRIPTION,
      COMMERCIAL_INVENTORY_DESCRIPTION,
      COMMERCIAL_EQUIPMENT_DOWN_PAYMENT,
      COMMERCIAL_EQUIPMENT_BUSINESS_NEED,
      COMMERCIAL_INVENTORY_BUSINESS_NEED,
      COMMERCIAL_INVENTORY_SHELF_LIFE,
      COMMERCIAL_EQUIPMENT_LIFE_EXPECTANCY,
      COMMERCIAL_EQUIPMENT_PURCHASE_TIMING,
    ]),
  },
  {
    id             : COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER,
    questions      : getCommercialEquipmentQuestionsByName([
      FieldNames.isCommercialExistingCustomer,
    ]),
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BUSINESS_INFO,
    title     : 'Tell us about your business',
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialDBA,
      FieldNames.commercialCensusTract,
      FieldNames.commercialNAICSCode,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialEmployeeCount,
      FieldNames.commercialWebsite,
      FieldNames.commercialEntityType,
      FieldNames.commercialBusinessType,
      FieldNames.commercialStateOfIncorporation,
      FieldNames.commercialYearStarted,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BUSINESS_FINANCIALS,
    title     : 'Tell us about your business financials',
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialRevenueTrend3,
      FieldNames.commercialRevenueLastYear,
      FieldNames.commercialRevenueExplanation,
      FieldNames.commercialProfitTrend3,
      FieldNames.commercialProfitExplanation,
      FieldNames.commercialCollateralList,
      FieldNames.commercialBusinessOwnershipType,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BUSINESS_OWNERS,
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BUSINESS_MANAGEMENT,
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.hasOtherManagement,
      FieldNames.commercialManagementList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_LOAN_HOLDER,
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialLoanHolder,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id       : COMMERCIAL_EQUIPMENT_PERSONAL_INFO ,
    title    : 'Enter your personal information',
    questions: getCommercialEquipmentQuestionsByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BORROWER_LIVING_SITUATION,
    title     : 'What is your current housing situation?',
    questions : getCommercialEquipmentQuestionsByName([FieldNames.livingRentOrOwn]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id       : COMMERCIAL_EQUIPMENT_BORROWER_HOME_INFO,
    title    : 'Provide your homeowner information',
    questions: getCommercialEquipmentQuestionsByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    showIf: [(values) => isHomeowner(values) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BORROWER_RENTER_INFO,
    title     : 'Provide your renter information',
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
    ]),
    showIf : [(values) => isRenting(values) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BORROWER_INCOME_SOURCE,
    title     : 'What is your income source?',
    questions : getCommercialEquipmentQuestionsByName([FieldNames.employmentType]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BORROWER_EMPLOYMENT,
    title     : 'Tell us about your income and employment',
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.commercialEmploySameBusiness,
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf       : [(values) => (isEmployee(values) || isSelfEmployed(values)) && !isExistingCommercial(values)],
  },
  {
    id        : COMMERCIAL_EQUIPMENT_BORROWER_RETIREMENT,
    questions : getCommercialEquipmentQuestionsByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf       : [(values) => (isRetired(values) ||  isEmploymentOther(values)) && !isExistingCommercial(values)],
  },
  {
    id           : COMMERCIAL_EQUIPMENT_EXISTING_CUSTOMER_INFO,
    title        : 'Please enter your Loan and Business Information',
    questions    : getCommercialBridgeLoanQuestionsByName([
      FieldNames.existingLoanNumber,
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
    ]),
    showIf       : [isExistingCommercial],
    submitAction : handleCommercialFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : COMMERCIAL_EQUIPMENT_BORROWER_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getCommercialDeclarationQuestions(BorrowerType.PRIMARY),
    showIf: [(values) => !isExistingCommercial(values)],
    submitAction : handleCommercialFormSubmit,
    submitText   : 'Submit',
  },
  {
    id              : COMMERCIAL_EQUIPMENT_FINAL,
    ifNoQuestions   : ConfirmationCommercial,
    hideProgressBar : true,
  },
];
