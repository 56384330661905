import { BorrowerType } from '@lenderful/domain';
import { isEmployee, isEmploymentOther, isExistingCommercial, isHomeowner, isRenting, isRetired, isSelfEmployed } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { getCommercialDeclarationQuestions } from 'app/models/questions/declarations';
import {
  AGRICULTURAL_ABOUT_BUSINESS,
  AGRICULTURAL_ABOUT_BUSINESS_FINANCIALS,
  AGRICULTURAL_BORROWER_QUESTIONS,
  AGRICULTURAL_EXISTING_CUSTOMER,
  AGRICULTURAL_EXISTING_LOAN_AND_BUSINESS, AGRICULTURAL_FINAL,
  AGRICULTURAL_HOUSING_INFORMATION,
  AGRICULTURAL_HOUSING_SITUATION,
  AGRICULTURAL_INCOME_AND_EMPLOYMENT,
  AGRICULTURAL_LOAN_HOLDER,
  AGRICULTURAL_LOC_LOAN_INFO,
  AGRICULTURAL_OWNERS_LIST,
  AGRICULTURAL_PERSONAL_INFO,
  AGRICULTURAL_RENTER_INFORMATION,
  AGRICULTURAL_RETIREMENT_OR_OTHER_INCOME,
  AGRICULTURAL_TYPE_OF_INCOME,
} from 'app/models/sections/constants';
import { Section } from 'app/models/types';
import { ConfirmationCommercial } from '../../components/ConfirmationCommercial';
import { AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS } from '../questions/commercial-agricultural-line-of-credit';
import { handleAGFormSubmit } from '../../actions/form/actions';

export const getAgriculturalLineOfCreditSectionById = (id: number): Section => {
  return AGRICULTURAL_LINE_OF_CREDIT_SECTIONS.find((section) => section.id === id) as Section;
};

const AGRICULTURAL_LINE_OF_CREDIT_SECTIONS: Section[] = [
  {
    id: AGRICULTURAL_LOC_LOAN_INFO,
    title: 'Tell us more about your Ag Loan needs',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.loanAmount,
      FieldNames.commercialLoanPurpose,
      FieldNames.commercialLoanTiming,
    ]),
  },
  {
    id: AGRICULTURAL_EXISTING_CUSTOMER,
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.isAGExistingCustomer,
    ]),
  },
  {
    id: AGRICULTURAL_ABOUT_BUSINESS,
    title: 'Tell us about your business',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.commercialPhone,
      FieldNames.commercialEmployeeCount,
      FieldNames.commercialEntityType,
      FieldNames.commercialYearStarted,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_ABOUT_BUSINESS_FINANCIALS,
    title: 'Tell us about your business financials',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.commercialRevenueTrend3,
      FieldNames.commercialRevenueLastYear,
      FieldNames.commercialRevenueExplanation,
      FieldNames.commercialProfitTrend3,
      FieldNames.commercialProfitExplanation,
      FieldNames.commercialCollateralList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_OWNERS_LIST,
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.commercialOwnerList,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_LOAN_HOLDER,
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.commercialLoanHolder,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_PERSONAL_INFO,
    title: 'Enter your personal information',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.email,
      FieldNames.phone,
      FieldNames.dateOfBirth,
    ]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_HOUSING_SITUATION,
    title: 'What is your current housing situation?',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([FieldNames.livingRentOrOwn]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_HOUSING_INFORMATION,
    title: 'Provide your homeowner information',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
    ]),
    showIf: [(values) => isHomeowner(values) && !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_RENTER_INFORMATION,
    title: 'Provide your renter information',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
    ]),
    showIf: [(values) => isRenting(values) && !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_TYPE_OF_INCOME,
    title: 'What is your income source?',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([FieldNames.employmentType]),
    showIf: [(values) => !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_INCOME_AND_EMPLOYMENT,
    title: 'Tell us about your income and employment',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.commercialEmploySameBusiness,
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employPhone,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
    ]),
    showIf: [(values) => (isEmployee(values) || isSelfEmployed(values)) && !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_RETIREMENT_OR_OTHER_INCOME,
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [(values) => (isRetired(values) || isEmploymentOther(values)) && !isExistingCommercial(values)],
  },
  {
    id: AGRICULTURAL_EXISTING_LOAN_AND_BUSINESS,
    title: 'Please enter your Loan and Business Information',
    questions: AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS.getByName([
      FieldNames.existingLoanNumber,
      FieldNames.commercialBusinessName,
      FieldNames.commercialStreet,
      FieldNames.commercialStreet2,
      FieldNames.commercialCity,
      FieldNames.commercialState,
      FieldNames.commercialZip,
      FieldNames.firstName,
      FieldNames.lastName,
      FieldNames.email,
      FieldNames.phone,
    ]),
    showIf: [isExistingCommercial],
    submitAction : handleAGFormSubmit,
    submitText: 'Submit',
  },
  {
    id: AGRICULTURAL_BORROWER_QUESTIONS,
    title: 'Borrower Questions',
    questions: getCommercialDeclarationQuestions(BorrowerType.PRIMARY),
    submitAction : handleAGFormSubmit,
    submitText: 'Submit',
  },
  {
    id              : AGRICULTURAL_FINAL,
    ifNoQuestions   : ConfirmationCommercial,
    hideProgressBar : true,
  },
];
