import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  REFINANCE_TURBO_ANNUAL_COSTS,
  REFINANCE_TURBO_AUTOMATIC_VOIE,
  REFINANCE_TURBO_BORROWER_DECLARATION,
  REFINANCE_TURBO_CASH_AT_CLOSING,
  REFINANCE_TURBO_CASH_AT_CLOSING_FINAL,
  REFINANCE_TURBO_CITIZENSHIP_MILITARY,
  REFINANCE_TURBO_COBORROWER_CITIZENSHIP,
  REFINANCE_TURBO_COBORROWER_DECLARATION,
  REFINANCE_TURBO_COBORROWER_EMPLOYEE_INFO,
  REFINANCE_TURBO_COBORROWER_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_COBORROWER_HOUSING_INFO,
  REFINANCE_TURBO_COBORROWER_PERSONAL_INFO,
  REFINANCE_TURBO_COLLECT_SSN,
  REFINANCE_TURBO_COLLECT_SSN_FINAL,
  REFINANCE_TURBO_COLLECT_SSN_QUESTION,
  REFINANCE_TURBO_CREDIT_AUTHORIZATION,
  REFINANCE_TURBO_CURRENT_LOAN_INFO,
  REFINANCE_TURBO_EMPLOYEE_INFO,
  REFINANCE_TURBO_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_ESTIMATED_CREDIT,
  REFINANCE_TURBO_LOAN_PURPOSE,
  REFINANCE_TURBO_MARITAL_STATUS_DEPENDENTS,
  REFINANCE_TURBO_MIN_LOAN_STOP_GATE,
  REFINANCE_TURBO_NO_OPTIMAL_PROGRAMS,
  REFINANCE_TURBO_PERSONAL_INFO,
  REFINANCE_TURBO_PRIMARY_HOME,
  REFINANCE_TURBO_PROPERTY_INFO,
  REFINANCE_TURBO_PROPERTY_TYPE,
  REFINANCE_TURBO_PROPERTY_VALUE_RANGE,
  REFINANCE_TURBO_REAL_ESTATE_LIABILITIES,
  REFINANCE_TURBO_REO_PRIMARY_QUESTIONS,
  REFINANCE_TURBO_REO_SECONDARY_QUESTIONS,
  REFINANCE_TURBO_RETIRED_INFO,
  REFINANCE_TURBO_UPLOAD_DOCUMENTS,
  REFINANCE_TURBO_USPS_VERIFICATION,
  REFINANCE_TURBO_VOIE_REPORTS,
  REFINANCE_TURBO_VERIFICATION_CONNECT,
} from 'app/models/sections/constants';
import {
  ajaxValidateBorrowerResidence,
  ajaxValidateCoBorrowerResidence,
  ajaxValidateSubjectProperty,
} from 'app/actions/form/address-validation/actions';
import {
  canVerifyOfIncomeEmployment,
  coBorrowerHasDifferentAddress,
  hasAvmHomeValue,
  hasCoBorrower,
  hasRoomForCashOut,
  isBorrowerUnderage,
  isCoBorrowerEmployee,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoBorrowerSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isExcludingSsnQuestions,
  isManualIncomeAndEmploymentRequired,
  isPrimaryHome,
  isRetired,
  isSelfEmployed,
  isTakingCashOut,
  LoanOfficerCondition,
} from 'app/models/fields/conditionals';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import { FormParagraphs } from 'app/models/paragraphs';
import { UploadDocumentsStep } from 'app/components/Turbo/UploadDocumentsStep';
import { getTurboDeclarationQuestions } from 'app/models/questions/declarations';
import { AddressVerified } from 'app/components/Turbo/AddressVerified';
import { MinimumLoanAmount } from '../../components/StopGates/MinimumLoanAmount';
import {
  handleGoToFinancialVerificationPreStep,
  handleRefinanceTurboFinalAdjustments,
  handleRefinanceTurboFormSubmit,
  handleRefinanceTurboGetFinalRates,
  handleRefinanceTurboGetRates,
} from '../../actions/form/turbo/actions';
import { handleGetSubjectPropertyInfo } from 'app/actions/form/property/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';
import { REFINANCE_TURBO_QUESTIONS } from '../questions/refinance-turbo';
import { ajaxPostCreditReportForRefinanceTurbo } from '../../actions/form/credit-report/actions';
import { ajaxGetFinancialVerificationUrl } from '../../actions/form/verification/actions';
import { NoOptimalPrograms } from 'app/components/Turbo/NoOptimalPrograms';
import { BorrowerType } from '@lenderful/domain';

export const getRefinanceTurboSectionById = (id: number): Section => {
  return REFINANCE_TURBO_SECTIONS.find((section) => section.id === id) as Section;
};

const REFINANCE_TURBO_SECTIONS: Section[] = [
  {
    id        : REFINANCE_TURBO_LOAN_PURPOSE,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.loanPurpose,
    ]),
  },
  {
    id        : REFINANCE_TURBO_PROPERTY_TYPE,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.propertyUsage,
      FieldNames.propertyType,
    ]),
  },
  {
    id            : REFINANCE_TURBO_ESTIMATED_CREDIT,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.creditScore,
    ]),

  },
  {
    id                : REFINANCE_TURBO_PROPERTY_INFO,
    questions         : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.homeValue,
      FieldNames.loanAmount,
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
    ]),
    submitAction : ajaxValidateSubjectProperty,
  },
  {
    id: REFINANCE_TURBO_USPS_VERIFICATION,
    ifNoQuestions : AddressVerified,
    submitAction  : handleGetSubjectPropertyInfo,
  },
  {
    id       : REFINANCE_TURBO_PROPERTY_VALUE_RANGE,
    hideBackButton    : true,
    hideForwardButton : true,
    questions: REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.avmHomeValue]),
    showIf            : [hasAvmHomeValue],
  },
  {
    id        : REFINANCE_TURBO_CASH_AT_CLOSING,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.cashOut]),
    showIf    : [(values) => isTakingCashOut(values) && hasRoomForCashOut(values)],
  },
  {
    id        : REFINANCE_TURBO_CURRENT_LOAN_INFO,
    title     : 'Fill in some details about your current loan',
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.currentInterestRate,
      FieldNames.currentLoanType,
      FieldNames.currentLoanTerm,
      FieldNames.currentMonthlyPayment,
    ]),
  },
  {
    id        : REFINANCE_TURBO_PERSONAL_INFO,
    title     : 'Enter your personal information',
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.dateOfBirth,
      FieldNames.email,
      FieldNames.phone,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerUnderage,
        LoanOfficerCondition.hasNoRoomForCashOut,
      ],
    },
    submitText   : 'Submit',
    submitAction : handleRefinanceTurboGetRates,
    submitIf     : [
      (values) =>
        !isBorrowerUnderage(values) &&
        (!isTakingCashOut(values) || hasRoomForCashOut(values)),
    ],
  },
  {
    id       : REFINANCE_TURBO_ANNUAL_COSTS,
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.livingEscrowUsage,
      FieldNames.escrowUsage,
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
    ]),
  },
  {
    id       : REFINANCE_TURBO_PRIMARY_HOME,
    title    : 'Tell us about your primary home',
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
    ]),
    submitIf: [(values) => !isPrimaryHome(values)],
    submitAction : (formValues) => ajaxValidateBorrowerResidence(formValues),
  },
  {
    id: REFINANCE_TURBO_MARITAL_STATUS_DEPENDENTS,
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.maritalStatus,
      FieldNames.numOfDependent,
    ]),
  },
  {
    id        : REFINANCE_TURBO_CITIZENSHIP_MILITARY,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.citizenshipType,
      FieldNames.militaryExperience,
    ]),
  },
  {
    id        : REFINANCE_TURBO_COBORROWER_PERSONAL_INFO,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerOnTitle,
    ]),
  },
  {
    id        : REFINANCE_TURBO_COBORROWER_HOUSING_INFO,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerMaritalStatus,
    ]),
    showIf       : [hasCoBorrower],
    submitIf     : [(formValues) => coBorrowerHasDifferentAddress(formValues)],
    submitAction : (formValues) => ajaxValidateCoBorrowerResidence(formValues),
  },
  {
    id           : REFINANCE_TURBO_COBORROWER_CITIZENSHIP,
    questions    : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMilitaryExperience,
    ]),
    showIf       : [hasCoBorrower],
  },
  {
    id           : REFINANCE_TURBO_CREDIT_AUTHORIZATION,
    questions    : REFINANCE_TURBO_QUESTIONS.getByName([ FieldNames.loanOfficer ]),
    agreement    : <AuthorizeCreditReport />,
    submitAction : ajaxPostCreditReportForRefinanceTurbo,
  },
  {
    id              : REFINANCE_TURBO_REAL_ESTATE_LIABILITIES,
    title           : 'Real Estate Liabilities',
    questions       : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.realEstateLiabilities,
    ]),
  },
  {
    id        : REFINANCE_TURBO_REO_PRIMARY_QUESTIONS,
    title     : 'In addition to your Primary Home, how many other homes do you own?',
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.liabilityCombinedNumberOtherHomes,
      FieldNames.liabilityCombinedYearTaxes,
      FieldNames.liabilityCombinedYearInsure,
      FieldNames.liabilityCombinedYearAssociate,
    ]),
    showIf: [(values) => isPrimaryHome(values)],
    submitAction: handleRefinanceTurboFinalAdjustments,
  },
  {
    id        : REFINANCE_TURBO_REO_SECONDARY_QUESTIONS,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.isOwningOtherHomes,
      FieldNames.liabilityCombinedNumberOtherHomes,
      FieldNames.liabilityCombinedYearTaxes,
      FieldNames.liabilityCombinedYearInsure,
      FieldNames.liabilityCombinedYearAssociate,
    ]),
    showIf: [(values) => !isPrimaryHome(values)],
    submitAction: handleRefinanceTurboFinalAdjustments,
  },
  {
    id        : REFINANCE_TURBO_CASH_AT_CLOSING_FINAL,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.cashOut]),
    submitAction : handleRefinanceTurboGetFinalRates,
    submitText : 'Submit',
  },
  {
    id           : REFINANCE_TURBO_AUTOMATIC_VOIE,
    paragraph    : FormParagraphs.automaticVoie,
    questions    : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.canVerifyOfIncomeEmployment]),
    title        : 'If you are a W2 employee, would you like to automatically receive verification of your income and employment?',
    submitAction : handleGoToFinancialVerificationPreStep,
  },
  {
    id            : REFINANCE_TURBO_COLLECT_SSN,
    questions     : [REFINANCE_TURBO_QUESTIONS.getById(REFINANCE_TURBO_COLLECT_SSN_QUESTION)],
    showIf        : [canVerifyOfIncomeEmployment],
    submitIf      : [canVerifyOfIncomeEmployment],
    submitAction  : ajaxGetFinancialVerificationUrl,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id                : REFINANCE_TURBO_VERIFICATION_CONNECT,
    questions         : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.isFinancialVerificationComplete]),
    showIf            : [canVerifyOfIncomeEmployment],
    hideBackButton    : true,
    hideForwardButton : true,
  },
  {
    id             : REFINANCE_TURBO_VOIE_REPORTS,
    questions      : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.isFinancialVerificationComplete]),
    showIf         : [canVerifyOfIncomeEmployment],
    hideBackButton : true,
  },
  {
    id        : REFINANCE_TURBO_EMPLOYMENT_TYPE,
    questions : REFINANCE_TURBO_QUESTIONS.getByName([FieldNames.employmentType]),
    showIf: [isManualIncomeAndEmploymentRequired],
  },
  {
    id       : REFINANCE_TURBO_EMPLOYEE_INFO,
    title    : 'Tell us about your income and employment',
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isEmployee(values) || isSelfEmployed(values))],
  },
  {
    id       : REFINANCE_TURBO_RETIRED_INFO,
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isRetired(values) || isEmploymentOther(values))],
  },
  {
    id       : REFINANCE_TURBO_COBORROWER_EMPLOYMENT_TYPE,
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && hasCoBorrower(values)],
  },
  {
    id       : REFINANCE_TURBO_COBORROWER_EMPLOYEE_INFO,
    title    : 'Tell us about your co-borrower\'s income and employment',
    questions: REFINANCE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerRetirementIncome,
    ]),
    showIf: [
      (values) => isManualIncomeAndEmploymentRequired(values) &&
      (isCoBorrowerEmployee(values) ||
        isCoBorrowerEmploymentOther(values) ||
        isCoBorrowerRetired(values) ||
        isCoBorrowerSelfEmployed(values)),
    ],
  },
  {
    id           : REFINANCE_TURBO_BORROWER_DECLARATION,
    title        : 'Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : handleRefinanceTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : REFINANCE_TURBO_COBORROWER_DECLARATION,
    title        : 'Co-Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitAction : handleRefinanceTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : REFINANCE_TURBO_COLLECT_SSN_FINAL,
    questions    : [REFINANCE_TURBO_QUESTIONS.getById(REFINANCE_TURBO_COLLECT_SSN_QUESTION)],
    submitAction : handleRefinanceTurboFormSubmit,
    submitText   : 'Submit',
    agreement    : <AuthorizeSsnFullPull />,
  },
  {
    id              : REFINANCE_TURBO_UPLOAD_DOCUMENTS,
    ifNoQuestions   : UploadDocumentsStep,
    hideProgressBar : true,
  },
  {
    id: REFINANCE_TURBO_MIN_LOAN_STOP_GATE,
    ifNoQuestions: MinimumLoanAmount,
  },
  {
    id: REFINANCE_TURBO_NO_OPTIMAL_PROGRAMS,
    ifNoQuestions: NoOptimalPrograms,
  },
];
