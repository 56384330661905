import { CheckboxGroup } from 'app/components/FormFields/CheckboxGroup';
import { DeclarationBankruptDetails } from 'app/components/FormFields/DeclarationBankruptDetails';
import { DeclarationForeclosureDate } from 'app/components/FormFields/DeclarationForeclosureDate';
import { DeclarationCheckboxes } from 'app/components/FormFields/DeclarationCheckboxes';
import { DeclarationOwnershipInterest } from 'app/components/FormFields/DeclarationOwnershipInterest';
import { DeclarationText } from 'app/components/FormFields/DeclarationText';
import { SwitchToggle } from 'app/components/FormFields/SwitchToggle';
import { Select } from 'app/components/FormFields/Select';
import {
  DAY_OF_WEEK_OPTIONS,
  ETHNICITY_HISPANIC_OPTIONS,
  ETHNICITY_OPTIONS,
  RACE_ASIAN_OPTIONS,
  RACE_ISLANDER_OPTIONS,
  RACE_OPTIONS,
  SEX_OPTIONS,
  TIME_OF_DAY_OPTIONS,
} from 'app/models/options/options';
import { DeclarationQuestion } from 'app/models/types';
import { FormParagraphs } from 'app/models/paragraphs';
import { FieldNames } from 'app/models/fields/names';
import { AutoPrequalLabels, ToggleLabels } from 'app/models/fields/labels';
import {
  hasBankrupt,
  hasBorrowedDown,
  hasForeclosed,
  hasOccupyPrimary,
  hasOwnerInterest,
  hasShowGovtMonitoring,
  isAsian,
  isAsianOther,
  isHispanic,
  isHispanicOther,
  isIslander,
  isIslanderOther,
  noCitizenship,
  schedulerDisabled,
} from 'app/models/fields/conditionals';
import { Currency } from 'app/components/FormFields/Currency';
import { BorrowerType } from '@lenderful/domain';

/* Auto prequal whitelist declarations will be displayed in this order
   when updating these values, handleDeclarationFlagsWhitelist() MUST also be updated */
const autoPrequalWhitelist = [
  FieldNames.hasBankrupt,
  FieldNames.hasForeclosed,
  FieldNames.hasCitizenship,
  FieldNames.hasAlimony,
] as string[];

/* Long Forms whitelist declarations will be displayed in this order */
const longFormWhitelist = [
  FieldNames.hasOccupyPrimary,
  FieldNames.hasOwnerInterest,
  FieldNames.ownerPropertyUsage,
  FieldNames.ownerTitleHeld,
  FieldNames.hasFamilyRelationship,
  FieldNames.hasBorrowedDown,
  FieldNames.borrowedDownAmount,
  FieldNames.hasAnotherMortgageInProgress,
  FieldNames.hasLoanObligation,
  FieldNames.hasOtherLienOnProperty,
  FieldNames.hasNoteEndorsement,
  FieldNames.hasJudgements,
  FieldNames.hasDelinquent,
  FieldNames.hasLawsuit,
  FieldNames.hasConveyedTitle,
  FieldNames.hasShortSale,
  FieldNames.hasForeclosed,
  FieldNames.forecloseCompleted,
  FieldNames.hasBankrupt,
  FieldNames.bankruptAge,
  FieldNames.bankruptType,
  FieldNames.ethnicity,
  FieldNames.ethnicityHispanic,
  FieldNames.ethnicityOther,
  FieldNames.race,
  FieldNames.raceAsian,
  FieldNames.raceAsianOther,
  FieldNames.raceIslander,
  FieldNames.raceIslanderOther,
  FieldNames.sex,
  FieldNames.scheduleDate,
  FieldNames.scheduleTime,
] as string[];

/* Construction whitelist declarations will be displayed in this order */
const constructionWhitelist = [
  FieldNames.hasOccupyPrimary,
  FieldNames.hasOwnerInterest,
  FieldNames.ownerPropertyUsage,
  FieldNames.ownerTitleHeld,
  FieldNames.hasFamilyRelationship,
  FieldNames.hasBorrowedDown,
  FieldNames.borrowedDownAmount,
  FieldNames.hasAnotherMortgageInProgress,
  FieldNames.hasLoanObligation,
  FieldNames.hasOtherLienOnProperty,
  FieldNames.hasNoteEndorsement,
  FieldNames.hasJudgements,
  FieldNames.hasDelinquent,
  FieldNames.hasLawsuit,
  FieldNames.hasConveyedTitle,
  FieldNames.hasShortSale,
  FieldNames.hasForeclosed,
  FieldNames.forecloseCompleted,
  FieldNames.hasBankrupt,
  FieldNames.bankruptAge,
  FieldNames.bankruptType,
  FieldNames.scheduleDate,
  FieldNames.scheduleTime,
] as string[];

/* Reverse Mortgage whitelist declarations will be displayed in this order
   when updating these values, handleDeclarationFlagsWhitelist() MUST also be updated */
const reverseWhitelist = [
  FieldNames.hasOccupyPrimary,
  FieldNames.hasNoteEndorsement,
  FieldNames.hasJudgements,
  FieldNames.hasDelinquent,
  FieldNames.hasLawsuit,
  FieldNames.hasBankrupt,
  FieldNames.ethnicity,
  FieldNames.ethnicityHispanic,
  FieldNames.ethnicityOther,
  FieldNames.race,
  FieldNames.raceAsian,
  FieldNames.raceAsianOther,
  FieldNames.raceIslander,
  FieldNames.raceIslanderOther,
  FieldNames.sex,
] as string[];

/* Turbo whitelist declarations will be displayed in this order
   when updating these values, handleDeclarationFlagsWhitelist() MUST also be updated */
const turboWhitelist = [
  FieldNames.hasOccupyPrimary,
  FieldNames.hasOwnerInterest,
  FieldNames.ownerPropertyUsage,
  FieldNames.ownerTitleHeld,
  FieldNames.hasAnotherMortgageInProgress,
  FieldNames.hasOtherLienOnProperty,
  FieldNames.hasNoteEndorsement,
  FieldNames.hasJudgements,
  FieldNames.hasDelinquent,
  FieldNames.hasLawsuit,
  FieldNames.hasConveyedTitle,
  FieldNames.hasShortSale,
  FieldNames.hasForeclosed,
  FieldNames.forecloseCompleted,
  FieldNames.hasBankrupt,
  FieldNames.bankruptAge,
  FieldNames.bankruptType,
  FieldNames.ethnicity,
  FieldNames.ethnicityHispanic,
  FieldNames.ethnicityOther,
  FieldNames.race,
  FieldNames.raceAsian,
  FieldNames.raceAsianOther,
  FieldNames.raceIslander,
  FieldNames.raceIslanderOther,
  FieldNames.sex,
  FieldNames.scheduleDate,
  FieldNames.scheduleTime,
] as string[];

/* Commercial Purchase whitelist declarations will be displayed in this order */
const commercialWhitelist = [
  FieldNames.hasCommercialBankrupt,
  FieldNames.hasCommercialCredit,
  FieldNames.hasCommercialTaxDue,
  FieldNames.hasCommercialDebt,
  FieldNames.hasCommercialLegalAction,
] as string[];

/* Personal Financial Statement declarations will be displayed in this order */
const financialStatementWhitelist = [
  FieldNames.hasBankrupt,
  FieldNames.hasForeclosed,
  FieldNames.hasJudgements,
] as string[];

/**
 * Uses the whitelist to filter out questions that are not asked.  It checks
 * both the name and fieldNames attributes for comparison
 *
 * @param whitelist
 * @returns
 */
const whitelistQuestionPredicate = (whitelist: string[]) => (question: DeclarationQuestion): boolean => (
  whitelist.includes(question.name) ||
  (question?.fieldNames?.some(name => whitelist.includes(name)))
);

/**
 * Sorts the questions given the order of them in the whitelist provided.  It prefers order by name
 * unless it is empty.  Otherwise, it will use 1st fieldNames.
 *
 * @param whitelist
 * @returns
 */
const whitelistQuestionComparison = (whitelist: string[]) => (aQuestion: DeclarationQuestion, bQuestion: DeclarationQuestion): number => {
  const aName = aQuestion.name !== '' ? aQuestion.name : aQuestion.fieldNames[0];
  const bName = bQuestion.name !== '' ? bQuestion.name : bQuestion.fieldNames[0];
  return whitelist.indexOf(aName) - whitelist.indexOf(bName);
};

/**
 * This takes a question and adds the correct `name` and `showIf` fields based on the
 * borrower type.
 *
 * @param {BorrowerType} borrowerType
 * @param {DeclarationQuestion} question
 * @returns {DeclarationQuestion} modified declaration question
 */
const addQuestionNameAndShowIf = (borrowerType: BorrowerType, question: DeclarationQuestion): DeclarationQuestion => {
  const name = (borrowerType === BorrowerType.PRIMARY) ?
    `${FieldNames.decBorrower}.${question.name}` :
    `${FieldNames.decCoBorrower}.${question.name}`;

  let showIf = (borrowerType === BorrowerType.PRIMARY) ? question.primaryShowIf : question.coBorrowerShowIf;
  showIf = showIf ? showIf : undefined;

  return { ...question, name, showIf };
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 * with the corresponding borrower type
 *
 * @param {string} borrowerType Primary or Co-Borrower
 * @returns {DeclarationQuestion[]}
 */
export const getLongFormDeclarationQuestions = (borrowerType: BorrowerType): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(longFormWhitelist))
    .sort(whitelistQuestionComparison(longFormWhitelist))
    .map(question => addQuestionNameAndShowIf(borrowerType, question));
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 * with the corresponding borrower type
 *
 * @param {string} borrowerType Primary or Co-Borrower
 * @returns {DeclarationQuestion[]}
 */
export const getConstructionDeclarationQuestions = (borrowerType: BorrowerType): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(constructionWhitelist))
    .sort(whitelistQuestionComparison(constructionWhitelist))
    .map(question => addQuestionNameAndShowIf(borrowerType, question));
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 * with the corresponding borrower type
 *
 * @param {string} borrowerType Primary or Co-Borrower
 * @returns {DeclarationQuestion[]}
 */
export const getReverseDeclarationQuestions = (borrowerType: BorrowerType): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(reverseWhitelist))
    .sort(whitelistQuestionComparison(reverseWhitelist))
    .map(question => addQuestionNameAndShowIf(borrowerType, question));
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 * with the corresponding borrower type
 *
 * @param {string} borrowerType Primary or Co-Borrower
 * @returns {DeclarationQuestion[]}
 */
export const getTurboDeclarationQuestions = (borrowerType: BorrowerType): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(turboWhitelist))
    .sort(whitelistQuestionComparison(turboWhitelist))
    .map(question => addQuestionNameAndShowIf(borrowerType, question));
};

/**
 * Returns a filtered list of auto prequal declaration questions.
 * Auto prequal declarations are only assigned to the primary borrower.
 *
 * @returns {any[]}
 */
export const getAutoPrequalDeclarationQuestions = (): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter((question) => autoPrequalWhitelist.includes(question.name))
    /* Map the question names over to decBorrower and add the auto prequal labels */
    .map((question) => ({
      ...question,
      name  : `${FieldNames.decBorrower}.${question.name}`,
      showIf: question.primaryShowIf ? question.primaryShowIf: undefined,
      label : AutoPrequalLabels[question.name],
    }))
    /* Sort out the questions to appear in the order listed in the autoPrequalDecNames array */
    .sort((a, b) => {
      return autoPrequalWhitelist.indexOf(a.name.split('.')[1]) - autoPrequalWhitelist.indexOf(b.name.split('.')[1]);
    });
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 * with the corresponding borrower type
 *
 * @param {string} borrowerType Primary or Co-Borrower
 * @returns {DeclarationQuestion[]}
 */
export const getCommercialDeclarationQuestions = (borrowerType: BorrowerType): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(commercialWhitelist))
    .sort(whitelistQuestionComparison(commercialWhitelist))
    .map(question => addQuestionNameAndShowIf(borrowerType, question));
};

/**
 * Iterates over the declaration questions prefixing the fieldNames
 *
 * @returns {DeclarationQuestion[]}
 */
export const getFinancialStatementWhitelist = (): DeclarationQuestion[] => {
  return DECLARATION_QUESTIONS
    .filter(whitelistQuestionPredicate(financialStatementWhitelist))
    .sort(whitelistQuestionComparison(financialStatementWhitelist))
    .map(question => addQuestionNameAndShowIf(BorrowerType.PRIMARY, question));
};

/*
 * Declaration questions are not exported and should only be imported
 * using the helper function `getLongFormDeclarationQuestions` or `getAutoPrequalDeclarationQuestions`
 * since these questions are generated for borrower and co-borrower
 */
const DECLARATION_QUESTIONS: DeclarationQuestion[] = [
  {
    id       : 1001,
    component: SwitchToggle,
    label    : ToggleLabels.hasJudgements,
    name     : FieldNames.hasJudgements,
  },
  {
    id           : 1002,
    component    : SwitchToggle,
    label        : ToggleLabels.hasBankrupt,
    name         : FieldNames.hasBankrupt,
    hasConditions: true,
  },
  {
    id                  : 1003,
    component           : DeclarationBankruptDetails,
    // This contains multiple questions, name is assigned in component
    name                : '',
    primaryShowIf       : [hasBankrupt(FieldNames.decBorrower)],
    coBorrowerShowIf    : [hasBankrupt(FieldNames.decCoBorrower)],
    // Used in the change handler to update fields located inside these components
    // @TODO: Refactor/split these mutli-question questions into one each
    fieldNames          : [FieldNames.bankruptAge, FieldNames.bankruptType],
  },
  {
    id           : 1005,
    component    : SwitchToggle,
    label        : ToggleLabels.hasForeclosed,
    name         : FieldNames.hasForeclosed,
    hasConditions: true,
  },
  {
    id              : 1006,
    component       : DeclarationForeclosureDate,
    label           : 'When was it completed?',
    name            : FieldNames.forecloseCompleted,
    primaryShowIf   : [hasForeclosed(FieldNames.decBorrower)],
    coBorrowerShowIf: [hasForeclosed(FieldNames.decCoBorrower)],
    fieldNames      : [FieldNames.forecloseCompleted],
  },
  {
    id       : 1007,
    component: SwitchToggle,
    label    : ToggleLabels.hasLawsuit,
    name     : FieldNames.hasLawsuit,
  },
  {
    id       : 1008,
    component: SwitchToggle,
    label    : ToggleLabels.hasLoanObligation,
    name     : FieldNames.hasLoanObligation,
  },
  {
    id       : 1009,
    component: SwitchToggle,
    label    : ToggleLabels.hasDelinquent,
    name     : FieldNames.hasDelinquent,
  },
  {
    id           : 1010,
    component    : SwitchToggle,
    label        : ToggleLabels.hasAlimony,
    name         : FieldNames.hasAlimony,
    hasConditions: true,
  },
  {
    id       : 1013,
    component: SwitchToggle,
    label    : ToggleLabels.hasBorrowedDown,
    name     : FieldNames.hasBorrowedDown,
  },
  {
    id               : 1031,
    component        : Currency,
    name             : FieldNames.borrowedDownAmount,
    label            : ToggleLabels.borrowedDownAmount,
    primaryShowIf    : [hasBorrowedDown(FieldNames.decBorrower)],
    coBorrowerShowIf : [hasBorrowedDown(FieldNames.decCoBorrower)],
  },
  {
    id       : 1029,
    component: SwitchToggle,
    label    : ToggleLabels.hasNoteEndorsement,
    name     : FieldNames.hasNoteEndorsement,
  },
  {
    id       : 1014,
    component: SwitchToggle,
    label    : ToggleLabels.hasCitizenship,
    name     : FieldNames.hasCitizenship,
  },
  {
    id              : 1015,
    component       : SwitchToggle,
    label           : ToggleLabels.hasGreenCard,
    name            : FieldNames.hasGreenCard,
    primaryShowIf   : [noCitizenship(FieldNames.decBorrower)],
    coBorrowerShowIf: [noCitizenship(FieldNames.decCoBorrower)],
  },
  {
    id       : 1016,
    component: SwitchToggle,
    label    : ToggleLabels.hasOccupyPrimary,
    name     : FieldNames.hasOccupyPrimary,
  },
  {
    id: 1017,
    // @MEETING: Set as default if isHomeowner is true
    component       : SwitchToggle,
    label           : ToggleLabels.hasOwnerInterest,
    name            : FieldNames.hasOwnerInterest,
    primaryShowIf   : [hasOccupyPrimary(FieldNames.decBorrower)],
    coBorrowerShowIf: [hasOccupyPrimary(FieldNames.decCoBorrower)],
    hasConditions   : true,
  },
  {
    id              : 1018,
    component       : DeclarationOwnershipInterest,
    name            : '',
    primaryShowIf   : [hasOwnerInterest(FieldNames.decBorrower)],
    coBorrowerShowIf: [hasOwnerInterest(FieldNames.decCoBorrower)],
    fieldNames      : [FieldNames.ownerPropertyUsage, FieldNames.ownerTitleHeld],
  },
  {
    id         : 1020,
    component  : DeclarationCheckboxes,
    title      : 'Government Monitoring Questions',
    borderTitle: true,
    label      : 'Ethnicity',
    name       : FieldNames.ethnicity,
    options    : ETHNICITY_OPTIONS,
    primaryShowIf   : [hasShowGovtMonitoring],
    coBorrowerShowIf: [hasShowGovtMonitoring],
  },
  {
    id              : 1021,
    component       : CheckboxGroup,
    label           : 'Hispanic or Latino',
    name            : FieldNames.ethnicityHispanic,
    options         : ETHNICITY_HISPANIC_OPTIONS,
    primaryShowIf   : [isHispanic(FieldNames.decBorrower)],
    coBorrowerShowIf: [isHispanic(FieldNames.decCoBorrower)],
  },
  {
    id              : 1022,
    component       : DeclarationText,
    label           : 'Other Hispanic or Latino',
    name            : FieldNames.ethnicityOther,
    primaryShowIf   : [isHispanicOther(FieldNames.decBorrower)],
    coBorrowerShowIf: [isHispanicOther(FieldNames.decCoBorrower)],
  },
  {
    id       : 1023,
    component: DeclarationCheckboxes,
    label    : 'Race',
    name     : FieldNames.race,
    options  : RACE_OPTIONS,
    primaryShowIf   : [hasShowGovtMonitoring],
    coBorrowerShowIf: [hasShowGovtMonitoring],
  },
  {
    id              : 1024,
    component       : CheckboxGroup,
    label           : 'Asian',
    name            : FieldNames.raceAsian,
    options         : RACE_ASIAN_OPTIONS,
    primaryShowIf   : [isAsian(FieldNames.decBorrower)],
    coBorrowerShowIf: [isAsian(FieldNames.decCoBorrower)],
  },
  {
    id              : 1025,
    component       : DeclarationText,
    label           : 'Other Asian',
    name            : FieldNames.raceAsianOther,
    primaryShowIf   : [isAsianOther(FieldNames.decBorrower)],
    coBorrowerShowIf: [isAsianOther(FieldNames.decCoBorrower)],
  },
  {
    id              : 1026,
    component       : CheckboxGroup,
    label           : 'Native Hawaiian or Other Pacific Islander',
    name            : FieldNames.raceIslander,
    options         : RACE_ISLANDER_OPTIONS,
    primaryShowIf   : [isIslander(FieldNames.decBorrower)],
    coBorrowerShowIf: [isIslander(FieldNames.decCoBorrower)],
  },
  {
    id              : 1027,
    component       : DeclarationText,
    label           : 'Other Pacific Islander',
    name            : FieldNames.raceIslanderOther,
    primaryShowIf   : [isIslanderOther(FieldNames.decBorrower)],
    coBorrowerShowIf: [isIslanderOther(FieldNames.decCoBorrower)],
  },
  {
    id       : 1028,
    component: DeclarationCheckboxes,
    label    : 'Sex',
    name     : FieldNames.sex,
    options  : SEX_OPTIONS,
    primaryShowIf   : [hasShowGovtMonitoring],
    coBorrowerShowIf: [hasShowGovtMonitoring],
  },
  {
    id              : 1029,
    component       : Select,
    label           : 'Date',
    name            : FieldNames.scheduleDate,
    options         : DAY_OF_WEEK_OPTIONS,
    title           : 'Schedule Appointment (Optional)',
    paragraph       : FormParagraphs.scheduleAppointment,
    borderTitle     : true,
    isOptional      : true,
    primaryShowIf   : [schedulerDisabled(FieldNames.decBorrower)],
    coBorrowerShowIf: [schedulerDisabled(FieldNames.decCoBorrower)],
  },
  {
    id              : 1030,
    component       : Select,
    label           : 'Time',
    name            : FieldNames.scheduleTime,
    options         : TIME_OF_DAY_OPTIONS,
    isOptional      : true,
    primaryShowIf   : [schedulerDisabled(FieldNames.decBorrower)],
    coBorrowerShowIf: [schedulerDisabled(FieldNames.decCoBorrower)],
  },
  {
    id       : 1032,
    component: SwitchToggle,
    label    : ToggleLabels.hasFamilyRelationship,
    name     : FieldNames.hasFamilyRelationship,
  },
  {
    id       : 1033,
    component: SwitchToggle,
    label    : ToggleLabels.hasAnotherMortgageInProgress,
    name     : FieldNames.hasAnotherMortgageInProgress,
  },
  {
    id       : 1034,
    component: SwitchToggle,
    label    : ToggleLabels.hasOtherLienOnProperty,
    name     : FieldNames.hasOtherLienOnProperty,
  },
  {
    id       : 1035,
    component: SwitchToggle,
    label    : ToggleLabels.hasShortSale,
    name     : FieldNames.hasShortSale,
  },
  {
    id       : 1036,
    component: SwitchToggle,
    label    : ToggleLabels.hasConveyedTitle,
    name     : FieldNames.hasConveyedTitle,
  },
  {
    id       : 1037,
    component: SwitchToggle,
    label    : ToggleLabels.hasCommercialBankrupt,
    name     : FieldNames.hasCommercialBankrupt,
  },
  {
    id       : 1038,
    component: SwitchToggle,
    label    : ToggleLabels.hasCommercialCredit,
    name     : FieldNames.hasCommercialCredit,
  },
  {
    id       : 1039,
    component: SwitchToggle,
    label    : ToggleLabels.hasCommercialTaxDue,
    name     : FieldNames.hasCommercialTaxDue,
  },
  {
    id       : 1040,
    component: SwitchToggle,
    label    : ToggleLabels.hasCommercialDebt,
    name     : FieldNames.hasCommercialDebt,
  },
  {
    id       : 1041,
    component: SwitchToggle,
    label    : ToggleLabels.hasCommercialLegalAction,
    name     : FieldNames.hasCommercialLegalAction,
  },
];
