import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { ssnQuestionRequirementSelector } from 'app/reducers/app-config';
import { getFormName } from '../../routes/helpers';
import { FieldRequirementType, FormName } from '@lenderful/domain';

interface Props {
  clientName             : string;
  formName               : FormName;
  ssnQuestionRequirement : string;
}

const AuthorizeSsnFullPullComponent = ({ ...props }: Props) => {
  const { clientName, ssnQuestionRequirement } = props;
  const { t }       = useTranslation();
  const optionalSsnVerbiage = t('formAgreement.authorizeSsnFullPull.optional', {
    defaultValue: 'If you choose not to provide this, you will need to contact {{ clientName }} to authorize a credit pull and complete your application.',
    clientName,
  });
  const isSsnRequired = ssnQuestionRequirement === FieldRequirementType.REQUIRED;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant   = 'body2'
          component = 'span'
          classes   = {{ body2: 'small' }}
        >
          <Trans i18nKey='formAgreement.authorizeSsnFullPull'>
            I/We authorize the ordering of a consumer credit report.  I/We hereby further authorize the verification my past and present earnings, bank accounts, and other asset balances that are needed to process my mortgage loan application.  Additional verification of my personal credit information is only to be used in the processing of my application for a loan. It is understood that a copy of this form will also serve as authorization. This authorization expires 120 days from the date indicated below.
            <br/><br/>
            If you have added a co-borrower, you will need to provide both social security numbers.&nbsp;
          </Trans>
          {isSsnRequired || [FormName.SBLINEOFCREDIT, FormName.SBTERM].includes(props.formName) ? '' : optionalSsnVerbiage}
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any, ownProps: any) => ({
  clientName             : state.config.clientDisplayName,
  formName               : getFormName(state.router.location.pathname),
  ssnQuestionRequirement : ssnQuestionRequirementSelector(state),
  ...ownProps,
});

export const AuthorizeSsnFullPull = connect(
  mapStateToProps,
  null,
)(AuthorizeSsnFullPullComponent);
