import React from 'react';
import { NotificationContextType } from 'app/providers/notifications/NotificationContextType';
import { Notification, NotificationLevel } from 'app/providers/notifications/Notification';

const NotificationContext = React.createContext<NotificationContextType>(null!);

/**
 * This provider stores all the context around notifications.  There is a NotificationComponent that
 * displays the message in a snackbar.
 * 
 * @param {React.ReactNode} children 
 * @returns {JSX.Element}
 */
export const NotificationProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {

  const [isOpen, setIsOpen] = React.useState(false);
  const [notification, setNotification] = React.useState<Notification>();

  const displayNotification = (message: string, level?: NotificationLevel, icon?: JSX.Element) => {
    setNotification({ icon, message, level });
    setIsOpen(true);
  };

  const context: NotificationContextType = {
    displayNotification,
    notification,
    isOpen,
    setIsOpen,
  };

  return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>;
};

/**
 * Hook to obtain the notification provider inside any component
 * @returns {NotificationContextType} 
 */
export const useNotifications = (): NotificationContextType => {
  return React.useContext(NotificationContext);
};
