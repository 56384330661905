import { FieldNames } from 'app/models/fields/names';
import {
  PROPERTY_USAGE_OPTIONS,
  PROPERTY_TYPE_OPTIONS,
  ESCROW_USAGE_OPTIONS,
  MARITAL_STATUS_OPTIONS,
  NAME_SUFFIX_OPTIONS,
  YES_OR_NO_OPTIONS,
  CITIZENSHIP_TYPE_OPTIONS,
  MILITARY_EXPERIENCE_OPTIONS,
  EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
  COMMERCIAL_ENTITY_TYPE_OPTIONS,
  YEAR_1970PLUS_TO_NOW,
  COMMERCIAL_TREND_OPTIONS,
  COMMERCIAL_LOAN_HOLDER_OPTIONS,
  COMMERCIAL_LIVING_SITUATION_OPTIONS,
  EMPLOYMENT_TYPE_OPTIONS,
} from 'app/models/options/options';
import { Name } from 'app/components/FormFields/Name';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { Email } from 'app/components/FormFields/Email';
import { Phone } from 'app/components/FormFields/Phone';
import { RadioField } from 'app/components/FormFields/RadioField';
import { SinglePastDate } from 'app/components/FormFields/SinglePastDate';
import { Select } from 'app/components/FormFields/Select';

import {
  hasCoBorrower,
  isCoLessThanTwoYrsAtJob,
  isCoSelfEmployed,
  isLessThanTwoYrsAtJob,
  isEmployee,
  isSelfEmployed,
  isCoEmployee,
  isInvestmentHome,
  isMultiFamilyHome,
  isEmploySameBusiness, isRetired, isEmploymentOther,
} from 'app/models/fields/conditionals';
import { FormParagraphs } from 'app/models/paragraphs';
import { TextInput } from 'app/components/FormFields/TextInput';
import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { CreditScoresRadio } from '../../components/FormFields/CreditScoresRadio';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { Integer } from '../../components/FormFields/Integer';
import { Currency } from '../../components/FormFields/Currency';
import { TextArea } from '../../components/FormFields/TextArea';
import { Owners } from '../../components/FormFieldArrays/Owners';
import { IncomeSources } from '../../components/FormFieldArrays/IncomeSources';
import { RetirementIncomeSources } from '../../components/FormFieldArrays/RetirementIncomeSources';
import { OtherIncomeSources } from '../../components/FormFieldArrays/OtherIncomeSources';
import { Question } from '../types';

/**
 * List of questions shared by more than one form
 * These must be imported directly
 * import { SHARED } from 'app/models/questions/shared';
 */
export const SHARED = {
  [FieldNames.propertyUsage]: {
    component : RadioField,
    label     : 'Property Use',
    name      : FieldNames.propertyUsage,
    options   : PROPERTY_USAGE_OPTIONS,
    popover   :
      'Primary residence is the place you live more than 6 months per year; secondary homes are non-rented vacation or second homes; investment homes are generally rentals.',
  },
  [FieldNames.propertyType]: {
    component : RadioField,
    label     : 'Property Type',
    name      : FieldNames.propertyType,
    options   : PROPERTY_TYPE_OPTIONS,
    popover   :
      'Condominiums include attached condos that have homeowner association fees (HOA); Modular homes are assembled at the building site and built to local/state building codes.',
  },
  [FieldNames.creditScore]: {
    component : CreditScoresRadio,
    label     : 'Credit Score',
    hideLabel : true,
    name      : FieldNames.creditScore,
    popover   :
      'This is the estimate of your current credit score. After you authorize an official credit pull, the middle of 3 scores will be used for your mortgage application.',
  },
  [FieldNames.escrowUsage]: {
    component : RadioField,
    label     : 'Escrows and Insurance',
    hideLabel : true,
    name      : FieldNames.escrowUsage,
    options   : ESCROW_USAGE_OPTIONS,
    popover   :
      'An escrow is a separate account where funds are collected and deposited by your mortgage servicer to pay your taxes and home insurance; these funds come from an amount added to your monthly payment.',
  },
  [FieldNames.propertyZip]: {
    component: ZipCode,
    label    : 'Zip Code',
    name     : FieldNames.propertyZip,
  },
  [FieldNames.firstName]: {
    component    : Name,
    label        : 'First Name',
    name         : FieldNames.firstName,
    popover      : 'Names only allow upper and lower case letters, hyphens, spaces and apostrophes.',
    fieldPopover : true,
  },
  [FieldNames.middleName]: {
    component : Name,
    label     : 'Middle Name',
    name      : FieldNames.middleName,
    isOptional: true,
  },
  [FieldNames.lastName]: {
    component: Name,
    label    : 'Last Name',
    name     : FieldNames.lastName,
  },
  [FieldNames.suffixName]: {
    component  : Select,
    options    : NAME_SUFFIX_OPTIONS,
    label      : 'Suffix',
    name       : FieldNames.suffixName,
    isOptional : true,
  },
  [FieldNames.mothersMaidenName]: {
    component: Name,
    label    : 'Mothers Maiden Name',
    name     : FieldNames.mothersMaidenName,
  },
  [FieldNames.coBorrowerSuffixName]: {
    component  : Select,
    options    : NAME_SUFFIX_OPTIONS,
    label      : 'Co-borrower Suffix',
    name       : FieldNames.coBorrowerSuffixName,
    isOptional : true,
    showIf     : [hasCoBorrower],
  },
  [FieldNames.email]: {
    component: Email,
    label    : 'Email',
    name     : FieldNames.email,
  },
  [FieldNames.phone]: {
    component: Phone,
    label    : 'Phone',
    name     : FieldNames.phone,
  },
  [FieldNames.maritalStatus]: {
    component: RadioField,
    name     : FieldNames.maritalStatus,
    options  : MARITAL_STATUS_OPTIONS,
    title    : 'What is your marital status?',
    popover  : 'Unmarried includes single, divorced, and widowed persons.',
  },
  [FieldNames.dateOfBirth]: {
    component: SinglePastDate,
    label    : 'Date of Birth',
    name     : FieldNames.dateOfBirth,

  },
  [FieldNames.hasAddlPropertiesYN]: {
    component : RadioField,
    name      : FieldNames.hasAddlPropertiesYN,
    options   : YES_OR_NO_OPTIONS,
    paragraph : FormParagraphs.additionalProperties,
  },
  [FieldNames.employPrevTitle]: {
    component  : TextInput,
    label      : 'Previous Job Title',
    isOptional : true,
    name       : FieldNames.employPrevTitle,
    showIf     : [isLessThanTwoYrsAtJob],
  },
  [FieldNames.coBorrowerEmployPrevTitle]: {
    component  : TextInput,
    label      : 'Previous Job Title',
    isOptional : true,
    name       : FieldNames.coBorrowerEmployPrevTitle,
    showIf     : [isCoLessThanTwoYrsAtJob],
  },
  [FieldNames.citizenshipType]: {
    component : Select,
    name      : FieldNames.citizenshipType,
    options   : CITIZENSHIP_TYPE_OPTIONS,
    label     : 'Citizenship Type',
    title     : 'What is your citizenship?',
  },
  [FieldNames.coBorrowerCitizenshipType]: {
    component : Select,
    name      : FieldNames.coBorrowerCitizenshipType,
    options   : CITIZENSHIP_TYPE_OPTIONS,
    label     : 'Citizenship Type',
    title     : `What is your co-borrower's citizenship?`,
  },
  [FieldNames.militaryExperience]: {
    component  : Select,
    name       : FieldNames.militaryExperience,
    options    : MILITARY_EXPERIENCE_OPTIONS,
    label      : 'Miltary Experience',
    title      : 'What is your military experience?',
  },
  [FieldNames.coBorrowerMilitaryExperience]: {
    component  : Select,
    name       : FieldNames.coBorrowerMilitaryExperience,
    options    : MILITARY_EXPERIENCE_OPTIONS,
    label      : 'Miltary Experience',
    title      : 'What is your co-borrower’s military experience?',
  },
  [FieldNames.employOwnershipShare]: {
    component : Select,
    name      : FieldNames.employOwnershipShare,
    options   : EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
    label     : 'Ownership Share',
    title     : 'For this business, I have:',
    showIf    : [isSelfEmployed],
  },
  [FieldNames.coBorrowerEmployOwnershipShare]: {
    component : Select,
    name      : FieldNames.coBorrowerEmployOwnershipShare,
    options   : EMPLOYMENT_OWNERSHIP_SHARE_OPTIONS,
    label     : 'Co-Borrower\'s Ownership Share',
    title     : 'For this business, they have:',
    showIf    : [isCoSelfEmployed],
  },
  [FieldNames.employPartyTransaction]: {
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    name      : FieldNames.employPartyTransaction,
    title     : 'I am employed by a family member, property seller, real estate agent, or other party to the transaction:',
    showIf    : [isEmployee, isSelfEmployed],
  },
  [FieldNames.coBorrowerEmployPartyTransaction]: {
    component : RadioField,
    options   : YES_OR_NO_OPTIONS,
    name      : FieldNames.coBorrowerEmployPartyTransaction,
    title     : 'They are employed by a family member, property seller, real estate agent, or other party to the transaction:',
    showIf    : [isCoEmployee, isCoSelfEmployed],
  },
  [FieldNames.propertyMonthlyRentalIncome]: {
    component : CurrencyAllowZero,
    name      : FieldNames.propertyMonthlyRentalIncome,
    label     : 'Rental Income',
    showIf    : [isInvestmentHome, isMultiFamilyHome],
  },
};

export const INCOME_DISCLAIMER = 'Alimony, child support, or separate maintenance income need not be revealed if the Borrower or Co-Borrower does not choose to have it considered for repaying this loan.';

export const AG_SHARED: Question[] = [
  {
    id        : 4701,
    component : RadioField,
    name      : FieldNames.isAGExistingCustomer,
    options   : YES_OR_NO_OPTIONS,
    title     : 'Are you an existing AG Loan customer?',
  },
  {
    id         : 4702,
    component  : Name,
    label      : 'Business Name',
    name       : FieldNames.commercialBusinessName,
  },
  {
    id        : 4703,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.commercialStreet,
    title     : 'Where is the business located?',
  },
  {
    id         : 4704,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.commercialStreet2,
  },
  {
    id        : 4705,
    component : Address,
    label     : 'City',
    name      : FieldNames.commercialCity,
  },
  {
    id        : 4706,
    component : State,
    label     : 'State',
    name      : FieldNames.commercialState,
  },
  {
    id        : 4707,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.commercialZip,
  },
  {
    id        : 4708,
    ...SHARED[FieldNames.phone],
    name   : FieldNames.commercialPhone,
  },
  {
    id        : 4709,
    component : Integer,
    label     : 'Number of Employees',
    name      : FieldNames.commercialEmployeeCount,
  },
  {
    id        : 4710,
    component : Select,
    label     : 'Entity Type',
    name      : FieldNames.commercialEntityType,
    options   : COMMERCIAL_ENTITY_TYPE_OPTIONS,
  },
  {
    id        : 4711,
    component : Select,
    label     : 'Year Started',
    name      : FieldNames.commercialYearStarted,
    options   : YEAR_1970PLUS_TO_NOW,
  },
  {
    id        : 4712,
    component : Select,
    label     : '3 Year Revenue Trend',
    name      : FieldNames.commercialRevenueTrend3,
    options   : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id        : 4713,
    component : Currency,
    label     : 'Revenue for Last Fiscal Year',
    name      : FieldNames.commercialRevenueLastYear,
  },
  {
    id        : 4714,
    label     : 'Revenue Explanation',
    component : TextArea,
    name      : FieldNames.commercialRevenueExplanation,
  },
  {
    id        : 4715,
    component  : Select,
    label      : '3 Year Profitability Trend',
    name       : FieldNames.commercialProfitTrend3,
    options    : COMMERCIAL_TREND_OPTIONS,
  },
  {
    id        : 4716,
    label       : 'Profit Explanation',
    component   : TextArea,
    name        : FieldNames.commercialProfitExplanation,
  },
  {
    id        : 4717,
    label       : 'List of Key Collateral',
    component   : TextArea,
    name        : FieldNames.commercialCollateralList,
  },
  {
    id        : 4718,
    component   : Owners,
    name        : FieldNames.commercialOwnerList,
  },
  {
    id        : 4719,
    component : RadioField,
    title     : 'Will the loan be held by the business or an individual?',
    name      : FieldNames.commercialLoanHolder,
    options   : COMMERCIAL_LOAN_HOLDER_OPTIONS,
  },
  {
    id        : 4720,
    ...SHARED[FieldNames.firstName],
  },
  {
    id        : 4721,
    ...SHARED[FieldNames.middleName],
  },
  {
    id        : 4722,
    ...SHARED[FieldNames.lastName],
  },
  {
    id        : 4723,
    ...SHARED[FieldNames.suffixName],
  },
  {
    id        : 4724,
    ...SHARED[FieldNames.email],
  },
  {
    id        : 4725,
    ...SHARED[FieldNames.phone],
  },
  {
    id        : 4726,
    ...SHARED[FieldNames.dateOfBirth],
  },
  {
    id        : 4762,
    component : RadioField,
    name      : FieldNames.livingRentOrOwn,
    options   : COMMERCIAL_LIVING_SITUATION_OPTIONS,
  },
  {
    id        : 4727,
    ...SHARED[FieldNames.propertyType],
    component    : Select,
    fieldPopover : true,
    name         : FieldNames.livingPropertyType,
  },
  {
    id        : 4728,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.livingPropertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet,
    },
  },
  {
    id        : 4729,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.livingPropertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.livingPropertyStreet2,
    },
  },
  {
    id        : 4730,
    component : Address,
    label     : 'City',
    name      : FieldNames.livingPropertyCity,
  },
  {
    id        : 4731,
    component : State,
    label     : 'State',
    name      : FieldNames.livingPropertyState,
  },
  {
    id        : 4732,
    ...SHARED[FieldNames.propertyZip],
    name : FieldNames.livingPropertyZip,
  },
  {
    id        : 4733,
    component : Currency,
    label     : 'Monthly Rent Payment',
    name      : FieldNames.livingMonthlyRentAmount,
  },
  {
    id        : 4734,
    component  : RadioField,
    name       : FieldNames.employmentType,
    options    : EMPLOYMENT_TYPE_OPTIONS,
    disclaimer : INCOME_DISCLAIMER,
  },
  {
    id        : 4735,
    component : RadioField,
    title     : 'Do you work for the business seeking the loan?',
    name      : FieldNames.commercialEmploySameBusiness,
    options   : YES_OR_NO_OPTIONS,
  },
  {
    id        : 4736,
    component : TextInput,
    label     : 'Company Name',
    name      : FieldNames.employCompanyName,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4737,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employStreet,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4738,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employStreet2,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4739,
    component : Address,
    label     : 'City',
    name      : FieldNames.employCity,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4740,
    component : State,
    label     : 'State',
    name      : FieldNames.employState,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4741,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.employZip,
    showIf    : [(values) => !isEmploySameBusiness(values)],
  },
  {
    id        : 4742,
    component  : TextInput,
    label      : 'Job Title',
    isOptional : true,
    name       : FieldNames.employTitle,
  },
  {
    id        : 4743,
    ...SHARED[FieldNames.phone],
    name : FieldNames.employPhone,
  },
  {
    id        : 4744,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employStart,
  },
  {
    id        : 4745,
    component : Currency,
    label     : 'Monthly Base Pay',
    name      : FieldNames.employBase,
  },
  {
    id        : 4746,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Overtime',
    name         : FieldNames.employOvertime,
    title        : 'Additional income sources?',
    popover      : 'Overtime can be included in your income if it has been consistent over a two-year period.',
  },
  {
    id        : 4747,
    component   : Currency,
    fieldPopover: true,
    isOptional  : true,
    label       : 'Monthly Commission',
    name        : FieldNames.employCommission,
    popover     : 'Commissions can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 4748,
    component    : Currency,
    fieldPopover : true,
    isOptional   : true,
    label        : 'Monthly Bonus',
    name         : FieldNames.employBonus,
    popover      : 'Bonuses can be included in your income if they have been consistent over a two-year period.',
  },
  {
    id        : 4749,
    component : IncomeSources,
    name      : FieldNames.additionalIncome,
  },
  {
    id        : 4750,
    component : TextInput,
    label     : 'Previous Employers Name',
    name      : FieldNames.employPrevName,
    title     : 'Previous employment information',
    showIf    : [isLessThanTwoYrsAtJob],
    popover   : 'Lenders will require previous employer information if you\'ve been with this employer for less than 2 years.',
  },
  {
    id        : 4751,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.employPrevStreet,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4752,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.employPrevStreet2,
    showIf     : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4753,
    component : Address,
    label     : 'City',
    name      : FieldNames.employPrevCity,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4754,
    component : State,
    label     : 'State',
    name      : FieldNames.employPrevState,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4755,
    component : ZipCode,
    label     : 'Zip',
    name      : FieldNames.employPrevZip,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4756,
    ...SHARED[FieldNames.employPrevTitle],
  },
  {
    id        : 4757,
    component : SinglePastDate,
    label     : 'Start Date',
    name      : FieldNames.employPrevStart,
    showIf    : [isLessThanTwoYrsAtJob],
  },
  {
    id        : 4758,
    component : SinglePastDate,
    label     : 'End Date',
    name      : FieldNames.employPrevEnd,
    showIf    : [isLessThanTwoYrsAtJob],
    validator: {
      type : 'isAfterStartDate',
      param: FieldNames.employPrevStart,
    },
  },
  {
    id        : 4759,
    component : RetirementIncomeSources,
    title     : 'Tell us about your retirement income',
    name      : FieldNames.retirementIncome,
    showIf    : [isRetired],
  },
  {
    id        : 4760,
    component : OtherIncomeSources,
    title     : 'Tell us about your other income',
    name      : FieldNames.otherIncome,
    showIf    : [isEmploymentOther],
  },
  {
    id        : 4761,
    component : TextInput,
    label     : 'Loan Number',
    name      : FieldNames.existingLoanNumber,
  },
];
