import { FieldNames } from 'app/models/fields/names';
import { RadioField } from 'app/components/FormFields/RadioField';
import { AGRICULTURAL_LOAN_TYPE_OPTIONS } from 'app/models/options/options';
import { Question } from 'app/models/types';

/**
 * Takes in a field name and returns the corresponding form landing
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const getAgriculturalQuestionByName = (name: string): Question => {
  return AGRICULTURAL_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const getAgriculturalQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(getAgriculturalQuestionByName(name));
  }
  return questions;
};

export const AGRICULTURAL_QUESTIONS: Question[] = [
  {
    id       : 1001,
    component: RadioField,
    name     : FieldNames.loanType,
    options  : AGRICULTURAL_LOAN_TYPE_OPTIONS,
    title    : 'What type of Ag Loan would you like?',
  },
];
