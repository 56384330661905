import { routes } from './route-list';
import { Section } from 'app/models/types';
import { getLandingSectionById } from 'app/models/sections/landing';
import { getAutoLoanSectionById } from 'app/models/sections/auto-loan';
import { getApplicationHomeEquitySectionById } from 'app/models/sections/application-home-equity';
import { getApplicationPurchaseSectionById } from 'app/models/sections/application-purchase';
import { getApplicationRefinanceSectionById } from 'app/models/sections/application-refinance';
import { getAutoPrequalSectionById } from 'app/models/sections/auto-prequal';
import { getAutoPrequalRenewSectionById } from 'app/models/sections/auto-prequal-renew';
import { getHomeEquitySectionById } from 'app/models/sections/home-equity';
import { getPurchaseSectionById } from 'app/models/sections/purchase';
import { getRefinanceSectionById } from 'app/models/sections/refinance';
import { getPersonalLoanById } from 'app/models/sections/personal-loan';
import { sectionFromHash } from 'app/routes/helpers';
import { getCommercialPurchaseSectionById } from 'app/models/sections/commercial-purchase';
import { getCommercialRefinanceSectionById } from 'app/models/sections/commercial-refinance';
import { getClosingCostSectionById } from 'app/models/sections/closing-costs';
import { getOVLoanSectionById } from '../models/sections/ov-loan';
import { getCreditCardSectionById } from '../models/sections/credit-card';
import { getHomeEquityTurboSectionById } from 'app/models/sections/home-equity-turbo';
import { getApplicationConstructionSectionById } from '../models/sections/application-construction';
import { getReverseSectionById } from '../models/sections/reverse';
import { getCreditBoosterSectionById } from '../models/sections/credit-booster';
import { getCommercialEquipmentSectionById } from '../models/sections/commercial-equipment';
import { getCommercialVehicleSectionById } from '../models/sections/commercial-vehicle';
import { getCommercialBridgeLoanSectionById } from '../models/sections/commercial-bridge-loan';
import { getInsuranceQuoteSectionById } from 'app/models/sections/insurance-quote';
import { getFinancialStatementSectionById } from '../models/sections/financial-statement';
import { getCommercialRenewSectionById } from '../models/sections/commercial-renew';
import { getCommercialSectionById } from '../models/sections/commercial';
import { getSBLineOfCreditSectionById } from '../models/sections/sb-line-of-credit';
import { getSBTermSectionById } from '../models/sections/sb-term';
import { getApplicationLandSectionById } from '../models/sections/application-land';
import { getOverdraftProtectionById } from '../models/sections/overdraft-protection';
import { getRefinanceTurboSectionById } from '../models/sections/refinance-turbo';
import { getHomeEquityRatesSectionById } from 'app/models/sections/home-equity-rates';
import { getPurchaseTurboSectionById } from '../models/sections/purchase-turbo';
import { getAgriculturalSectionById } from 'app/models/sections/commercial-agricultural';
import { getAgriculturalLineOfCreditSectionById } from 'app/models/sections/commercial-agricultural-line-of-credit';
import { getAgriculturalPurchaseEquipmentSectionById } from '../models/sections/commercial-agricultural-purchase-equipment';
import { getAgriculturalPurchaseAcreageSectionById } from '../models/sections/commercial-agricultural-purchase-acreage';
import { getAgriculturalRefinancePropertySectionById } from '../models/sections/commercial-agricultural-refinance-property';

/**
 * Gets a form section related to the location provided
 *
 * @param {Location} location Location object from the router
 */
export const getSectionFromLocation = (location: Location) => {
  const { hash, pathname } = location;

  return getSectionById(pathname, sectionFromHash(hash));
};

/**
 * Gets a form section from the pathname and id provided
 *
 * @param {string} pathname Pathname from the router
 * @param {number} id Section id from the hash
 * @returns {(Section | undefined)}
 */
export const getSectionById = (pathname: string, id: number): Section | undefined => {
  const map = {
    [routes.agricultural]            : getAgriculturalSectionById,
    [routes.agLineOfCredit]          : getAgriculturalLineOfCreditSectionById,
    [routes.agPurchaseAcreage]       : getAgriculturalPurchaseAcreageSectionById,
    [routes.agPurchaseEquipment]     : getAgriculturalPurchaseEquipmentSectionById,
    [routes.agRefinanceProperty]     : getAgriculturalRefinancePropertySectionById,
    [routes.application]             : getLandingSectionById,
    [routes.applicationConstruction] : getApplicationConstructionSectionById,
    [routes.applicationHomeEquity]   : getApplicationHomeEquitySectionById,
    [routes.applicationLand]         : getApplicationLandSectionById,
    [routes.applicationPurchase]     : getApplicationPurchaseSectionById,
    [routes.applicationRefinance]    : getApplicationRefinanceSectionById,
    [routes.autoLoan]                : getAutoLoanSectionById,
    [routes.autoPrequal]             : getAutoPrequalSectionById,
    [routes.autoPrequalRenew]        : getAutoPrequalRenewSectionById,
    [routes.closingCostsPurchase]    : getClosingCostSectionById,
    [routes.closingCostsRefinance]   : getClosingCostSectionById,
    [routes.commercial]              : getCommercialSectionById,
    [routes.commercialBridgeLoan]    : getCommercialBridgeLoanSectionById,
    [routes.commercialBusinessLoan]  : getCommercialSectionById,
    [routes.commercialEquipment]     : getCommercialEquipmentSectionById,
    [routes.commercialPurchase]      : getCommercialPurchaseSectionById,
    [routes.commercialRefinance]     : getCommercialRefinanceSectionById,
    [routes.commercialRenew]         : getCommercialRenewSectionById,
    [routes.commercialVehicle]       : getCommercialVehicleSectionById,
    [routes.creditBooster]           : getCreditBoosterSectionById,
    [routes.creditCard]              : getCreditCardSectionById,
    [routes.financialStatement]      : getFinancialStatementSectionById,
    [routes.homeEquity]              : getHomeEquitySectionById,
    [routes.homeEquityRates]         : getHomeEquityRatesSectionById,
    [routes.homeEquityTurbo]         : getHomeEquityTurboSectionById,
    [routes.insuranceQuote]          : getInsuranceQuoteSectionById,
    [routes.otherVehicle]            : getOVLoanSectionById,
    [routes.overdraftProtection]     : getOverdraftProtectionById,
    [routes.personalLoan]            : getPersonalLoanById,
    [routes.purchase]                : getPurchaseSectionById,
    [routes.purchaseTurbo]           : getPurchaseTurboSectionById,
    [routes.refinance]               : getRefinanceSectionById,
    [routes.refinanceTurbo]          : getRefinanceTurboSectionById,
    [routes.reverse]                 : getReverseSectionById,
    [routes.sbLineOfCredit]          : getSBLineOfCreditSectionById,
    [routes.sbTerm]                  : getSBTermSectionById,
  };
  return map[pathname] ? map[pathname](id) : undefined;
};
