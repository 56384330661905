import { formValueSelector } from 'redux-form';
import { CurrentLoanType } from 'app/models/options/enums';
import { FieldNames } from 'app/models/fields/names';
import { createSelector } from 'reselect';
import { ratesSelector } from 'app/reducers/rates';
import { FormName, RefinancePurpose } from '@lenderful/domain';
import { RateInfo } from 'app/models/types';

const HIGHEST_POSSIBLE_TERM = 40;

export const currentLoanTermSelector = (state) => formValueSelector(FormName.REFINANCE_TURBO)(state, FieldNames.currentLoanTerm);
export const currentLoanTypeSelector = (state) => formValueSelector(FormName.REFINANCE_TURBO)(state, FieldNames.currentLoanType);
export const currentMonthlyPaymentSelector = (state) => formValueSelector(FormName.REFINANCE_TURBO)(state, FieldNames.currentMonthlyPayment);
export const loanPurposeSelector = (state) => formValueSelector(FormName.REFINANCE_TURBO)(state, FieldNames.loanPurpose);

/**
 * This selector delegates to a filter to return the optimal rates and programs based on the scenario of the
 * borrower's current loan.  It uses term, type, purpose, and monthly payment to filter down the rates and programs.
 */
export const optimalProgramsSelector = createSelector(
  [ratesSelector, currentLoanTermSelector, currentLoanTypeSelector, currentMonthlyPaymentSelector, loanPurposeSelector],
  (rates, currentLoanTerm, currentLoanType, currentMonthlyPayment, loanPurpose) => {
    return filterOptimalPrograms(rates, loanPurpose, currentMonthlyPayment, currentLoanType, currentLoanTerm);
  },
);

/**
 * This method returns the optimal rates and programs based on the scenario of the borrower's current loan.  It uses term,
 * type, purpose, and monthly payment to filter down the rates and programs.
 *
 * @param {RateInfo[]} rates
 * @param {RefinancePurpose} loanPurpose
 * @param {number} currentMonthlyPayment
 * @param {CurrentLoanType} currentLoanType
 * @param {number} currentLoanTerm
 * @returns {RateInfo[]}
 */
export const filterOptimalPrograms = (
  rates: RateInfo[],
  loanPurpose: RefinancePurpose,
  currentMonthlyPayment: number,
  currentLoanType: CurrentLoanType,
  currentLoanTerm: number,
) => {
  if (loanPurpose === RefinancePurpose.MONTHLY) {
    return rates.filter(rate => rate.principalAndInterestPayment < currentMonthlyPayment);
  }
  if (loanPurpose === RefinancePurpose.TERMS) {
    const loanTerm = findLoanTerm(currentLoanType, currentLoanTerm);
    return rates.filter(rate => rate.term < loanTerm);
  }
  return rates;
};

/**
 * This method will use loan term when it exists.  Otherwise, it will determine the loan term
 * based on the fixed years of the loan type program.
 *
 * @param {CurrentLoanType} loanType
 * @param {number} loanTerm
 * @returns {number}
 */
const findLoanTerm = (loanType: CurrentLoanType, loanTerm?: number): number => {
  if (loanTerm) {
    return loanTerm;
  }
  if (loanType === CurrentLoanType.THIRTY) {
    return 30;
  }
  if (loanType === CurrentLoanType.TWENTY) {
    return 20;
  }
  if (loanType === CurrentLoanType.FIFTEEN) {
    return 15;
  }
  if (loanType === CurrentLoanType.TEN) {
    return 10;
  }
  return HIGHEST_POSSIBLE_TERM + 1;
};
