import { Questions } from './Questions';
import { AG_SHARED } from './shared';
import { RadioField } from '../../components/FormFields/RadioField';
import { FieldNames } from '../fields/names';
import {
  AG_PROPERTY_TYPE_OPTIONS,
  COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
  REFINANCE_REASON_OPTIONS,
  YEAR_1970PLUS_TO_NOW,
} from '../options/options';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { ZipCode } from '../../components/FormFields/ZipCode';
import { Select } from '../../components/FormFields/Select';
import { Currency } from '../../components/FormFields/Currency';
import { IntegerAllowZero } from '../../components/FormFields/IntegerAllowZero';

export const AGRICULTURAL_REFINANCE_PROPERTY_QUESTIONS: Questions = new Questions([
  {
    id        : 1001,
    component : RadioField,
    label     : 'Purpose of Refinance',
    hideLabel : true,
    name      : FieldNames.loanPurpose,
    options   : REFINANCE_REASON_OPTIONS,
  },
  {
    id        : 1002,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.propertyStreet,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id         : 1003,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id        : 1004,
    component : Address,
    label     : 'City',
    name      : FieldNames.propertyCity,
  },
  {
    id        : 1005,
    component : State,
    label     : 'State',
    name      : FieldNames.propertyState,
  },
  {
    id        : 1006,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.propertyZip,
  },
  {
    id           : 1007,
    component    : Select,
    label        : 'Property Type',
    name         : FieldNames.agPropertyType,
    fieldPopover : true,
    options      : AG_PROPERTY_TYPE_OPTIONS,
  },
  {
    id        : 1008,
    component : Currency,
    label     : 'Estimated Property Value',
    name      : FieldNames.propertyValue,
  },
  {
    id        : 1009,
    component : Currency,
    label     : 'Current Total Lien Balance',
    name      : FieldNames.commercialLienBalance,
  },
  {
    id        : 1010,
    component : Currency,
    label     : 'Requested Loan Amount',
    name      : FieldNames.loanAmount,
  },
  {
    id        : 1011,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
  },
  {
    id        : 1012,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    id         : 1013,
    component  : IntegerAllowZero,
    label      : 'Land or Site Size (approximately)',
    endAdornment : 'Square feet',
    name       : FieldNames.landSize,
    validator  : {
      type  : 'isGreaterThanZero',
      param : FieldNames.landSize,
    },
  },
  {
    id         : 1014,
    component  : Select,
    label      : 'Number of Buildings or Structures',
    name       : FieldNames.numOfStructures,
    options    : COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
  },
  {
    id         : 1015,
    component  : IntegerAllowZero,
    label      : 'Building Square Footage (approximately)',
    name       : FieldNames.buildingSquareFootage,
  },
  {
    id         : 1016,
    component  : Select,
    isOptional : true,
    label      : 'Year Built',
    name       : FieldNames.builtYear,
    options    : YEAR_1970PLUS_TO_NOW,
  },
  ...AG_SHARED,
]);
