import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { PersistConfig, persistReducer, createTransform } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import { submittedDataReducer } from 'app/reducers/submitted-data';
import { emailedRatesReducer } from 'app/reducers/email-rates';
import { userReducer } from 'app/reducers/user';
import { partialReducer } from 'app/reducers/partial';
import { creditReportReducer } from 'app/reducers/credit-report';
import { addressValidationReducer } from 'app/reducers/address-validation';
import { referToLoReducer } from 'app/reducers/refer-to-lo';
import { loanOfficerReducer } from 'app/reducers/loan-officer';
import { checklistReducer } from 'app/reducers/checklist';
import { appConfigsReducer } from 'app/reducers/app-config';
import { formReducer } from 'app/reducers/form';
import { ratesReducer } from 'app/reducers/rates';
import { autoValuationReducer } from 'app/reducers/auto-valuation';
import { zipCodeValidationReducer } from '../reducers/zip-code-validation';
import { financialVerificationReducer } from 'app/reducers/verification';
import { reverseMortgageReducer } from 'app/reducers/reverse-mortgage';
import { homeEquityRatesReducer } from 'app/reducers/home-equity-rates';
import { reportReducer } from '../reducers/report';

const getAppVersion = (envVersion: string | undefined): number => {
  return envVersion ? parseInt(envVersion.split('.').join(''), 10) : -1;
};

// Persist Transforms
// ------------------

/*
 * Transformer for ensuring only the redux-form initial values
 * and user values are put into the persistent storage and not
 * the form meta data.
 */
const KeepFormValues = createTransform(
  (inboundState, key) => {
    return Object.keys(inboundState)
      /* Store all forms */
      .reduce((newState, formName) => {
        const { initial, values } = inboundState[formName];
        newState[formName] = { initial, values };
        return newState;
      }, {});
  },
  (outboundState, key) => {
    return { ...outboundState };
  },
  { whitelist: ['form'] },
);

// Persist Configs
// ---------------

const commonConfig: Partial<PersistConfig> = {
  debug  : process.env.NODE_ENV === 'development',
  version: getAppVersion(process.env.REACT_APP_VERSION),
};

const rootPersistConfig: PersistConfig = {
  key       : 'root',
  whitelist : ['autoValuation', 'financialVerification', 'homeEquityRates', 'rates', 'submittedData', 'form', 'creditReport', 'branchLocations', 'loanOfficers', 'checklistPdf', 'report'],
  transforms: [KeepFormValues],
  storage   : sessionStorage,
  ...commonConfig,
};

const userPersistConfig: PersistConfig = {
  key      : 'user',
  blacklist: ['isLoading'],
  version  : getAppVersion(process.env.REACT_APP_VERSION),
  storage  : sessionStorage,
  ...commonConfig,
};

export const rootReducer = (history) => combineReducers({
  addressValidation     : addressValidationReducer,
  autoValuation         : autoValuationReducer,
  checklistPdf          : checklistReducer,
  config                : appConfigsReducer,
  creditReport          : creditReportReducer,
  emailedRates          : emailedRatesReducer,
  financialVerification : financialVerificationReducer,
  form                  : formReducer,
  homeEquityRates       : homeEquityRatesReducer,
  loanOfficers          : loanOfficerReducer,
  partial               : partialReducer,
  rates                 : ratesReducer,
  referToLo             : referToLoReducer,
  reverseMortgage       : reverseMortgageReducer,
  report                : reportReducer,
  router                : connectRouter(history),
  submittedData         : submittedDataReducer,
  user                  : persistReducer(userPersistConfig, userReducer),
  zipCodeValidation     : zipCodeValidationReducer,
});

export const persistedRootReducer = (history) => persistReducer(
  rootPersistConfig,
  rootReducer(history),
);
