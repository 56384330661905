import { CurrencyAllowZero } from 'app/components/FormFields/CurrencyAllowZero';
import { State } from 'app/components/FormFields/State';
import { TextInput } from 'app/components/FormFields/TextInput';
import { ZipCode } from 'app/components/FormFields/ZipCode';
import { isTakingCashOut } from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import { SHARED } from 'app/models/questions/shared';
import { Question } from 'app/models/types';

/**
 * Takes in a field name and returns the corresponding purchase
 * question
 *
 * @param {string} name Field name
 * @returns Question with matching field name
 */
export const closingCostQuestionByName = (name: string): Question => {
  return CLOSING_COST_QUESTIONS.find((question) => {
    return question.name === name;
  }) as Question;
};

/**
 * Takes in a question id and returns the corresponding purchase
 * question
 *
 * @param {number} id Question id
 * @returns Question with matching id
 */
export const closingCostQuestionById = (id: number): Question => {
  return CLOSING_COST_QUESTIONS.find((question) => {
    return question.id === id;
  }) as Question;
};

/**
 * Takes in an array of field names and returns an array of questions
 *
 * @param {string[]} [names=[]] Field names
 * @returns Questions matching field names
 */
export const closingCostQuestionsByName = (names: string[] = []): Question[] => {
  const questions: Question[] = [];
  for (const name of names) {
    questions.push(closingCostQuestionByName(name));
  }
  return questions;
};

export const CLOSING_COST_QUESTIONS: Question[] = [
  {
    id: 1001,
    component: TextInput,
    name: FieldNames.propertyStreet,
    label: 'Street Address',
    title: 'What is the address of the property?',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
  },
  {
    id: 1002,
    component: TextInput,
    name: FieldNames.propertyStreet2,
    label: 'Street Address 2',
    isOptional: true,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
  },
  {
    id: 1003,
    component: TextInput,
    name: FieldNames.propertyCity,
    label: 'City',
  },
  {
    id: 1004,
    component: State,
    name: FieldNames.propertyState,
    label: 'State',
  },
  {
    id: 1005,
    component: ZipCode,
    name: FieldNames.propertyZip,
    label: 'Zip',
  },
  {
    id: 1006,
    ...SHARED[FieldNames.escrowUsage],
    name  : FieldNames.escrowUsage,
    title : 'Do you have an escrow account?',
  },
  {
    id        : 1007,
    component : CurrencyAllowZero,
    title     : 'What is your current mortgage balance?',
    label     : 'Mortgage Balance',
    name      : FieldNames.mortgageBalance,
    showIf    : [(values) => isTakingCashOut(values)],
  },
];
