import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { logger } from 'app/util/logger';
import { getSlug } from 'app/util/headers';
import { ClosingCostsEstimateRequest, ClosingCostsEstimateResponse } from 'app/api/closing-costs-estimate/types';

/**
 * This API call returns the closing costs PDF as binary
 *
 * @param {ClosingCostsEstimateRequest} request
 * @returns {Blob} file-like object that represents binary data for the PDF
 */
export const postClosingCostsEstimate = (request: ClosingCostsEstimateRequest): Promise<ClosingCostsEstimateResponse> => {
  const headers = { accept: 'application/pdf' };
  request.slug = getSlug();
  return client
    .post<ClosingCostsEstimateResponse>(Endpoints.CLOSING_COSTS_ESTIMATE, request, { headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('ERROR: Failed to get closing costs PDF');
      throw error;
    });
};
