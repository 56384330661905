import { FormName } from '@lenderful/domain';

export enum FormType {
  PURCHASE  = 'purchase',
  REFINANCE = 'refinance',
  PREQUAL   = 'prequal'
}
export enum PropertyType {
  SINGLE                          = 'SFH',
  CONDO                           = 'CON',
  MODULAR                         = 'MOD',
  MULTI                           = '24U',
  MANUFACTURED_HOME_ON_FOUNDATION = 'MAN',
}
export enum PropertyUsage {
  PRIMARY    = 'PRI',
  SECONDARY  = '2ND',
  INVESTMENT = 'INV',
  UNKNOWN    = 'UNK'
}
export enum BuyingProcess {
  SIGNED   = 'Signed Purchase Agreement',
  PENDING  = 'Offer Pending',
  SHOPPING = 'Shopping',
  OTHER    = 'Other'
}
export enum PurchaseTimeline {
  SHORT  = '0-3 Months',
  MEDIUM = '3-6 Months',
  LONG   = '6+ Months'
}
export enum EscrowUsage {
  YES = 'Yes',
  NO  = 'No'
}
export enum YesOrNo {
  YES = 'Yes',
  NO  = 'No'
}
export enum BankruptType {
  SEVEN    = 'SEVEN',
  ELEVEN   = 'ELEVEN',
  TWELVE   = 'TWELVE',
  THIRTEEN = 'THIRTEEN'
}
export enum BankruptAge {
  ZERO,
  ONE,
  TWO,
  THREE,
  FOUR,
  FIVE,
  SIX,
  SEVEN
}
export enum Purpose {
  PURCHASE = 'PURCHASE',
  PREQUAL  = 'PREQUAL'
}
export enum LandingLoanType {
  PURCHASE  = 'PURCHASE',
  REFINANCE = 'REFINANCE'
}
export enum LiabilityUsage {
  PRIMARY_HOME    = 'PRIMARY_HOME',
  OTHER_HOME      = 'OTHER_HOME',
  NOT_MY_HOME     = 'NOT_MY_HOME',
  COLLATERAL_HOME = 'COLLATERAL_HOME',
}
export enum PartialSource {
  SAVE     = 'SAVE',
  REFERRED = 'REFERRED_TO_LO'
}
export enum DayOfWeek {
  ANY       = 'Any Day',
  MONDAY    = 'Monday',
  TUESDAY   = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY  = 'Thursday',
  FRIDAY    = 'Friday'
}
export enum TimeOfDay {
  ANY    = 'Any Time',
  NINE   = '9am',
  TEN    = '10am',
  ELEVEN = '11am',
  TWELVE = '12pm',
  ONE    = '1pm',
  TWO    = '2pm',
  THREE  = '3pm',
  FOUR   = '4pm'
}

export enum NameSuffix {
  JR  = 'Jr',
  SR  = 'Sr',
  II  = 'II',
  III = 'III',
  IV  = 'IV',
  V   = 'V',
}

export enum CitizenshipType {
  US_CITIZEN        = '01',
  PERM_RESIDENT     = '03',
  NON_PERM_RESIDENT = '05',
}

export enum EmploymentOwnershipShare {
  LESS_THAN_25_PERCENT             = 'LT',
  GREATER_THAN_OR_EQUAL_25_PERCENT = 'GT',
}

export enum VehiclePurchasePlan {
  PURCHASE_FROM_DEALER_NEW  = 'N',
  PURCHASE_FROM_DEALER_USED = 'U',
  REFINANCE_CURRENT_VEHICLE = 'R',
  LEASE_BUYOUT              = 'L',
  PRIVATE_PARTY_PURCHASE    = 'P'
}

export enum DesiredLoanTerm {
  MONTHS_12 = 12,
  MONTHS_24 = 24,
  MONTHS_36 = 36,
  MONTHS_48 = 48,
  MONTHS_60 = 60,
  MONTHS_72 = 72,
  MONTHS_84 = 84
}

export const CONSUMER_FORMS = [
  FormName.AUTOLOAN,
  FormName.PERSONALLOAN,
  FormName.OTHER_VEHICLE,
  FormName.CREDITCARD];

export enum ConstructionLoanType {
  NOTSURE  = 'N',
  ONE      = 'ONECLOSING',
  TWO      = 'TWOCLOSING',
}

export enum CommercialPurchasePropertyType {
  HOTELS     = 'Hotels/Hospitality',
  INDUSTRIAL = 'Industrial',
  RETAIL     = 'Retail',
  OFFICE     = 'Office',
  MIXED_USE  = 'Mixed Use',
  OTHER      = 'Other'
}

export enum CommercialEntityType {
  ASSOCIATION         = 'ASSOCIATION',
  CORPORATION         = 'CORPORATION',
  GOVERNMENT_ENTITY   = 'GOVERNMENT_ENTITY',
  INDIVIDUAL          = 'INDIVIDUAL',
  LLC                 = 'LLC',
  NON_PROFIT          = 'NON_PROFIT',
  PARTNERSHIP         = 'PARTNERSHIP',
  PROPRIETORSHIP      = 'PROPRIETORSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  S_CORP              = 'S_CORP',
  TRUST               = 'Trust',
}

export enum CommercialLoanPurpose {
  EQUIPMENT           = 'Equipment',
  BRIDGE_LOAN         = 'Bridge Loan',
  TERM_LOAN           = 'Term Loan',
  INVENTORY_FINANCING = 'Inventory Financing',
  LINE_OF_CREDIT      = 'Line of Credit',
  OTHER               = 'Other',
}

export enum NumberOfStructures {
  ZERO      = '0',
  ONE       = '1',
  TWO       = '2',
  THREE     = '3',
  FOUR      = '4',
  FIVE_PLUS = '5+',
}

export enum CreditBoosterAccountTypes {
  SAVINGS = 'Savings',
  DEPOSIT  = 'Certificate of Deposit'
}

export enum NumberOfVehicles {
  ONE       = '1',
  TWO       = '2',
  THREE     = '3',
  FOUR      = '4',
  FIVE_PLUS = '5+',
}

export enum NewOrUsed {
  NEW       = 'New',
  USED      = 'Used',
}

export enum NumberOfStories {
  ONE   = '1',
  TWO   = '2',
  THREE = '3',
  FOUR  = '4',
}

export enum NumberOfUnits {
  ONE   = '1',
  TWO   = '2',
  THREE = '3',
  FOUR  = '4',
}

export enum DynamicFormFieldArrayType {
  ADDRESS             = 'Address',
  CURRENCY            = 'Currency',
  CURRENCY_ALLOW_ZERO = 'CurrencyAllowZero',
  DATE_FUTURE         = 'DateFuture',
  DATE_PAST           = 'DatePast',
  DATE_SINGLE         = 'DateSingle',
  EMAIL               = 'Email',
  INTEGER_ALLOW_ZERO  = 'IntegerAllowZero',
  NAME                = 'Name',
  PERCENT             = 'Percent',
  PHONE               = 'Phone',
  RADIO_BUTTON        = 'RadioField',
  SELECT              = 'Select',
  STATE               = 'State',
  TEXT_INPUT          = 'TextInput',
  YEAR                = 'Year',
  Y_N_SWITCH_TOGGLE   = 'YesNoSwitchToggle',
  ZIP_CODE            = 'ZipCode',
}

export enum CommercialCollateralDescriptionType {
  ABA                  = 'All Business Assets',
  COMMERCIAL_EQUIPMENT = 'Commercial Equipment',
  COMMERCIAL_VEHICLE   = 'Commercial Vehicle',
}

export enum CurrentLoanType {
  THIRTY  = '30 Year Fixed',
  TWENTY  = '20 Year Fixed',
  FIFTEEN = '15 Year Fixed',
  TEN     = '10 Year Fixed',
  BALLOON = 'Balloon Loan',
  ARM     = 'ARM',
  OTHER   = 'Other',
}

export enum FinancialVerificationIntegration {
  FINICITY = 'FINICITY',
  TRUV     = 'TRUV'
}
