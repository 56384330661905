import { LongFormRequest, LongFormResponse } from 'app/api/loan-application/types';
import { mapAppointment, mapLoanApplication, mapRequestSource } from 'app/api/loan-application/mappings';
import { AppConfigState } from 'app/reducers/app-config';
import { getSalesforceParams, Endpoints } from 'app/api/helpers';
import { client } from 'app/api/_client';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { getSlug } from 'app/util/headers';
import { logger } from 'app/util/logger';
import { FieldNames } from 'app/models/fields/names';
import { FormName } from '@lenderful/domain';

export const mapLongFormRequest = (formData, formName: FormName, loanOfficer: LoanOfficerDetail, config: AppConfigState) => {
  const loanApplication  = mapLoanApplication(formData);
  const appointment      = mapAppointment(formData);
  const source           = mapRequestSource(formName);
  const shortApplicationId: number = formData.insertId || null;
  let creditReportId = formData[FieldNames.creditReportId];
  const clientMeta = {
    isAffinityProgram : formData[FieldNames.isAffinityProgram],
    loanOfficerId     : loanOfficer.id,
    sf                : { ...getSalesforceParams() },
  };
  const request: LongFormRequest = {
    appointment,
    client               : config.name,
    client_meta          : clientMeta,
    creditReportId,
    formName,
    LOAN_APPLICATION     : loanApplication,
    short_application_id : shortApplicationId,
    slug                 : config.slug,
    source,
  };
  return request;
};

export const postLongFormApplication = (body): Promise<LongFormResponse> => {
  const slug = getSlug();
  body = { ...body, slug };
  return client
    .post<LongFormResponse>(Endpoints.LONGFORM, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      logger.error('Error in Long Form API call: ', error);
      throw error;
    });
};
