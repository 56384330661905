import React from 'react';
import { Route, Redirect } from 'react-router';
import { CssBaseline } from '@material-ui/core';
import { routes } from 'app/routes/route-list';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { ModalAddressValidation } from 'app/components/Modals/ModalAddressValidation';
import { ThemeWrapper } from 'app/components/ThemeWrapper';
import { Loader } from 'app/components/Loader';
import { EmployeeReferral } from 'app/components/EmployeeReferral';
import ResumeSessionContainer from 'app/containers/ResumeSessionContainer';
import BranchContainer from 'app/containers/BranchContainer';
import ScheduleContainer from 'app/containers/ScheduleContainer';
import { isOnLoanOfficerSite } from 'app/util/headers';
import {
  CLOSING_COSTS_RATE_SELECTION,
  CONSTRUCTION_GET_PERSONAL_INFORMATION,
  PERSONAL_LOAN_GET_FICO_AND_ZIP_SECTION,
  SUMMARY_SECTION,
  FINAL_SUMMARY_SECTION,
  REVERSE_LOAN_GOAL,
  CREDIT_BOOSTER_APP_GENERAL_INFO,
  FINANCIAL_STATEMENT_APP_PERSONAL_INFO,
  SB_LINE_OF_CREDIT_LOAN_INFO,
  SB_TERM_LOAN_INFO,
  LAND_GET_PERSONAL_INFORMATION,
  CREDIT_CARD_APP_DOB,
  OVERDRAFT_PROTECTION_GET_FICO_AND_ZIP_SECTION,
  REFINANCE_TURBO_OPTIMAL_SUMMARY,
  REFINANCE_TURBO_SUMMARY,
  REFINANCE_TURBO_FINAL_SUMMARY,
  PURCHASE_TURBO_SUMMARY,
  PURCHASE_TURBO_SUMMARY_AFTER_CREDIT,
} from 'app/models/sections/constants';
import { ModalZipCodeValidation } from './components/Modals/ModalZipCodeValidation';
import { NotificationComponent } from 'app/components/Notification/NotificationComponent';
import { NotificationProvider } from 'app/providers/notifications/NotificationProvider';
import RouteCheckComponent from 'app/components/Notification/RouteCheckComponent';

let DevToolWrapper: any = () => null;

/*
 * We want production builds on staging but still want our dev tools to show,
 */
if (process.env.REACT_APP_IS_STAGING_OR_DEV === 'true') {
  DevToolWrapper = React.lazy(() => import('./components/DevTool/DevToolWrapper'));
}

const AutoLoanContainer                           = React.lazy(() => import('app/containers/AutoLoanContainer'));
const AutoLoanSummaryContainer                    = React.lazy(() => import('app/containers/AutoLoanSummaryContainer'));
const BrokerContainer                             = React.lazy(() => import('app/containers/BrokerContainer'));
const CommercialBusinessLoanContainer             = React.lazy(() => import('app/containers/CommercialBusinessLoanContainer'));
const CommercialBridgeLoanContainer               = React.lazy(() => import('app/containers/CommercialBridgeLoanContainer'));
const CommercialContainer                         = React.lazy(() => import('app/containers/CommercialContainer'));
const CommercialEquipmentContainer                = React.lazy(() => import('app/containers/CommercialEquipmentContainer'));
const CommercialPurchaseContainer                 = React.lazy(() => import('app/containers/CommercialPurchaseContainer'));
const CommercialRefinanceContainer                = React.lazy(() => import('app/containers/CommercialRefinanceContainer'));
const CommercialRenewContainer                    = React.lazy(() => import('app/containers/CommercialRenewContainer'));
const CommercialVehicleContainer                  = React.lazy(() => import('app/containers/CommercialVehicleContainer'));
const ContactContainer                            = React.lazy(() => import('app/containers/ContactContainer'));
const ConstructionContainer                       = React.lazy(() => import('app/containers/ConstructionContainer'));
const CreditBoosterContainer                      = React.lazy(() => import('app/containers/CreditBoosterContainer'));
const CreditCardContainer                         = React.lazy(() => import('app/containers/CreditCardContainer'));
const FinancialStatementContainer                 = React.lazy(() => import('app/containers/FinancialStatementContainer'));
const FooterContainer                             = React.lazy(() => import('app/containers/FooterContainer'));
const HeaderContainer                             = React.lazy(() => import('app/containers/HeaderContainer'));
const HomeEquityContainer                         = React.lazy(() => import('app/containers/HomeEquityContainer'));
const HomeEquitySummaryContainer                  = React.lazy(() => import('app/containers/HomeEquitySummaryContainer'));
const HomeEquityRatesContainer                    = React.lazy(() => import('app/containers/HomeEquityRatesContainer'));
const HomeEquityRatesSummaryContainer             = React.lazy(() => import('app/containers/HomeEquityRatesSummaryContainer'));
const LFAutoPrequalContainer                      = React.lazy(() => import('app/containers/LFAutoPrequalContainer'));
const LFAutoPrequalRenewContainer                 = React.lazy(() => import('app/containers/LFAutoPrequalRenewContainer'));
const LFContainer                                 = React.lazy(() => import('app/containers/LFContainer'));
const LFHomeEquityContainer                       = React.lazy(() => import('app/containers/LFHomeEquityContainer'));
const LFPrequalReturningUserContainer             = React.lazy(() => import('app/containers/LFPrequalReturningUserContainer'));
const LFPurchaseContainer                         = React.lazy(() => import('app/containers/LFPurchaseContainer'));
const LFRefinanceContainer                        = React.lazy(() => import('app/containers/LFRefinanceContainer'));
const LFLandContainer                             = React.lazy(() => import('app/containers/LFLandContainer'));
const OtherVehicleContainer                       = React.lazy(() => import('app/containers/OtherVehicleContainer'));
const PageTitleContainer                          = React.lazy(() => import('app/containers/PageTitleContainer'));
const OverdraftProtectionContainer                = React.lazy(() => import('app/containers/OverdraftProtectionContainer'));
const OverdraftProtectionSummaryContainer         = React.lazy(() => import('app/containers/OverdraftProtectionSummaryContainer'));
const PersonalLoanContainer                       = React.lazy(() => import('app/containers/PersonalLoanContainer'));
const PersonalLoanSummaryContainer                = React.lazy(() => import('app/containers/PersonalLoanSummaryContainer'));
const ReverseContainer                            = React.lazy(() => import('app/containers/ReverseContainer'));
const ReverseSummaryContainer                     = React.lazy(() => import('app/containers/ReverseSummaryContainer'));
const SBTermContainer                             = React.lazy(() => import('app/containers/SBTermContainer'));
const SBLineOfCreditContainer                     = React.lazy(() => import('app/containers/SBLineOfCreditContainer'));
const SFPurchaseContainer                         = React.lazy(() => import('app/containers/SFPurchaseContainer'));
const SFRefinanceContainer                        = React.lazy(() => import('app/containers/SFRefinanceContainer'));
const SplashContainer                             = React.lazy(() => import('app/containers/SplashContainer'));
const PurchaseSummaryContainer                    = React.lazy(() => import('app/containers/PurchaseSummaryContainer'));
const RefinanceSummaryContainer                   = React.lazy(() => import('app/containers/RefinanceSummaryContainer'));
const ClosingCostsContainer                       = React.lazy(() => import('app/containers/ClosingCostsContainer'));
const ClosingCostsRateSelectionContainer          = React.lazy(() => import('app/containers/ClosingCostsRateSelectionContainer'));
const HomeEquityExpressContainer                  = React.lazy(() => import('app/containers/HomeEquityTurboContainer'));
const HomeEquityExpressSummaryContainer           = React.lazy(() => import('app/containers/HomeEquityTurboSummaryContainer'));
const HomeEquityExpressFinalSummaryContainer      = React.lazy(() => import('app/containers/HomeEquityTurboFinalSummaryContainer'));
const InsuranceQuoteContainer                     = React.lazy(() => import('app/containers/InsuranceQuoteContainer'));
const RefinanceTurboContainer                     = React.lazy(() => import('app/containers/RefinanceTurboContainer'));
const RefinanceTurboSummaryContainer              = React.lazy(() => import('app/containers/RefinanceTurboSummaryContainer'));
const RefinanceTurboOptimalProgramsContainer      = React.lazy(() => import('app/containers/RefinanceTurboOptimalProgramsContainer'));
const RefinanceTurboFinalSummaryContainer         = React.lazy(() => import('app/containers/RefinanceTurboFinalSummaryContainer'));
const PurchaseTurboContainer                      = React.lazy(() => import('app/containers/PurchaseTurboContainer'));
const PurchaseTurboSummaryContainer               = React.lazy(() => import('app/containers/PurchaseTurboSummaryContainer'));
const PurchaseTurboFinalSummaryContainer          = React.lazy(() => import('app/containers/PurchaseTurboFinalSummaryContainer'));
const AgriculturalContainer                       = React.lazy(() => import('app/containers/AgriculturalContainer'));
const AgriculturalLineOfCreditContainer           = React.lazy(() => import('app/containers/AgriculturalLineOfCreditContainer'));
const AgriculturalPurchaseEquipmentContainer      = React.lazy(() => import('app/containers/AgriculturalPurchaseEquipmentContainer'));
const AgriculturalPurchaseAcreageContainer        = React.lazy(() => import('app/containers/AgriculturalPurchaseAcreageContainer'));
const AgriculturalRefinancePropertyContainer      = React.lazy(() => import('app/containers/AgriculturalRefinancePropertyContainer'));

export const App = (() => (
  <ThemeWrapper>
    <React.Suspense fallback={<Loader />}>
      <CssBaseline />
      <HeaderContainer />
      <PageTitleContainer />
      <ModalAddressValidation />
      <ModalZipCodeValidation />
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <NotificationProvider>
          <NotificationComponent />
          <RouteCheckComponent />
          <Route path={routes.resume} component={ResumeSessionContainer} />
          <Route exact path={routes.autoPrequalEdit} component={LFPrequalReturningUserContainer} />
          <Route path={routes.purchase} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.purchase,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else if (sectionId === SUMMARY_SECTION) {
              return <PurchaseSummaryContainer />;
            } else {
              return <SFPurchaseContainer />;
            }
          }}
          />
          <Route path={routes.refinance} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.refinance,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else if (sectionId === SUMMARY_SECTION) {
              return <RefinanceSummaryContainer />;
            } else {
              return <SFRefinanceContainer />;
            }
          }} />
          <Route path={routes.personalLoan} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.personalLoan}#${PERSONAL_LOAN_GET_FICO_AND_ZIP_SECTION}`} />;
            }
            if (sectionId === SUMMARY_SECTION) {
              return <PersonalLoanSummaryContainer />;
            }
            return <PersonalLoanContainer />;
          }} />
          <Route path={routes.overdraftProtection} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.overdraftProtection}#${OVERDRAFT_PROTECTION_GET_FICO_AND_ZIP_SECTION}`} />;
            }
            if (sectionId === SUMMARY_SECTION) {
              return <OverdraftProtectionSummaryContainer />;
            }
            return <OverdraftProtectionContainer />;
          }} />
          <Route exact path={routes.application} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.application,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              return <LFContainer />;
            }
          }} />
          <Route exact path={routes.closingCostsPurchase} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.closingCostsPurchase}#101`} />;
            } else if (sectionId === CLOSING_COSTS_RATE_SELECTION) {
              return <ClosingCostsRateSelectionContainer />;
            } else {
              return <ClosingCostsContainer />;
            }
          }} />
          <Route exact path={routes.closingCostsRefinance} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.closingCostsRefinance}#101`} />;
            } else if (sectionId === CLOSING_COSTS_RATE_SELECTION) {
              return <ClosingCostsRateSelectionContainer />;
            } else {
              return <ClosingCostsContainer />;
            }
          }} />
          <Route exact path={routes.insuranceQuote} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.insuranceQuote}#101`} />;
            } else {
              return <InsuranceQuoteContainer />;
            }
          }} />
          <Route exact path={routes.homeEquityTurbo} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.homeEquityTurbo,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              if (sectionId === FINAL_SUMMARY_SECTION) {
                return <HomeEquityExpressFinalSummaryContainer />;
              }
              if (sectionId === SUMMARY_SECTION) {
                return <HomeEquityExpressSummaryContainer />;
              }
              return <HomeEquityExpressContainer />;
            }
          }} />
          <Route path={routes.applicationPurchase} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.applicationPurchase,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              return <LFPurchaseContainer />;
            }
          }} />
          <Route exact path={routes.agricultural} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.agricultural}#101`} />;
            } else {
              return <AgriculturalContainer />;
            }
          }} />
          <Route exact path={routes.agLineOfCredit} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.agLineOfCredit}#101`} />;
            } else {
              return <AgriculturalLineOfCreditContainer />;
            }
          }} />
          <Route exact path={routes.agPurchaseEquipment} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.agPurchaseEquipment}#101`} />;
            } else {
              return <AgriculturalPurchaseEquipmentContainer />;
            }
          }} />
          <Route exact path={routes.agPurchaseAcreage} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.agPurchaseAcreage}#101`} />;
            } else {
              return <AgriculturalPurchaseAcreageContainer />;
            }
          }} />
          <Route exact path={routes.agRefinanceProperty} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.agRefinanceProperty}#101`} />;
            } else {
              return <AgriculturalRefinancePropertyContainer />;
            }
          }} />
          <Route exact path={routes.commercial} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercial}#101`} />;
            } else {
              return <CommercialContainer />;
            }
          }} />
          <Route exact path={routes.commercialBusinessLoan} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialBusinessLoan}#101`} />;
            } else {
              return <CommercialBusinessLoanContainer />;
            }
          }} />
          <Route path={routes.commercialBridgeLoan} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialBridgeLoan}#101`} />;
            } else {
              return <CommercialBridgeLoanContainer />;
            }
          }} />
          <Route path={routes.commercialEquipment} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialEquipment}#101`} />;
            } else {
              return <CommercialEquipmentContainer />;
            }
          }} />
          <Route path={routes.commercialPurchase} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialPurchase}#101`} />;
            } else {
              return <CommercialPurchaseContainer />;
            }
          }} />
          <Route path={routes.commercialRefinance} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialRefinance}#101`} />;
            } else {
              return <CommercialRefinanceContainer />;
            }
          }} />
          <Route path={routes.commercialRenew} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialRenew}#101`} />;
            } else {
              return <CommercialRenewContainer />;
            }
          }} />
          <Route path={routes.commercialVehicle} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.commercialVehicle}#101`} />;
            } else {
              return <CommercialVehicleContainer />;
            }
          }} />
          <Route path={routes.applicationRefinance} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.applicationRefinance,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              return <LFRefinanceContainer />;
            }
          }} />
          <Route exact path={routes.autoPrequal} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.autoPrequal,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              return <LFAutoPrequalContainer />;
            }
          }} />
          <Route exact path={routes.autoPrequalRenew} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.autoPrequalRenew}#101`} />;
            } else {
              return <LFAutoPrequalRenewContainer />;
            }
          }} />
          <Route path={routes.homeEquity} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.homeEquity,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else if (sectionId === SUMMARY_SECTION) {
              return <HomeEquitySummaryContainer />;
            } else {
              return <HomeEquityContainer />;
            }
          }} />
          <Route path={routes.homeEquityRates} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.homeEquityRates,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else if (sectionId === SUMMARY_SECTION) {
              return <HomeEquityRatesSummaryContainer />;
            } else {
              return <HomeEquityRatesContainer />;
            }
          }} />
          <Route path={routes.applicationHomeEquity} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={{
                pathname: routes.applicationHomeEquity,
                search: route.location.search,
                hash: '#101',
              }} />;
            } else {
              return <LFHomeEquityContainer />;
            }
          }} />
          <Route path={routes.autoLoan} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.autoLoan}#101`} />;
            }
            if (sectionId === SUMMARY_SECTION) {
              return <AutoLoanSummaryContainer />;
            }
            return <AutoLoanContainer />;
          }} />
          <Route path={routes.otherVehicle} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.otherVehicle}#101`} />;
            }
            return <OtherVehicleContainer />;
          }} />
          <Route path={routes.creditBooster} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.creditBooster}#${CREDIT_BOOSTER_APP_GENERAL_INFO}`} />;
            }
            return <CreditBoosterContainer />;

          }} />
          <Route path={routes.creditCard} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.creditCard}#${CREDIT_CARD_APP_DOB}`} />;
            }
            return <CreditCardContainer />;

          }} />
          <Route path={routes.applicationConstruction} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.applicationConstruction}#${CONSTRUCTION_GET_PERSONAL_INFORMATION}`} />;
            } else {
              return <ConstructionContainer />;
            }
          }} />
          <Route path={routes.reverse} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={ {
                pathname: routes.reverse,
                search: route.location.search,
                hash: `#${ REVERSE_LOAN_GOAL }`,
              } }/>;
            }
            if (sectionId === SUMMARY_SECTION) {
              return <ReverseSummaryContainer />;
            }
            return <ReverseContainer />;
          }} />
          <Route path={routes.financialStatement} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.financialStatement}#${FINANCIAL_STATEMENT_APP_PERSONAL_INFO}`} />;
            }
            return <FinancialStatementContainer />;

          }} />
          <Route path={routes.sbLineOfCredit} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.sbLineOfCredit}#${SB_LINE_OF_CREDIT_LOAN_INFO}`} />;
            }
            return <SBLineOfCreditContainer />;

          }} />
          <Route path={routes.sbTerm} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.sbTerm}#${SB_TERM_LOAN_INFO}`} />;
            }
            return <SBTermContainer />;

          }} />
          <Route path={routes.applicationLand} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.applicationLand}#${LAND_GET_PERSONAL_INFORMATION}`} />;
            } else {
              return <LFLandContainer />;
            }
          }} />
          <Route exact path={routes.refinanceTurbo} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.refinanceTurbo}#101`} />;
            }
            if (sectionId === REFINANCE_TURBO_SUMMARY) {
              return <RefinanceTurboSummaryContainer />;
            }
            if (sectionId === REFINANCE_TURBO_OPTIMAL_SUMMARY) {
              return <RefinanceTurboOptimalProgramsContainer />;
            }
            if (sectionId === REFINANCE_TURBO_FINAL_SUMMARY) {
              return <RefinanceTurboFinalSummaryContainer />;
            }
            return <RefinanceTurboContainer />;
          }} />
          <Route exact path={routes.purchaseTurbo} render={(route) => {
            const sectionId = route.location.hash.replace('#', '');
            if (!sectionId) {
              return <Redirect to={`${routes.purchaseTurbo}#101`} />;
            }
            if (sectionId === PURCHASE_TURBO_SUMMARY) {
              return <PurchaseTurboSummaryContainer />;
            }
            if (sectionId === PURCHASE_TURBO_SUMMARY_AFTER_CREDIT) {
              return <PurchaseTurboFinalSummaryContainer />;
            }
            return <PurchaseTurboContainer />;
          }} />
          <Route path={routes.contact} render={(route) => {
            if (!route.location.pathname.endsWith('/')) {
              // redirect to force an exact path
              return <Redirect to={routes.contact} />;
            }
            return <ContactContainer />;
          }} />
          <Route exact path={routes.employeeReferral} component={EmployeeReferral} />
          <Route exact path={routes.root} render={() => {
            if(isOnLoanOfficerSite()) {
              return <BrokerContainer />;
            }
            return <SplashContainer />;
          }} />
          <Route path={routes.branch} component={BranchContainer} />
          <Route path={routes.scheduleAppointment} component={ScheduleContainer} />
          <Route exact path={routes.broker} component={BrokerContainer} />
          <DevToolWrapper />
          <FooterContainer />
        </NotificationProvider>
      </MuiPickersUtilsProvider>
    </React.Suspense>
  </ThemeWrapper>
));
