import { LiabilityAccount } from 'app/api/credit-report';
import { FieldNames } from 'app/models/fields/names';
import {
  CASH_OUT_MIN,
  TransferToFormCode,
  cashOutAvailableAtClosing,
  isPrimaryHome,
  isTakingCashOut,
  isHomeowner,
} from 'app/models/fields/conditionals';
import { change, getFormValues, initialize } from 'redux-form';
import { FinancialVerificationIntegration, LiabilityUsage, YesOrNo } from 'app/models/options/enums';
import {
  ajaxFetchHomeEquityRates,
  ajaxpostTurboFail,
  ajaxpostTurboSuccess,
  handleDeclarationFlagsWhitelist,
  getNextSection,
  goToSection,
  setSubmittedData,
  referToLoanOfficer,
  ajaxPostShortForm,
  ajaxGetHomeEquityPrefillSuccess,
  ajaxGetHomeEquityPrefillFail,
  ajaxPostRefinanceTurboSuccess,
  ajaxPostRefinanceTurboFail,
  ajaxFetchRates,
  ajaxPostPurchaseTurboFail,
  ajaxPostPurchaseTurboSuccess,
} from 'app/actions/form/actions';
import {
  FINAL_SUMMARY_SECTION,
  HOME_EQUITY_MINIMUM_LOAN_STOP_GATE,
  HOME_EQUITY_TURBO_AUTOMATIC_VOIE,
  SUMMARY_SECTION,
  HOME_EQUITY_TURBO_CITIZENSHIP,
  HOME_EQUITY_TURBO_EMPLOYMENT_TYPE,
  HOME_EQUITY_TURBO_PERSONAL_INFO,
  REFINANCE_TURBO_ANNUAL_COSTS,
  REFINANCE_TURBO_NO_OPTIMAL_PROGRAMS,
  REFINANCE_TURBO_OPTIMAL_SUMMARY,
  REFINANCE_TURBO_SUMMARY,
  REFINANCE_TURBO_FINAL_SUMMARY,
  REFINANCE_TURBO_AUTOMATIC_VOIE,
  HOME_EQUITY_RATES_NO_RATES_FOUND,
  PURCHASE_TURBO_ANNUAL_COSTS,
  PURCHASE_TURBO_SUMMARY,
  PURCHASE_TURBO_SUMMARY_AFTER_CREDIT,
  PURCHASE_TURBO_ADDITIONAL_PROPERTIES,
  PURCHASE_TURBO_AVAILABLE_FUNDS,
  HOME_EQUITY_TURBO_VERIFICATION_CONNECT,
  HOME_EQUITY_TURBO_COLLECT_SSN,
  REFINANCE_TURBO_COLLECT_SSN,
  REFINANCE_TURBO_EMPLOYMENT_TYPE,
  REFINANCE_TURBO_VERIFICATION_CONNECT,
  PURCHASE_TURBO_COLLECT_SSN,
  PURCHASE_TURBO_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_VERIFICATION_CONNECT,
} from '../../../models/sections/constants';
import { Type, Type as FormTypes } from 'app/actions/form/types';
import { RootState } from '../../../store/types';
import { getLoanOfficer } from '../../../reducers/loan-officer';
import { logger } from '../../../util/logger';
import { getVerificationBorrowerCustomerId, getVerificationCoBorrowerCustomerId, getVerificationOrder } from 'app/reducers/verification';
import { getHomeEquityTurboPrefill, mapTurboFormRequest, postTurbo } from 'app/api/loan-application/turbo-form';
import { fetchHomeEquityAcceptedLocation, fetchHomeEquityLoanAmountLimits, mapHomeEquityLoanAmountLimitsRequest } from 'app/api/home-equity';
import { getFormName } from 'app/routes/helpers';
import { HomeEquityProgram, HomeEquityRatesResponse } from 'app/api/home-equity/types';
import { FormName, GeneralReferralCode, HomeEquityLoanPurpose, Product, PropertyType } from '@lenderful/domain';
import { FicoRange, productsSelector } from 'app/reducers/app-config';
import { selectAVMReportId } from 'app/reducers/report';
import { hasEitherSsn } from '../../../components/FormFields/Ssn/CollectSsnBase';
import { getAuditMetadata } from '../../../util/actions';
import { mapFromUniversalLoanApplication } from 'app/api/loan-application/universal/mappings';
import { homeEquityTurboInitialValues } from 'app/util/initial-values';
import { push } from 'connected-react-router';
import { routes } from 'app/routes/route-list';
import { filterOptimalPrograms } from 'app/reducers/turbo-selectors';
import { RateInfo } from 'app/models/types';
import { redirectToNewFrom } from 'app/reducers/router';
import { ratesSelector } from '../../../reducers/rates';

/**
 * This sets the values for the given Home Equity program into the form.
 *
 * @param {HomeEquityProgram} program
 * @returns
 */
export function setSelectedHomeEquityTurboRate(program: HomeEquityProgram) {
  return (dispatch, getState) => {
    dispatch({ type: Type.SET_SELECTED_RATE, program });
    const formName = getFormName(getState().router.location.pathname);
    const { apr, rate, termInYears, productFamily, productType, principalAndInterestPayment } = program;

    /* Set rate values to home equity turbo form */
    dispatch(change(formName, FieldNames.rate, rate));
    dispatch(change(formName, FieldNames.apr, apr));
    dispatch(change(formName, FieldNames.loanTerm, termInYears));
    dispatch(change(formName, FieldNames.loanType, productFamily));
    dispatch(change(formName, FieldNames.productType, productType));
    dispatch(change(formName, FieldNames.monthlyPayment, principalAndInterestPayment));

    /* Set entire rate row to the selectedRate field */
    dispatch(change(formName, FieldNames.selectedRate, program));
  };
}

/**
 * This method handles getting the rates on the home equity rates form.  This form does not have property
 * type or loan purpose, so it defaults those values in the request.  Upon completion it will either
 * refer the user (if no rates) or navigate the user to the home equity turbo form with the fields already
 * answered as prefilled values.
 *
 */
export function handleHomeEquityGetRates(formData) {
  return async (dispatch) => {

    const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: formData[FieldNames.propertyZip] });
    if (locationResponse && !locationResponse.isValid) {
      return dispatch(goToSection(HOME_EQUITY_RATES_NO_RATES_FOUND));
    }

    const payload = {
      ...formData,
      [FieldNames.propertyType]: PropertyType.SINGLE_FAMILY,
      [FieldNames.homeEquityLoanPurpose]: HomeEquityLoanPurpose.CONSOLIDATION,
    };

    const response: HomeEquityRatesResponse = await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(payload)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_RATES, payload));

    // Check if the rates returned
    if (response.fixedRatePrograms.length > 0 || response.lineOfCredit) {
      return dispatch(goToSection(SUMMARY_SECTION));
    }
    logger.warn('handleHomeEquityGetRates: No qualified rates returned', response);
    return dispatch(goToSection(HOME_EQUITY_RATES_NO_RATES_FOUND));
  };
}

/**
 * This method navigates from the home equity rates form to the home equity turbo form.
 *
 * @returns
 */
export function handleGoToTurbo() {
  return redirectToNewFrom({
    answeredQuestions: [], // TODO: do we need something here?
    code: TransferToFormCode.HOME_EQUITY_RATES_TO_TURBO,
    condition: () => true,
    fromForm: FormName.HOME_EQUITY_RATES,
    sectionId: '101',
    toForm: FormName.HOME_EQUITY_TURBO,
  });
}

/**
 * This method handles getting the rates for the home equity turbo
 * form.  It is responsible for setting the submitted data (Which is used
 * to hold the initial values of the form - important for resetting it).
 *
 * This method also determines whether you get the summary or a referral
 * based on whether there are rates returned or not.
 *
 * @param payload
 * @returns
 */
export function handleTurboHomeEquityGetRates(payload) {
  return async (dispatch) => {
    dispatch({ type: Type.HANDLE_HOMEEQUITY_SUBMIT_SECOND, payload });

    const locationResponse = await fetchHomeEquityAcceptedLocation({ propertyZip: payload[FieldNames.propertyZip] });
    if (locationResponse && !locationResponse.isValid) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.STATE_NOT_SUPPORTED));
    }

    const response: HomeEquityRatesResponse = await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(payload)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_TURBO, payload));

    // Check if the rates returned
    if (response.fixedRatePrograms.length > 0 || response.lineOfCredit) {
      dispatch(ajaxPostShortForm());
      return dispatch(goToSection(SUMMARY_SECTION)); // If true, go to the rate summary
    }
    logger.warn('handleTurboHomeEquityGetRates: No qualified rates returned', response);
    return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
  };
}

/**
 * This method handles making the final call to get rates for the Home Equity
 * Turbo form.  This call will have the credit report id to get the real FICO
 * instead of the self-described one.  It also performs some calculations to
 * determine if the user is over the LTV ratio and either caps them to a
 * maximum loan amount or refers them if the loan is too small.
 *
 * @param payload
 * @returns
 */
export function handleTurboHomeEquityGetFinalRates(formData) {
  return async (dispatch) => {

    dispatch({ type: FormTypes.HANDLE_HOMEEQUITY_SUBMIT_SECOND, formData });

    const realEstateLiabilities: LiabilityAccount[] = formData[FieldNames.realEstateLiabilities] || {};

    let collateralHome;
    if (isPrimaryHome(formData)) {
      collateralHome = realEstateLiabilities.find(liability => liability.liabilityUsage === LiabilityUsage.PRIMARY_HOME);
    } else {
      collateralHome = realEstateLiabilities.find(liability => liability.liabilityUsage === LiabilityUsage.COLLATERAL_HOME);
    }

    const liabilityBalance = collateralHome ? collateralHome.liabilityBalance : 0;
    const totalDebt = liabilityBalance + formData[FieldNames.loanAmount];

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(formData);
    const { maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    if ((totalDebt / formData[FieldNames.homeValue]) > (maxLTV / 100)) {
      // calculate new loan amount which represents the maximum possible with given LTV limit
      const newLoanAmount = (formData[FieldNames.homeValue] * (maxLTV / 100)) - liabilityBalance;

      // update both loan amount and maximum loan amount on form and payload
      dispatch(change(FormName.HOME_EQUITY_TURBO, FieldNames.loanAmount, newLoanAmount));
      dispatch(change(FormName.HOME_EQUITY_TURBO, FieldNames.maxLoanAmount, newLoanAmount));
      dispatch(change(FormName.HOME_EQUITY_TURBO, FieldNames.hasWarningForLoanAmountReduction, 'Yes'));

      formData[FieldNames.loanAmount] = newLoanAmount;
      formData[FieldNames.maxLoanAmount] = newLoanAmount;
      formData[FieldNames.hasWarningForLoanAmountReduction] = 'Yes';
    }

    if (formData[FieldNames.loanAmount] < minLoanAmount) {
      return dispatch(referToLoanOfficer(GeneralReferralCode.HOME_EQUITY_BORROW_AMOUNT_LOW));
    }

    const response: HomeEquityRatesResponse = await Promise.resolve(dispatch(ajaxFetchHomeEquityRates(formData)));
    dispatch(setSubmittedData(FormName.HOME_EQUITY_TURBO, formData));

    if (response.fixedRatePrograms.length > 0 || response.lineOfCredit) {
      const rates = [...response.fixedRatePrograms];
      if (response.lineOfCredit?.program) {
        rates.push(response.lineOfCredit.program);
      }
      const newSelectedRate = rates.find(rate => rate.loanType === formData[FieldNames.selectedRate].loanType);
      if (newSelectedRate.apr !== formData[FieldNames.selectedRate].apr) {
        dispatch(change(FormName.HOME_EQUITY_TURBO, FieldNames.hasWarningForRateChange, 'Yes'));
        formData[FieldNames.hasWarningForRateChange] = 'Yes';
      }
      return dispatch(goToSection(FINAL_SUMMARY_SECTION));
    }
    logger.warn('handleTurboHomeEquityGetFinalRates: No qualified rates returned', response);
    return dispatch(referToLoanOfficer(GeneralReferralCode.NO_QUALIFIED_PREQUAL_RATES));
  };
}

export function handleValidateLoanLimits(formData) {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);

    const loanAmountLimitsRequest = mapHomeEquityLoanAmountLimitsRequest(formData);
    const { maxLoanAmount, maxLTV, minLoanAmount } = await fetchHomeEquityLoanAmountLimits(loanAmountLimitsRequest);

    // Set the max loan amount in payload because we set submitted data below
    formData[FieldNames.maxLoanAmount] = maxLoanAmount;
    formData[FieldNames.minLoanAmount] = minLoanAmount;
    formData[FieldNames.maxLtvPercent] = maxLTV;
    dispatch(change(formName, FieldNames.maxLoanAmount, maxLoanAmount));
    dispatch(change(formName, FieldNames.minLoanAmount, minLoanAmount));
    dispatch(change(formName, FieldNames.maxLtvPercent, maxLTV));

    if (maxLoanAmount < minLoanAmount) {
      return dispatch(goToSection(HOME_EQUITY_MINIMUM_LOAN_STOP_GATE));
    }
    return dispatch(getNextSection());
  };
}

export function handleHomeEquityTurboFormSubmit(formData) {
  return (dispatch, getState) => {
    const state: RootState     = getState();
    const loanOfficer          = getLoanOfficer(state);
    const borrowerCustomerId   = getVerificationBorrowerCustomerId(state);
    const coBorrowerCustomerId = getVerificationCoBorrowerCustomerId(state);
    const order = getVerificationOrder(state);
    const avmReportId = selectAVMReportId(state);
    const formName = getFormName(getState().router.location.pathname);
    let request = mapTurboFormRequest(formData, formName, loanOfficer, borrowerCustomerId, coBorrowerCustomerId, order?.id, avmReportId);
    dispatch(handleDeclarationFlagsWhitelist(request, FormName.HOME_EQUITY_TURBO));
    dispatch({ type: Type.HANDLE_TURBO_SUBMIT, request });
    dispatch({ type: Type.AJAX_POST_HOME_EQUITY_TURBO, request });

    if (hasEitherSsn()) {
      const auditMetadata = getAuditMetadata('Submit button');
      request = { ...request,
        element: auditMetadata.element,
        page: auditMetadata.page,
        timestamp: auditMetadata.timestamp,
      };
    }

    return postTurbo(request).then(
      (response) => {
        dispatch(ajaxpostTurboSuccess(response));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxpostTurboFail(error));
        logger.error('Posting Turbo form error', error);
      },
    );
  };
}

/**
 * This method navigates to the next section when the user clicks the
 * "Apply Now" button to select a specific rate/program.
 *
 * @returns
 */
export function handleTurboHomeEquityRateSelect() {
  return (dispatch) => {
    dispatch(goToSection(HOME_EQUITY_TURBO_CITIZENSHIP));
  };
}

/**
 * This method navigates to either the Verification of Income and Employee workflow (Finicity) or
 * the pages for manual entry of employement information.
 *
 * @returns
 */
export function handleGoToEmployment() {
  return (dispatch, getState) => {
    const state = getState();
    const hasVerificationOfIncomeAndEmployment = productsSelector(state).some(product => product.name === Product.VOIE);

    if (hasVerificationOfIncomeAndEmployment) {
      return dispatch(goToSection(HOME_EQUITY_TURBO_AUTOMATIC_VOIE));
    }
    return dispatch(goToSection(HOME_EQUITY_TURBO_EMPLOYMENT_TYPE));
  };
}

/**
 * Determine which section to go based on the client's financial verification system used.
 *
 * @returns
 */
export function handleGoToFinancialVerificationPreStep(formData) {
  return (dispatch, getState) => {
    const { canVerifyOfIncomeEmployment, financialVerificationIntegration } = formData;
    const formName = getFormName(getState().router.location.pathname);
    const canVerify = canVerifyOfIncomeEmployment === YesOrNo.YES;
    const hasFinicity = financialVerificationIntegration === FinancialVerificationIntegration.FINICITY;
    const hasTruv = financialVerificationIntegration === FinancialVerificationIntegration.TRUV;

    // Home Equity Turbo form
    if (formName === FormName.HOME_EQUITY_TURBO) {
      if (canVerify && hasTruv) {
        return dispatch(goToSection(HOME_EQUITY_TURBO_VERIFICATION_CONNECT));
      } else if (canVerify && hasFinicity) {
        return dispatch(goToSection(HOME_EQUITY_TURBO_COLLECT_SSN));
      } else {
        return dispatch(goToSection(HOME_EQUITY_TURBO_EMPLOYMENT_TYPE));
      }
    }

    // Refinance Turbo form
    if (formName === FormName.REFINANCE_TURBO) {
      if (canVerify && hasTruv) {
        return dispatch(goToSection(REFINANCE_TURBO_VERIFICATION_CONNECT));
      } else if (canVerify && hasFinicity) {
        return dispatch(goToSection(REFINANCE_TURBO_COLLECT_SSN));
      } else {
        return dispatch(goToSection(REFINANCE_TURBO_EMPLOYMENT_TYPE));
      }
    }

    // Purchase Turbo form
    if (formName === FormName.PURCHASE_TURBO) {
      if (canVerify && hasTruv) {
        return dispatch(goToSection(PURCHASE_TURBO_VERIFICATION_CONNECT));
      } else if (canVerify && hasFinicity) {
        return dispatch(goToSection(PURCHASE_TURBO_COLLECT_SSN));
      } else {
        return dispatch(goToSection(PURCHASE_TURBO_EMPLOYMENT_TYPE));
      }
    }
  };
}

/**
 * This action retrieves the data from a prefilled home equity turbo application,
 * populates the form with this data, and navigates to the first page of
 * the application for the user to complete it.
 *
 * @param {string} hash
 * @param {FicoRange[]} ficoRanges
 * @returns
 */
export async function ajaxGetHomeEquityTurboPrefill(hash: string, ficoRanges: FicoRange[]) {
  return (dispatch) => {
    dispatch({ type: Type.AJAX_GET_HOME_EQUITY_PREFILL });
    return getHomeEquityTurboPrefill(hash).then(
      (data) => {
        logger.info('ajaxGetHomeEquityTurboPrefill: data', data);
        dispatch(ajaxGetHomeEquityPrefillSuccess(data));
        const prefilledForm = mapFromUniversalLoanApplication(data.application, ficoRanges, data.safeMonthlyPayment);
        const formValues = { ...homeEquityTurboInitialValues, ...prefilledForm };
        dispatch(initialize(FormName.HOME_EQUITY_TURBO, formValues, false));
        dispatch(push(`${routes.homeEquityTurbo}#${HOME_EQUITY_TURBO_PERSONAL_INFO}`));
      },
      (error) => {
        logger.warn('ajaxGetHomeEquityTurboPrefill: error', error);
        dispatch(ajaxGetHomeEquityPrefillFail(error));
      },
    );
  };
}

/**
 * This method handles getting rates for our Refinance Turbo product.  Afterwards, it will
 * either navigate the user to the summary page or a referral based on whether rates and programs
 * are available.
 *
 * @param formData
 * @returns
 */
export function handleRefinanceTurboGetRates(formData) {
  return async (dispatch) => {
    const {
      loanAmount,
      loanPurpose,
      cashOut,
      currentLoanTerm,
      currentLoanType,
      currentMonthlyPayment,
    } = formData;

    const adjustedPayload = {
      ...formData,
      loanAmount: isTakingCashOut(formData) ? loanAmount + cashOut : loanAmount,
    };

    return Promise.resolve(dispatch(ajaxFetchRates(adjustedPayload)))
      .then((programs: RateInfo[]) => {
        if (!programs || programs.length === 0) {
          return dispatch(referToLoanOfficer(GeneralReferralCode.NO_RATES_EXPRESS));
        }

        const optimalPrograms = filterOptimalPrograms(programs, loanPurpose, currentMonthlyPayment, currentLoanType, currentLoanTerm);

        dispatch(ajaxPostShortForm());
        if (optimalPrograms.length === 0) {
          return dispatch(goToSection(REFINANCE_TURBO_NO_OPTIMAL_PROGRAMS));
        }

        return dispatch(push(`${routes.refinanceTurbo}#${REFINANCE_TURBO_OPTIMAL_SUMMARY}`));
      });
  };
}

/**
 * This method navigates the user to the right page after the refinance turbo
 * summary page.
 *
 * @returns
 */
export function handleRefinanceTurboRateSelect() {
  return (dispatch) => {
    dispatch(push(`${routes.refinanceTurbo}#${REFINANCE_TURBO_ANNUAL_COSTS}`));
  };
}

/**
 * This method navigates the user to the right page after the refinance turbo
 * final summary page.
 *
 * @returns
 */
export function handleRefinanceTurboFinalRateSelect() {
  return (dispatch) => {
    dispatch(goToSection(REFINANCE_TURBO_AUTOMATIC_VOIE));
  };
}

/**
 * This method navigates the user to the standard refinance turbo summary page
 * that lists all programs regardless of whether they are optimal or not.
 *
 * @returns
 */
export function handleRefinanceTurboSummary() {
  return (dispatch) => {
    dispatch(push(`${routes.refinanceTurbo}#${REFINANCE_TURBO_SUMMARY}`));
  };
}

/**
 *  This function gets the final rates and redirects them accordingly.
 * @param formData
 */

export function fetchAndProcessRefinanceTurboRates(formData) {
  return async (dispatch) => {
    const { loanAmount, cashOut } = formData;
    const adjustedPayload = {
      ...formData,
      loanAmount: isTakingCashOut(formData) ? loanAmount + cashOut : loanAmount,
    };

    const rates = await Promise.resolve(dispatch(ajaxFetchRates(adjustedPayload)));
    dispatch(setSubmittedData(FormName.REFINANCE_TURBO, formData));
    if (!rates || rates.length === 0) {
      logger.warn('fetchAndProcessRefinanceTurboRates: No qualified rates returned', rates);
      return dispatch(referToLoanOfficer(GeneralReferralCode.NO_RATES_EXPRESS));
    }
    return dispatch(push(`${routes.refinanceTurbo}#${REFINANCE_TURBO_FINAL_SUMMARY}`));
  };
}

/**
 * This function performs some calculations to determine if the user is
 * over the LTV ratio and either caps them to a maximum loan amount or refers
 * them if the loan is too small. It also updates their cash out potential or gets
 * final rates.
 * @param formData
 */

export function handleRefinanceTurboFinalAdjustments(formData) {
  return async (dispatch) => {
    try {
      dispatch({ type: FormTypes.HANDLE_REFINANCE_TURBO_ADJUSTMENT, formData });

      const realEstateLiabilities: LiabilityAccount[] = formData[FieldNames.realEstateLiabilities] || {};
      let refinanceHomeLiabilities: LiabilityAccount[];
      if (isPrimaryHome(formData)) {
        refinanceHomeLiabilities = realEstateLiabilities.filter(liability => liability.liabilityUsage === LiabilityUsage.PRIMARY_HOME);
      } else {
        refinanceHomeLiabilities = realEstateLiabilities.filter(liability => liability.liabilityUsage === LiabilityUsage.COLLATERAL_HOME);
      }

      if (refinanceHomeLiabilities.length > 0) {
        let liabilityBalance = 0;
        refinanceHomeLiabilities.forEach(account => liabilityBalance += account.liabilityBalance );

        // if liability amount is higher than expected, show warning
        if (formData[FieldNames.loanAmount] < liabilityBalance) {
          dispatch(change(FormName.REFINANCE_TURBO, FieldNames.hasWarningForLoanAmountReduction, 'Yes'));
          formData[FieldNames.hasWarningForLoanAmountReduction] = 'Yes';
        }

        // replace refinanced loan amount with refinanced home's liability amount
        dispatch(change(FormName.REFINANCE_TURBO, FieldNames.loanAmount, liabilityBalance));
        formData[FieldNames.loanAmount] = liabilityBalance;
        dispatch(change(FormName.REFINANCE_TURBO, FieldNames.maxLoanAmount, liabilityBalance));
        formData[FieldNames.maxLoanAmount] = liabilityBalance;

        if (isTakingCashOut(formData)) {
          // if cash out available is now less than original cash out
          if (cashOutAvailableAtClosing(formData) < formData[FieldNames.cashOut]) {
            dispatch(change(FormName.REFINANCE_TURBO, FieldNames.cashOut, cashOutAvailableAtClosing(formData)));
            formData[FieldNames.cashOut] = cashOutAvailableAtClosing(formData);

            // if new cash out amount is now below threshold then refer
            if (cashOutAvailableAtClosing(formData) < CASH_OUT_MIN) {
              dispatch(referToLoanOfficer(GeneralReferralCode.CASH_OUT_NOT_AVAILABLE));
            }
          }
          return dispatch(getNextSection());
        }
      }
      // if no refinance home liabilities, get final rates
      await fetchAndProcessRefinanceTurboRates(formData)(dispatch);
    }
    catch (error) {
      logger.error('handleRefinanceTurboFinalAdjustments', error);
    }
  };
}

/**
 * This method handles making the final call to get rates for the Refinance
 * Turbo form.
 *
 * @param payload
 * @returns
 */
export function handleRefinanceTurboGetFinalRates(formData) {
  return async (dispatch) => {
    try {

      dispatch({ type: FormTypes.HANDLE_REFINANCE_TURBO_SUBMIT_SECOND, formData });

      await fetchAndProcessRefinanceTurboRates(formData)(dispatch);
    }
    catch (error) {
      logger.error('handleTurboHomeEquityGetFinalRates', error);
    }
  };
}

/**
 * *
 * @param formData
 */
export function handleRefinanceTurboFormSubmit(formData) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const loanOfficer = getLoanOfficer(state);
    const borrowerCustomerId = getVerificationBorrowerCustomerId(state);
    const coBorrowerCustomerId = getVerificationCoBorrowerCustomerId(state);
    const order = getVerificationOrder(state);
    const avmReportId = selectAVMReportId(state);
    const formName = getFormName(getState().router.location.pathname);
    let request = mapTurboFormRequest(formData, formName, loanOfficer, borrowerCustomerId, coBorrowerCustomerId, order?.id, avmReportId);
    dispatch(handleDeclarationFlagsWhitelist(request, FormName.REFINANCE_TURBO));
    dispatch({ type: Type.HANDLE_TURBO_SUBMIT, request });
    dispatch({ type: Type.AJAX_POST_REFINANCE_TURBO, request });

    if (hasEitherSsn()) {
      const auditMetadata = getAuditMetadata('Submit button');
      request = { ...request,
        element: auditMetadata.element,
        page: auditMetadata.page,
        timestamp: auditMetadata.timestamp,
      };
    }

    return postTurbo(request).then(
      (response) => {
        dispatch(ajaxPostRefinanceTurboSuccess(response));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxPostRefinanceTurboFail(error));
        logger.error('Posting Turbo form error', error);
      },
    );
  };
}

/**
 * This function gets Purchase rates and redirects to the summary container
 * @param formData
 */
export function handlePurchaseTurboGetRates(formData) {
  return (dispatch) => {
    return Promise.resolve(
      dispatch(ajaxFetchRates(formData)),
    ).then(() => {
      dispatch(ajaxPostShortForm());
      dispatch(goToSection(PURCHASE_TURBO_SUMMARY));
    });
  };
}

/**
 * This function gets Purchase rates again based off of the credit report and redirects to the final summary container
 * @param formData
 */
export function handlePurchaseTurboGetFinalRates(formData) {
  return (dispatch, getState) => {
    const state = getState();
    const currentRates = ratesSelector(state);
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    return Promise.resolve(
      dispatch(ajaxFetchRates(formData)),
    ).then((response) => {
      const newRates = response;
      if (!newRates || newRates.length === 0) {
        return dispatch(referToLoanOfficer(GeneralReferralCode.NO_RATES_EXPRESS));
      }
      if(newRates[0]?.apr === currentRates[0]?.apr) {
        if (isHomeowner(formData)) {
          return dispatch(goToSection(PURCHASE_TURBO_ADDITIONAL_PROPERTIES));
        }
        return dispatch(goToSection(PURCHASE_TURBO_AVAILABLE_FUNDS));
      }
      return dispatch(goToSection(PURCHASE_TURBO_SUMMARY_AFTER_CREDIT));
    });
  };
}

/**
 * This method navigates the user to the right page after the purchase turbo
 * summary page.
 *
 * @returns
 */
export function handlePurchaseTurboRateSelect() {
  return (dispatch) => {
    dispatch(push(`${routes.purchaseTurbo}#${PURCHASE_TURBO_ANNUAL_COSTS}`));
  };
}

export function handlePurchaseTurboFinalRateSelect() {
  return (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    if(isHomeowner(formData)) {
      return dispatch(push(`${routes.purchaseTurbo}#${PURCHASE_TURBO_ADDITIONAL_PROPERTIES}`));
    }
    return dispatch(push(`${routes.purchaseTurbo}#${PURCHASE_TURBO_AVAILABLE_FUNDS}`));
  };
}

/**
 * *
 * @param formData
 */
export function handlePurchaseTurboFormSubmit(formData) {
  return (dispatch, getState) => {
    const state: RootState = getState();
    const loanOfficer = getLoanOfficer(state);
    const borrowerCustomerId = getVerificationBorrowerCustomerId(state);
    const coBorrowerCustomerId = getVerificationCoBorrowerCustomerId(state);
    const order = getVerificationOrder(state);
    const avmReportId = selectAVMReportId(state);
    const formName = getFormName(getState().router.location.pathname);
    let request = mapTurboFormRequest(formData, formName, loanOfficer, borrowerCustomerId, coBorrowerCustomerId, order?.id, avmReportId);
    dispatch(handleDeclarationFlagsWhitelist(request, FormName.PURCHASE_TURBO));
    dispatch({ type: Type.HANDLE_TURBO_SUBMIT, request });
    dispatch({ type: Type.AJAX_POST_PURCHASE_TURBO, request });

    if (hasEitherSsn()) {
      const auditMetadata = getAuditMetadata('Submit button');
      request = { ...request,
        element: auditMetadata.element,
        page: auditMetadata.page,
        timestamp: auditMetadata.timestamp,
      };
    }

    return postTurbo(request).then(
      (response) => {
        dispatch(ajaxPostPurchaseTurboSuccess(response));
        dispatch(getNextSection());
      },
      (error) => {
        dispatch(ajaxPostPurchaseTurboFail(error));
        logger.error('Posting Turbo form error', error);
      },
    );
  };
}
