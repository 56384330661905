import React, { Fragment, useState } from 'react';
import { useDebounce } from 'react-use';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { change, Field } from 'redux-form';
import { noopValidate, required } from 'app/util/validators';
import { Button, Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { connect } from 'react-redux';
import { ajaxGetVehiclesTypeahead } from 'app/actions/form/auto-valuation/actions';
import { RootState } from 'app/store/types';
import { getAutoValuationTypeaheadVehicles } from 'app/reducers/auto-valuation';
import { AutoTypeaheadProps } from 'app/components/AutoLoan/types';
import { FieldNames } from 'app/models/fields/names';
import { FormName } from '@lenderful/domain';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = DispatchProps & MappedProps

const AutoTypeaheadBaseComponent = (props) => {
  const { ajaxGetVehiclesTypeahead, onVehicleVariantClear, options }: Props = props;

  const MIN_CHARACTERS_FOR_SEARCH = 4;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeaheadValue, setTypeaheadValue] = useState<string>();

  useDebounce(() => {
    if (typeaheadValue && typeaheadValue.length >= MIN_CHARACTERS_FOR_SEARCH) {
      setLoading(true);
      ajaxGetVehiclesTypeahead(typeaheadValue)
        .then(() => { setLoading(false); });
    }
  }, 500, [typeaheadValue]);

  if (props.input.value) {
    return (
      <Grid container>
        <Grid item>
          <Typography style={{ paddingTop: '0.5em', paddingRight: '0.5em' }}>
            {props.input.value}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              props.input.onChange('');
              onVehicleVariantClear();
            }}
            startIcon={<DeleteIcon />}
          >
            Clear
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Autocomplete
      id={props.name}
      style={{ minWidth: 400 }}
      open={open}
      onOpen={() => { setOpen(true); }}
      onClose={() => { setOpen(false); }}
      onChange={(event, value) => props.input.onChange(value)}
      getOptionSelected={(option, value) => option === value}
      getOptionLabel={(option) => option}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          variant='outlined'
          InputProps={{
            ...params.InputProps,
            onChange: (event) => {
              setTypeaheadValue(event.currentTarget.value);
            },
            endAdornment: (
              <Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  ajaxGetVehiclesTypeahead : (value: string) => dispatch(ajaxGetVehiclesTypeahead(value)),
  onVehicleVariantClear : () => {
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleId, ''));
    dispatch(change(FormName.AUTOLOAN, FieldNames.vehicleYear, ''));
  },
});

const mapStateToProps = (state: RootState) => ({
  options: getAutoValuationTypeaheadVehicles(state),
});

const AutoTypeaheadComponent = connect(mapStateToProps, mapDispatchToProps)(AutoTypeaheadBaseComponent);

export const AutoTypeahead = ({ name, label, title, isOptional }: AutoTypeaheadProps) => {
  const isRequired =  isOptional ? noopValidate : required;
  return (
    <Field
      component = {AutoTypeaheadComponent}
      label     = {label}
      name      = {name}
      title     = {title}
      type      = 'text'
      validate  = {isRequired}
    />
  );
};
