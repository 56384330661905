import moment from 'moment';
import { push } from 'connected-react-router';
import { change, getFormValues } from 'redux-form';
import { routes } from 'app/routes/route-list';
import {
  REVERSE_ADVISOR_STOP_GATE,
  REVERSE_CITIZEN_AND_MARRIAGE,
  SUMMARY_SECTION,
} from 'app/models/sections/constants';
import { getFormName } from 'app/routes/helpers';
import { ReverseMortgageCalcsRequest } from 'app/api/rates/types';
import { hasCoBorrower, isUsingReverseToPurchase } from 'app/models/fields/conditionals';
import {
  ajaxPostShortForm,
  getNextSection,
  goToSection,
  referToLoanOfficer,
} from 'app/actions/form/actions';
import { FieldNames } from 'app/models/fields/names';
import { fetchReverseMortgageCalcs } from 'app/api/rates';
import { getAuditMetadata, makeActionCreator } from 'app/util/actions';
import { Type } from 'app/actions/form/reverse-mortgage/types';
import { FormName, GeneralReferralCode } from '@lenderful/domain';
import { getLoanOfficer } from 'app/reducers/loan-officer';
import { mapUniversalLoanRequest, postUniversalLoanApplication } from 'app/api/loan-application/universal/loan';
import { logger } from 'app/util/logger';
import { hasEitherSsn } from '../../../components/FormFields/Ssn/CollectSsnBase';
import { postEmailReverseSummary } from 'app/api/reverse-summary';
import { Dispatch } from 'redux';
import { ReverseSummaryPdfRequest } from 'app/api/reverse-summary/types';
import { getDownPayment, getEstimatedHomeValue, getLineOfCredit, getLoanTerm, getMortgageBalance, getTenurePayment, getTermPayment } from 'app/api/loan-application/universal/reverse';
import { ajaxValidateBorrowerResidenceNoNavigate } from '../address-validation/actions';
import { YesOrNo } from '../../../models/options/enums';

export const ajaxReverseMortgageCalcs        = makeActionCreator(Type.AJAX_REVERSE_MORTGAGE_CALCS, 'payload');
export const ajaxReverseMortgageCalcsSuccess = makeActionCreator(Type.AJAX_REVERSE_MORTGAGE_CALCS_SUCCESS, 'payload');
export const ajaxReverseMortgageCalcsFailure = makeActionCreator(Type.AJAX_REVERSE_MORTGAGE_CALCS_FAIL, 'payload');
export const ajaxEmailReverseSummary         = makeActionCreator(Type.AJAX_EMAIL_REVERSE_SUMMARY, 'payload');
export const ajaxEmailReverseSummaryFail     = makeActionCreator(Type.AJAX_EMAIL_REVERSE_SUMMARY_FAIL, 'payload');
export const ajaxEmailReverseSummarySuccess  = makeActionCreator(Type.AJAX_EMAIL_REVERSE_SUMMARY_SUCCESS, 'payload');

export function handleReverseMortgageLoanSelection() {
  return (dispatch) => {
    dispatch(change(FormName.REVERSE, FieldNames.hasReverseSummaryInfo, YesOrNo.YES));
    dispatch(push(`${routes.reverse}#${REVERSE_CITIZEN_AND_MARRIAGE}`));
  };
}

export function handleReverseMortgageAskForAdvisor() {
  return (dispatch) => {
    dispatch(push(`${routes.reverse}#${REVERSE_ADVISOR_STOP_GATE}`));
  };
}

export function handleAddressValidationAndReverseShortFormSubmit() {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    ajaxValidateBorrowerResidenceNoNavigate(formData);
    return dispatch(handleReverseShortFormSubmit());
  };
}

export function handleReverseShortFormSubmit() {
  return async (dispatch) => {
    try {
      await dispatch(calculateReverseMortgage());
      // post short form data
      await dispatch(ajaxPostShortForm());
      // navigate to summary
      return dispatch(goToSection(SUMMARY_SECTION));
    } catch (error) {
      // navigate to referral
      if (error.response.status === 400 && error.response?.data?.error?.code === 'STATE_NOT_SUPPORTED') {
        dispatch(referToLoanOfficer(GeneralReferralCode.STATE_NOT_SUPPORTED));
        return false;
      }
    }
  };
}

export function calculateReverseMortgage() {
  return async (dispatch, getState) => {

    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const values = getFormValues(formName)(state);

    const today = moment();

    const borrowerAgeInFiveMonths = values[FieldNames.age] ?
      values[FieldNames.age] :
      today.diff(moment(values[FieldNames.dateOfBirth]).add(5, 'M'), 'years');

    const coBorrowerAgeInFiveMonths = values[FieldNames.coBorrowerAge] ?
      values[FieldNames.coBorrowerAge] :
      today.diff(moment(values[FieldNames.coBorrowerDOB]).add(5, 'M'), 'years');

    const request: ReverseMortgageCalcsRequest = {
      ageOfYoungest      : hasCoBorrower(values) ? Math.min(borrowerAgeInFiveMonths, coBorrowerAgeInFiveMonths) : borrowerAgeInFiveMonths,
      estimatedHomeValue : getEstimatedHomeValue(values),
      expectedLumpSum    : values[FieldNames.expectedLumpSum],
      mortgageBalance    : getMortgageBalance(values),
      propertyState      : values[FieldNames.propertyState] ? values[FieldNames.propertyState] : values[FieldNames.livingPropertyState],
      term               : values[FieldNames.loanTerm] * 12,
    };
    dispatch(ajaxReverseMortgageCalcs());
    try {
      const response = await fetchReverseMortgageCalcs(request);
      dispatch(ajaxReverseMortgageCalcsSuccess());

      dispatch(change(formName, FieldNames.initialMortgageInsurance, response.initialMortgageInsurance));
      dispatch(change(formName, FieldNames.maxClaimAmount, response.maxClaimAmount));
      dispatch(change(formName, FieldNames.maxLumpSum, response.maxLumpSum));
      dispatch(change(formName, FieldNames.netPrincipalLimit, response.netPrincipalLimit));
      dispatch(change(formName, FieldNames.tenurePayment, response.tenurePayment));
      dispatch(change(formName, FieldNames.termPayment, response.termPayment));
      dispatch(change(formName, FieldNames.totalUpfrontCosts, response.totalUpfrontCosts));

      if (isUsingReverseToPurchase(values)) {
        const downPayment = getDownPayment(values);
        dispatch(change(formName, FieldNames.downDollar, Math.max(downPayment, 0)));
      }
    } catch (error) {
      dispatch(ajaxReverseMortgageCalcsFailure());
      // navigate to referral
      if (error.response.status === 400 && error.response?.data?.error?.code === 'STATE_NOT_SUPPORTED') {
        dispatch(referToLoanOfficer(GeneralReferralCode.STATE_NOT_SUPPORTED));
        throw error;
      }
    }
  };
}

export function handleReverseFormSubmit() {
  return async (dispatch) => {
    // @todo add handleDeclarationFlagsWhitelist()
    await dispatch(handleReverseMortgageApplication());
    return dispatch(getNextSection());
  };
}

/**
 * This method builds the new payload before submitting to the backend endpoint
 *
 * @param {FormName} formName
 * @param {FieldNames} fieldName
 * @param {string} value
 */
export const handleReverseMortgageApplication = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const formData = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    const universalLoanRequest = mapUniversalLoanRequest(formData, loanOfficer, formName);
    let auditMetaData;
    if (hasEitherSsn()) {
      auditMetaData = getAuditMetadata('Submit button');
    }
    return postUniversalLoanApplication({ ...universalLoanRequest, auditMetaData }).then(
      (response) => {
        logger.debug('Post Reverse Mortgage Response', response);
        return response;
      },
      (error) => {
        logger.error('Error: Post Reverse Mortgage', error);
      },
    );
  };
};

export function handleEmailReverseMortgageSummary() {
  return (dispatch: Dispatch, getState) => {
    const state = getState();
    const formName = getFormName(state.router.location.pathname);
    const values = getFormValues(formName)(state);
    const loanOfficer = getLoanOfficer(state);

    const mortgageBalance    = getMortgageBalance(values);
    const tenurePayment      = getTenurePayment(values);
    const termPayment        = getTermPayment(values);
    const loanTerm           = getLoanTerm(values);
    const estimatedHomeValue = getEstimatedHomeValue(values);
    const lineOfCreditAmount = getLineOfCredit(values);
    const downPayment        = getDownPayment(values);

    const propertyAppraisedValue  = values[FieldNames.homeValue];
    const netPrincipalLimit       = values[FieldNames.netPrincipalLimit];
    const totalUpfrontCosts       = values[FieldNames.totalUpfrontCosts];
    const reverseMortgageLoanType = values[FieldNames.reverseMortgageLoanType];
    const purchasePrice           = values[FieldNames.purchasePrice];
    const expectedLumpSum         = values[FieldNames.expectedLumpSum];

    const remainingEquity = propertyAppraisedValue - mortgageBalance - netPrincipalLimit - totalUpfrontCosts;

    const payload: ReverseSummaryPdfRequest = {
      downPayment,
      email               : values[FieldNames.email],
      estimatedHomeValue,
      expectedLumpSum,
      firstName           : values[FieldNames.firstName],
      isPurchasingNewHome : isUsingReverseToPurchase(values),
      lastName            : values[FieldNames.lastName],
      lineOfCreditAmount,
      loanOfficerId       : loanOfficer.id,
      loanTerm,
      mortgageBalance,
      netPrincipalLimit,
      propertyStreet      : isUsingReverseToPurchase(values) ? values[FieldNames.propertyStreet] : values[FieldNames.livingPropertyStreet],
      propertyStreet2     : isUsingReverseToPurchase(values) ? values[FieldNames.propertyStreet2] : values[FieldNames.livingPropertyStreet2],
      propertyCity        : isUsingReverseToPurchase(values) ? values[FieldNames.propertyCity] : values[FieldNames.livingPropertyCity],
      propertyState       : isUsingReverseToPurchase(values) ? values[FieldNames.propertyState] : values[FieldNames.livingPropertyState],
      propertyZip         : isUsingReverseToPurchase(values) ? values[FieldNames.propertyZip] : values[FieldNames.livingPropertyZip],
      purchasePrice,
      remainingEquity,
      reverseMortgageLoanType,
      tenurePayment,
      termPayment,
      totalUpfrontCosts,
    };
    dispatch({ type: Type.AJAX_EMAIL_REVERSE_SUMMARY, payload });
    return postEmailReverseSummary(payload).then(
      (response) => {
        dispatch(ajaxEmailReverseSummarySuccess(response));
      },
      (error) => {
        dispatch(ajaxEmailReverseSummaryFail(error));
      });
  };
}
