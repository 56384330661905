import { RadioField } from 'app/components/FormFields/RadioField';
import {
  AG_PROPERTY_TYPE_OPTIONS,
  AG_PROPERTY_IDENTITY_OPTIONS,
  COMMERCIAL_ACCOUNT_TYPE_PURCHASE_OPTIONS, COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
} from 'app/models/options/options';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { Select } from '../../components/FormFields/Select';
import { Questions } from './Questions';
import { AG_SHARED } from './shared';
import { PurchaseDownPayment } from '../../components/FormFields/PurchaseDownPayment';
import { SingleFutureDate } from '../../components/FormFields/SingleFutureDate';
import { hasAGLegalDescription } from '../fields/conditionals';
import { BankAccounts } from '../../components/FormFieldArrays/BankAccounts';
import { TextArea } from '../../components/FormFields/TextArea';
import { Address } from '../../components/FormFields/Address';
import { State } from '../../components/FormFields/State';
import { ZipCode } from '../../components/FormFields/ZipCode';
import { IntegerAllowZero } from '../../components/FormFields/IntegerAllowZero';

export const AGRICULTURAL_PURCHASE_ACREAGE_QUESTIONS: Questions = new Questions([
  {
    id        : 1001,
    component : PurchaseDownPayment,
    hideTitle : true,
    label     : 'Acreage or Ag Building Value', // uses Translation
    name      : FieldNames.homeValue,
  },
  {
    id           : 1002,
    component    : Select,
    label        : 'Property Type',
    name         : FieldNames.agPropertyType,
    fieldPopover : true,
    options      : AG_PROPERTY_TYPE_OPTIONS,
  },
  {
    id         : 1003,
    component  : SingleFutureDate,
    label      : 'Expected Closing Date',
    name       : FieldNames.expectedClosingDate,
    isOptional : true,
  },
  {
    id        : 1004,
    component : BankAccounts,
    options   : COMMERCIAL_ACCOUNT_TYPE_PURCHASE_OPTIONS,
    name      : FieldNames.assets,
  },
  {
    id        : 1005,
    component : RadioField,
    title     : `How is the Ag property identified?`,
    name      : FieldNames.agPropertyIdentity,
    options   : AG_PROPERTY_IDENTITY_OPTIONS,
  },
  {
    id          : 1006,
    label       : 'Legal Description',
    component   : TextArea,
    name        : FieldNames.commercialDescription,
    showIf    : [hasAGLegalDescription],
  },
  {
    id        : 1007,
    component : Address,
    label     : 'Street Address',
    name      : FieldNames.propertyStreet,
    title     : 'Where is the property located?',
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet,
    },
    showIf: [(values) => !hasAGLegalDescription(values)],
  },
  {
    id         : 1008,
    component  : Address,
    isOptional : true,
    label      : 'Street Address 2',
    name       : FieldNames.propertyStreet2,
    validator: {
      type : 'isMailingAddress',
      param: FieldNames.propertyStreet2,
    },
    showIf: [(values) => !hasAGLegalDescription(values)],
  },
  {
    id        : 1009,
    component : Address,
    label     : 'City',
    name      : FieldNames.propertyCity,
    showIf: [(values) => !hasAGLegalDescription(values)],
  },
  {
    id        : 1010,
    component : State,
    label     : 'State',
    name      : FieldNames.propertyState,
    showIf: [(values) => !hasAGLegalDescription(values)],
  },
  {
    id        : 1011,
    component : ZipCode,
    label     : 'Zip Code',
    name      : FieldNames.propertyZip,
    showIf: [(values) => !hasAGLegalDescription(values)],
  },
  {
    id        : 1012,
    component : Currency,
    label     : 'Annual Taxes',
    name      : FieldNames.yearTaxes,
    title     : 'What are your expected taxes and insurance?',
  },
  {
    id        : 1013,
    component : Currency,
    label     : 'Annual Insurance',
    name      : FieldNames.yearInsure,
  },
  {
    id           : 1014,
    component    : IntegerAllowZero,
    endAdornment : 'Square feet',
    label        : 'Land or Site Size (approximately)',
    name         : FieldNames.landSize,
    validator    : {
      type  : 'isGreaterThanZero',
      param : FieldNames.landSize,
    },
  },
  {
    id         : 1015,
    component  : Select,
    label      : 'Number of Buildings or Structures',
    name       : FieldNames.numOfStructures,
    options    : COMMERCIAL_NUMBER_OF_STRUCTURES_OPTIONS,
  },
  {
    id         : 1016,
    component  : IntegerAllowZero,
    label      : 'Building Square Footage (approximately)',
    name       : FieldNames.buildingSquareFootage,
  },
  ...AG_SHARED,
]);
