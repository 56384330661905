import { RadioField } from 'app/components/FormFields/RadioField';
import {
  COMMERCIAL_PURCHASE_TIMING_OPTIONS,
} from 'app/models/options/options';
import { AG_SHARED } from 'app/models/questions/shared';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { TextArea } from '../../components/FormFields/TextArea';
import { Questions } from './Questions';

export const AGRICULTURAL_LINE_OF_CREDIT_QUESTIONS: Questions = new Questions([
  {
    id: 1000,
    component: TextArea,
    label: 'Intended Use for Loan',
    name: FieldNames.commercialLoanPurpose,
  },
  {
    id: 1001,
    component: RadioField,
    label: 'Timing for Loan',
    name: FieldNames.commercialLoanTiming,
    options: COMMERCIAL_PURCHASE_TIMING_OPTIONS,
  },
  {
    id: 1002,
    component: Currency,
    label: 'Requested Loan Amount',
    name: FieldNames.loanAmount,
  },
  ...AG_SHARED,
]);
