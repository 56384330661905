import { Section } from 'app/models/types';
import { FieldNames } from 'app/models/fields/names';
import {
  PURCHASE_TURBO_BUYING_PROCESS,
  PURCHASE_TURBO_PROPERTY_INFO_CREDIT,
  PURCHASE_TURBO_PROPERTY_ADDRESS,
  PURCHASE_TURBO_PERSONAL_INFO,
  PURCHASE_TURBO_ANNUAL_COSTS,
  PURCHASE_TURBO_MARITAL_STATUS_DEPENDENTS,
  PURCHASE_TURBO_CITIZENSHIP_MILITARY,
  PURCHASE_TURBO_HOUSING_TYPE,
  PURCHASE_TURBO_OWNER_INFO,
  PURCHASE_TURBO_RENTER_INFO,
  PURCHASE_TURBO_RENT_FREE_INFO,
  PURCHASE_TURBO_COBORROWER_PERSONAL_INFO,
  PURCHASE_TURBO_COBORROWER_HOUSING_INFO,
  PURCHASE_TURBO_COBORROWER_CITIZENSHIP_MILITARY,
  PURCHASE_TURBO_CREDIT_AUTHORIZATION,
  PURCHASE_TURBO_REAL_ESTATE_LIABILITIES,
  PURCHASE_TURBO_ADDITIONAL_PROPERTIES,
  PURCHASE_TURBO_AVAILABLE_FUNDS,
  PURCHASE_TURBO_DOWN_PAYMENT_SUMMARY,
  PURCHASE_TURBO_AUTOMATIC_VOIE,
  PURCHASE_TURBO_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_EMPLOYEE_INFO,
  PURCHASE_TURBO_RETIRED_OTHER,
  PURCHASE_TURBO_COBORROWER_EMPLOYMENT_TYPE,
  PURCHASE_TURBO_COBORROWER_EMPLOYEE_INFO,
  PURCHASE_TURBO_DECLARATION_QUESTIONS,
  PURCHASE_TURBO_COBORROWER_DECLARATION_QUESTIONS,
  PURCHASE_TURBO_COLLECT_SSN_FINAL,
  PURCHASE_TURBO_USPS_VERIFICATION,
  PURCHASE_TURBO_COLLECT_SSN,
  PURCHASE_TURBO_COLLECT_SSN_QUESTION,
  PURCHASE_TURBO_VERIFICATION_CONNECT,
  PURCHASE_TURBO_VOIE_REPORTS,
  PURCHASE_TURBO_CONFIRMATION,
  PURCHASE_TURBO_MIN_LOAN_STOP_GATE,
  PURCHASE_TURBO_IS_SHOPPING_ZIP_CODE_QUESTION,
} from 'app/models/sections/constants';
import {
  ajaxValidateBorrowerResidence,
  ajaxValidateCoBorrowerResidence,
  ajaxValidateSubjectProperty,
} from 'app/actions/form/address-validation/actions';
import {
  canVerifyOfIncomeEmployment,
  coBorrowerHasDifferentAddress,
  hasCoBorrower,
  isBorrowerUnderage,
  isCoBorrowerEmployee,
  isCoBorrowerEmploymentOther,
  isCoBorrowerRetired,
  isCoBorrowerSelfEmployed,
  isEmployee,
  isEmploymentOther,
  isExcludingSsnQuestions, isHomeowner,
  isManualIncomeAndEmploymentRequired,
  isPrimaryHome, isRentFree, isRenting,
  isRetired,
  isSelfEmployed,
  isShopping,
  LoanOfficerCondition,
  TransferToFormCondition,
} from 'app/models/fields/conditionals';
import { AuthorizeCreditReport } from 'app/components/FormAgreements/AuthorizeCreditReport';
import { FormParagraphs } from 'app/models/paragraphs';
import { getTurboDeclarationQuestions } from 'app/models/questions/declarations';
import { AddressVerified } from 'app/components/Turbo/AddressVerified';
import { MinimumLoanAmount } from '../../components/StopGates/MinimumLoanAmount';
import { handleGetSubjectPropertyInfo } from 'app/actions/form/property/actions';
import { AuthorizeSsnFullPull } from 'app/components/FormAgreements/AuthorizeSsnFullPull';
import { PURCHASE_TURBO_QUESTIONS } from '../questions/purchase-turbo';
import { ajaxPostCreditReportForPurchaseTurbo } from '../../actions/form/credit-report/actions';
import { ajaxGetFinancialVerificationUrl } from '../../actions/form/verification/actions';
import { purchaseLFQuestionsByName } from '../questions/application-purchase';
import {
  handleGoToFinancialVerificationPreStep,
  handlePurchaseTurboFormSubmit,
  handlePurchaseTurboGetFinalRates,
  handlePurchaseTurboGetRates,
} from '../../actions/form/turbo/actions';
import { Confirmation } from '../../components/Confirmation';
import { BorrowerType } from '@lenderful/domain';

export const getPurchaseTurboSectionById = (id: number): Section => {
  return PURCHASE_TURBO_SECTIONS.find((section) => section.id === id) as Section;
};

const PURCHASE_TURBO_SECTIONS: Section[] = [
  {
    id        : PURCHASE_TURBO_BUYING_PROCESS,
    title     : 'Congratulations on starting down the path to home ownership!',
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.buyingProcess,
    ]),
    conditionalIf : {
      transferFormIf : [TransferToFormCondition.purchaseTurboToPrequal],
    },
  },
  {
    id        : PURCHASE_TURBO_PROPERTY_INFO_CREDIT,
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.homeValue,
      FieldNames.propertyType,
      FieldNames.propertyUsage,
      FieldNames.expectedClosingDate,
      FieldNames.builtYear,
    ]),
    title : 'Tell us about your new purchase',
  },
  {
    id                : PURCHASE_TURBO_PROPERTY_ADDRESS,
    questions         : [...PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
      FieldNames.creditScore,
    ]), PURCHASE_TURBO_QUESTIONS.getById(PURCHASE_TURBO_IS_SHOPPING_ZIP_CODE_QUESTION) ] ,
    submitIf : [(values) => !isShopping(values)],
    submitAction : ajaxValidateSubjectProperty,
  },
  {
    id: PURCHASE_TURBO_USPS_VERIFICATION,
    ifNoQuestions : AddressVerified,
    submitAction  : handleGetSubjectPropertyInfo,
    showIf : [(values) => !isShopping(values)],
  },
  {
    id        : PURCHASE_TURBO_PERSONAL_INFO,
    title     : 'Enter your personal information',
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.firstName,
      FieldNames.middleName,
      FieldNames.lastName,
      FieldNames.suffixName,
      FieldNames.dateOfBirth,
      FieldNames.email,
      FieldNames.phone,
    ]),
    conditionalIf : {
      referToLoIf : [
        LoanOfficerCondition.isBorrowerUnderage,
      ],
    },
    submitText   : 'Submit',
    submitAction : handlePurchaseTurboGetRates,
    submitIf     : [
      (values) => !isBorrowerUnderage(values),
    ],
  },
  {
    id        : PURCHASE_TURBO_ANNUAL_COSTS,
    paragraph : FormParagraphs.estimatedAmounts,
    title     : 'What are your expected taxes and insurance for your new home?',
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.yearTaxes,
      FieldNames.yearInsure,
      FieldNames.yearAssociate,
      FieldNames.escrowUsage,
    ]),
  },
  {
    id: PURCHASE_TURBO_MARITAL_STATUS_DEPENDENTS,
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.maritalStatus,
      FieldNames.dependentsAges,
    ]),
  },
  {
    id        : PURCHASE_TURBO_CITIZENSHIP_MILITARY,
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.citizenshipType,
      FieldNames.militaryExperience,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isBorrowerNotCitizen],
    },
  },
  {
    id       : PURCHASE_TURBO_HOUSING_TYPE,
    title    : 'What is your current housing situation?',
    questions: PURCHASE_TURBO_QUESTIONS.getByName([FieldNames.livingRentOrOwn]),
  },
  {
    id       : PURCHASE_TURBO_OWNER_INFO,
    title    : 'Provide your homeowner information',
    showIf     : [isHomeowner],
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.livingPropertyType,
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingYearTaxes,
      FieldNames.livingYearInsure,
      FieldNames.livingYearAssociate,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
      FieldNames.livingEscrowUsage,
      FieldNames.homeSoldYN,
    ]),
    submitIf: [(values) => !isPrimaryHome(values)],
    submitAction : (formValues) => ajaxValidateBorrowerResidence(formValues),
  },
  {
    id         : PURCHASE_TURBO_RENTER_INFO,
    showIf     : [isRenting],
    title      : 'Provide your renter information',
    questions  : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingMonthlyRentAmount,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
    ]),
  },
  {
    id         : PURCHASE_TURBO_RENT_FREE_INFO,
    showIf     : [isRentFree],
    title      : 'Provide your housing information',
    questions  :PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.livingPropertyStreet,
      FieldNames.livingPropertyStreet2,
      FieldNames.livingPropertyCity,
      FieldNames.livingPropertyState,
      FieldNames.livingPropertyZip,
      FieldNames.livingOccupancyStart,
      FieldNames.livingPrevPropertyStreet,
      FieldNames.livingPrevPropertyStreet2,
      FieldNames.livingPrevPropertyCity,
      FieldNames.livingPrevPropertyState,
      FieldNames.livingPrevPropertyZip,
      FieldNames.livingPrevRentOrOwn,
      FieldNames.livingPrevOccupancyStart,
    ]),
  },
  {
    id        : PURCHASE_TURBO_COBORROWER_PERSONAL_INFO,
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerYN,
      FieldNames.coBorrowerFirstName,
      FieldNames.coBorrowerMiddleName,
      FieldNames.coBorrowerLastName,
      FieldNames.coBorrowerSuffixName,
      FieldNames.coBorrowerDOB,
      FieldNames.coBorrowerEmail,
      FieldNames.coBorrowerPhone,
      FieldNames.coBorrowerOnTitle,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerUnderage],
    },
  },
  {
    id        : PURCHASE_TURBO_COBORROWER_HOUSING_INFO,
    questions : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerSameAddress,
      FieldNames.coBorrowerLivingPropertyType,
      FieldNames.coBorrowerLivingPropertyStreet,
      FieldNames.coBorrowerLivingPropertyStreet2,
      FieldNames.coBorrowerLivingPropertyCity,
      FieldNames.coBorrowerLivingPropertyState,
      FieldNames.coBorrowerLivingPropertyZip,
      FieldNames.coBorrowerLivingRentOrOwn,
      FieldNames.coBorrowerMaritalStatus,
    ]),
    showIf       : [hasCoBorrower],
    submitIf     : [(formValues) => coBorrowerHasDifferentAddress(formValues)],
    submitAction : (formValues) => ajaxValidateCoBorrowerResidence(formValues),
  },
  {
    id           : PURCHASE_TURBO_COBORROWER_CITIZENSHIP_MILITARY,
    questions    : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerCitizenshipType,
      FieldNames.coBorrowerMilitaryExperience,
    ]),
    conditionalIf : {
      referToLoIf : [LoanOfficerCondition.isCoBorrowerNotCitizen],
    },
    showIf       : [hasCoBorrower],
  },
  {
    id           : PURCHASE_TURBO_CREDIT_AUTHORIZATION,
    questions    : PURCHASE_TURBO_QUESTIONS.getByName([ FieldNames.loanOfficer ]),
    agreement    : <AuthorizeCreditReport />,
    submitAction : ajaxPostCreditReportForPurchaseTurbo,
  },
  {
    id              : PURCHASE_TURBO_REAL_ESTATE_LIABILITIES,
    title           : 'Real Estate Liabilities',
    questions       : PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.realEstateLiabilities,
    ]),
    submitAction: handlePurchaseTurboGetFinalRates,
  },
  {
    id         : PURCHASE_TURBO_ADDITIONAL_PROPERTIES,
    title      : 'Do you own any additional properties?',
    questions  : purchaseLFQuestionsByName([
      FieldNames.hasAddlPropertiesYN,
      FieldNames.addlProperties,
    ]),
    showIf: [isHomeowner],
  },
  {
    id       : PURCHASE_TURBO_AVAILABLE_FUNDS,
    title    : 'Available Funds',
    paragraph: FormParagraphs.availableFunds,
    questions: purchaseLFQuestionsByName([FieldNames.assets]),
  },
  {
    id        : PURCHASE_TURBO_DOWN_PAYMENT_SUMMARY,
    title     : 'Down Payment Funds Summary',
    questions : purchaseLFQuestionsByName([FieldNames.assetSummary]),
  },
  {
    id           : PURCHASE_TURBO_AUTOMATIC_VOIE,
    paragraph    : FormParagraphs.automaticVoie,
    questions    : PURCHASE_TURBO_QUESTIONS.getByName([FieldNames.canVerifyOfIncomeEmployment]),
    title        : 'If you are a W2 employee, would you like to automatically receive verification of your income and employment?',
    submitAction : handleGoToFinancialVerificationPreStep,
  },
  {
    id            : PURCHASE_TURBO_COLLECT_SSN,
    questions     : [PURCHASE_TURBO_QUESTIONS.getById(PURCHASE_TURBO_COLLECT_SSN_QUESTION)],
    showIf        : [canVerifyOfIncomeEmployment],
    submitIf      : [canVerifyOfIncomeEmployment],
    submitAction  : ajaxGetFinancialVerificationUrl,
    agreement     : <AuthorizeSsnFullPull />,
  },
  {
    id                : PURCHASE_TURBO_VERIFICATION_CONNECT,
    questions         : PURCHASE_TURBO_QUESTIONS.getByName([FieldNames.isFinancialVerificationComplete]),
    showIf            : [canVerifyOfIncomeEmployment],
    hideBackButton    : true,
    hideForwardButton : true,
  },
  {
    id             : PURCHASE_TURBO_VOIE_REPORTS,
    questions      : PURCHASE_TURBO_QUESTIONS.getByName([FieldNames.isFinancialVerificationComplete]),
    showIf         : [canVerifyOfIncomeEmployment],
    hideBackButton : true,
  },
  {
    id        : PURCHASE_TURBO_EMPLOYMENT_TYPE,
    questions : PURCHASE_TURBO_QUESTIONS.getByName([FieldNames.employmentType]),
    showIf: [isManualIncomeAndEmploymentRequired],
  },
  {
    id       : PURCHASE_TURBO_EMPLOYEE_INFO,
    title    : 'Tell us about your income and employment',
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.employCompanyName,
      FieldNames.employStreet,
      FieldNames.employStreet2,
      FieldNames.employCity,
      FieldNames.employState,
      FieldNames.employZip,
      FieldNames.employTitle,
      FieldNames.employStart,
      FieldNames.employBase,
      FieldNames.employOwnershipShare,
      FieldNames.employPartyTransaction,
      FieldNames.employPrevName,
      FieldNames.employPrevStreet,
      FieldNames.employPrevStreet2,
      FieldNames.employPrevCity,
      FieldNames.employPrevState,
      FieldNames.employPrevZip,
      FieldNames.employPrevTitle,
      FieldNames.employPrevStart,
      FieldNames.employPrevEnd,
      FieldNames.employOvertime,
      FieldNames.employCommission,
      FieldNames.employBonus,
      FieldNames.additionalIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isEmployee(values) || isSelfEmployed(values))],
  },
  {
    id       : PURCHASE_TURBO_RETIRED_OTHER,
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.retirementIncome,
      FieldNames.otherIncome,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && (isRetired(values) || isEmploymentOther(values))],
  },
  {
    id       : PURCHASE_TURBO_COBORROWER_EMPLOYMENT_TYPE,
    title    : 'What is your co-borrower’s income source?',
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerEmploymentType,
    ]),
    showIf: [(values) => isManualIncomeAndEmploymentRequired(values) && hasCoBorrower(values)],
  },
  {
    id       : PURCHASE_TURBO_COBORROWER_EMPLOYEE_INFO,
    questions: PURCHASE_TURBO_QUESTIONS.getByName([
      FieldNames.coBorrowerEmployCompanyName,
      FieldNames.coBorrowerEmployStreet,
      FieldNames.coBorrowerEmployStreet2,
      FieldNames.coBorrowerEmployCity,
      FieldNames.coBorrowerEmployState,
      FieldNames.coBorrowerEmployZip,
      FieldNames.coBorrowerEmployTitle,
      FieldNames.coBorrowerEmployStart,
      FieldNames.coBorrowerEmployBase,
      FieldNames.coBorrowerEmployOwnershipShare,
      FieldNames.coBorrowerEmployPartyTransaction,
      FieldNames.coBorrowerEmployPrevName,
      FieldNames.coBorrowerEmployPrevStreet,
      FieldNames.coBorrowerEmployPrevStreet2,
      FieldNames.coBorrowerEmployPrevCity,
      FieldNames.coBorrowerEmployPrevState,
      FieldNames.coBorrowerEmployPrevZip,
      FieldNames.coBorrowerEmployPrevTitle,
      FieldNames.coBorrowerEmployPrevStart,
      FieldNames.coBorrowerEmployPrevEnd,
      FieldNames.coBorrowerEmployOvertime,
      FieldNames.coBorrowerEmployCommission,
      FieldNames.coBorrowerEmployBonus,
      FieldNames.coBorrowerAdditionalIncome,
      FieldNames.coBorrowerOtherIncome,
      FieldNames.coBorrowerRetirementIncome,
    ]),
    showIf: [
      (values) => isManualIncomeAndEmploymentRequired(values) &&
      (isCoBorrowerEmployee(values) ||
        isCoBorrowerEmploymentOther(values) ||
        isCoBorrowerRetired(values) ||
        isCoBorrowerSelfEmployed(values)),
    ],
  },
  {
    id           : PURCHASE_TURBO_DECLARATION_QUESTIONS,
    title        : 'Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.PRIMARY),
    submitIf     : [(values) => !hasCoBorrower(values) && isExcludingSsnQuestions(values)],
    submitAction : handlePurchaseTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : PURCHASE_TURBO_COBORROWER_DECLARATION_QUESTIONS,
    title        : 'Co-Borrower Questions',
    questions    : getTurboDeclarationQuestions(BorrowerType.COBORROWER),
    showIf       : [hasCoBorrower],
    submitIf     : [isExcludingSsnQuestions],
    submitAction : handlePurchaseTurboFormSubmit,
    submitText   : 'Submit',
  },
  {
    id           : PURCHASE_TURBO_COLLECT_SSN_FINAL,
    questions    : [PURCHASE_TURBO_QUESTIONS.getById(PURCHASE_TURBO_COLLECT_SSN_QUESTION)],
    submitAction : handlePurchaseTurboFormSubmit,
    submitText   : 'Submit',
    agreement    : <AuthorizeSsnFullPull />,
  },
  {
    id             : PURCHASE_TURBO_CONFIRMATION,
    ifNoQuestions  : Confirmation,
    hideProgressBar: true,
  },
  {
    id: PURCHASE_TURBO_MIN_LOAN_STOP_GATE,
    ifNoQuestions: MinimumLoanAmount,
  },
];
