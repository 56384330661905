import { makeActionCreator } from 'app/util/actions';
import { Type } from 'app/actions/form/auto-valuation/types';
import { getVehiclesTypeahead, getVehicleValuation, getVehicleVariants, TPErrorCode } from 'app/api/valuation/auto';
import { logger } from 'app/util/logger';
import { findVehicleByString } from 'app/reducers/auto-valuation';
import { FieldNames } from 'app/models/fields/names';
import { handleAutoLoanFetchRates, referToLoanOfficer } from 'app/actions/form/actions';
import { FormName, GeneralReferralCode } from '@lenderful/domain';
import { change } from 'redux-form';

export const ajaxGetAutoValuationTypeaheadSuccess = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD_SUCCESS, 'payload');
export const ajaxGetAutoValuationTypeaheadFail    = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD_FAIL, 'payload');
export const ajaxGetAutoValuationVariantsSuccess  = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_VARIANTS_SUCCESS, 'payload');
export const ajaxGetAutoValuationVariantsFail     = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_VARIANTS_FAIL, 'payload');
export const ajaxGetAutoValuationReportSuccess    = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_REPORT_SUCCESS, 'payload');
export const ajaxGetAutoValuationReportFail       = makeActionCreator(Type.AJAX_GET_AUTO_VALUATION_REPORT_FAIL, 'payload');

/**
 * This action makes a call to find all the possible options for the
 * vehicle typeahead based on the query string passed.
 *
 * @param query year/make/model/style to search for
 * @returns
 */
export const ajaxGetVehiclesTypeahead = (query: string) => {
  return (dispatch) => {
    dispatch({ type: Type.AJAX_GET_AUTO_VALUATION_TYPEAHEAD, query });

    return getVehiclesTypeahead({ query })
      .then((response) => {
        dispatch(ajaxGetAutoValuationTypeaheadSuccess(response.vehicles));
        return response.vehicles;
      })
      .catch((error) => {
        logger.warn('Auto vehicles are not available', { error, query });
        dispatch(ajaxGetAutoValuationTypeaheadFail());
      });
  };
};

/**
 * This action makes a call to find all the possible vehicle variants
 * for a specific year/make/model/style of vehicle.  The `vehicle` passed
 * in is a string representing the year/make/model/style.  This is matched
 * up to a specific vehicle in state.
 *
 * @param {string} vehicle representation of a year/make/model/style
 * @returns
 */
export const ajaxGetVehicleVariants = (vehicle: string) => {
  return (dispatch, getState) => {
    const vehicleFromState = findVehicleByString(getState(), vehicle);

    dispatch({ type: Type.AJAX_GET_AUTO_VALUATION_VARIANTS, vehicleFromState });

    return getVehicleVariants(vehicleFromState)
      .then((response) => {
        dispatch(ajaxGetAutoValuationVariantsSuccess(response));
        return response;
      })
      .catch((error) => {
        logger.warn('Auto vehicles variants are not available', { error, vehicleFromState });
        dispatch(ajaxGetAutoValuationVariantsFail());
      });
  };
};

/**
 * This method handles making the call to get the vehicle valuation and auto rates.  There are some
 * potential referrals, such as if the vehicle valuation has no data, the customer has their vehicle
 * valued too high (configurable by client), or if there are no rates based on the given loan
 * scenario.
 *
 * @param formData
 * @returns
 */
export function handleAutoLoanRatesAndValuation(formData) {
  return async (dispatch) => {

    const request = {
      mileage     : formData[FieldNames.vehicleMileage],
      vehicleId   : formData[FieldNames.vehicleId],
      vehicleYear : formData[FieldNames.vehicleYear],
      zipCode     : formData[FieldNames.propertyZip],
    };

    dispatch({ type: Type.AJAX_GET_AUTO_VALUATION_REPORT, request });

    try {
      const response = await getVehicleValuation(request);
      if(Object.values(TPErrorCode).includes(response.error)) {
        dispatch(referToLoanOfficer(GeneralReferralCode.NO_VEHICLE_VALUATION));
        throw Error(response.error);
      }
      dispatch(change(FormName.AUTOLOAN, FieldNames.autoRetailValue, response.retail.target));
      dispatch(ajaxGetAutoValuationReportSuccess(response));
      const vehicleValuationLimit = response.retail.target * (formData[FieldNames.autoHighLimitPercent] / 100);
      const hasValuationError = !!response.error;
      let isOverValuationLimit = formData[FieldNames.hasAutoLoanSpecialCalculation] ?
        vehicleValuationLimit < (formData[FieldNames.purchasePrice] - formData[FieldNames.tradeInValue] - formData[FieldNames.downDollar]) :
        vehicleValuationLimit < formData[FieldNames.purchasePrice];

      if (!hasValuationError && isOverValuationLimit) {
        return dispatch(referToLoanOfficer(GeneralReferralCode.HAS_AUTO_PRICE_SET_TOO_HIGH));
      } else {
        return dispatch(handleAutoLoanFetchRates(formData));
      }
    } catch (error) {
      logger.warn('Auto vehicles report is not available', { error, request });
      return dispatch(ajaxGetAutoValuationReportFail());
    }
  };
}
