import { FieldNames } from 'app/models/fields/names';
import * as FormActions from 'app/actions/form/actions';
import { Section } from 'app/models/types';
import { closingCostQuestionsByName } from 'app/models/questions/closing-costs';
import { ClosingCostEstimation } from 'app/components/ClosingCost/ClosingCostEstimation';

export const getClosingCostSectionById = (id: number): Section => {
  const section = CLOSING_COST_SECTIONS.find((section) => section.id === id) as Section;
  return section;
};

const CLOSING_COST_SECTIONS: Section[] = [
  {
    id              : 101,
    hideBackButton  : true,
    hideProgressBar : true,
    questions       : closingCostQuestionsByName([
      FieldNames.propertyStreet,
      FieldNames.propertyStreet2,
      FieldNames.propertyCity,
      FieldNames.propertyState,
      FieldNames.propertyZip,
      FieldNames.mortgageBalance,
      FieldNames.escrowUsage,
    ]),
    title: 'We\'ll need you to add or confirm some data in order to deliver your Closing Cost Estimate.',
    submitAction : FormActions.handleClosingCostsRateLookup,
  },
  {
    id               : 102,
    hideBackButton   : true,
    hideForwardButton: true,
    hideProgressBar  : true,
    ifNoQuestions    : ClosingCostEstimation,
  },
];
