import React from 'react';
import { connect } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { ImageSection } from 'app/components/ImageSection';
import { useTranslation } from 'react-i18next';
import { RootState } from 'app/store/types';
import { DocumentUploadButton } from 'app/components/DocumentUploadButton';
import { FormName } from '@lenderful/domain';
import { clientLinksSelector } from '../../reducers/app-config';
import { routes } from '../../routes/route-list';
import { ClientLinkButtons } from '../ClientLinkButtons';

type MappedProps = ReturnType<typeof mapStateToProps>;
type Props = MappedProps

const UploadDocumentsComponent = (props: Props) => {
  const { t } = useTranslation();
  const { confirmationImage, isHomeEquity, links } = props;
  const homeEquityLinks = links.filter(link => [FormName.HOME_EQUITY_TURBO].includes(link.formName));
  return (
    <Grid container={false}>
      <Grid item classes={{ item: 'noStepper' }}>
        <ImageSection image={confirmationImage}>
          <Typography variant='h6' classes={{ h6: 'titleMargin section' }}>
            {t('turbo.uploadDocuments.title', { defaultValue: 'Please upload the following documents:' })}
          </Typography>
          <Typography variant='body2' paragraph={true}>
            {t('turbo.uploadDocuments.paragraph1', {
              defaultValue:
                <ul>
                  <li>Copy of Drivers License</li>
                  <li>Current Mortgage Statement</li>
                  <li>Home Insurance Declaration Page</li>
                  <li>HOA Invoice (if applicable)</li>
                </ul>,
            })}
          </Typography>
          <Grid container justifyContent='center'>
            <Grid item>
              <DocumentUploadButton />
            </Grid>
            <Grid item>
              { isHomeEquity && homeEquityLinks.length > 0 && <ClientLinkButtons links={ homeEquityLinks } /> }
            </Grid>
          </Grid>
        </ImageSection>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  confirmationImage : state.config.confirmationImage,
  isHomeEquity:
    state.router.location.pathname === routes.homeEquityTurbo,
  links             : clientLinksSelector(state),
});

export const UploadDocumentsStep = connect(mapStateToProps)(UploadDocumentsComponent);
