import React from 'react';
import { FieldArray } from 'redux-form';
import { FieldNames } from 'app/models/fields/names';
import { Button } from 'app/components/Button';
import { requiredArray } from 'app/util/validators';
import { commercialOwnerInitialValue } from 'app/util/initial-values';
import { FormControl } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { localizeLabel } from 'app/i18n/helpers';
import { Name } from '../FormFields/Name';
import { SwitchToggle } from '../FormFields/SwitchToggle';
import { Select } from '../FormFields/Select';
import {
  ETHNICITY_HISPANIC_OPTIONS,
  ETHNICITY_OPTIONS,
  NAME_SUFFIX_OPTIONS,
  RACE_ASIAN_OPTIONS,
  RACE_ISLANDER_OPTIONS,
  RACE_OPTIONS,
  SEX_OPTIONS,
} from '../../models/options/options';
import { TextInput } from '../FormFields/TextInput';
import { DeclarationCheckboxes } from '../FormFields/DeclarationCheckboxes';
import { CheckboxGroup } from '../FormFields/CheckboxGroup';
import { DeclarationText } from '../FormFields/DeclarationText';
import {
  Ethnicity,
  EthnicityHispanic,
  Race,
  RaceAsian,
  RaceIslander,
} from '@lenderful/domain';
import { isCommercialForm } from '../../models/fields/conditionals';

const renderOwners = ({ ...props }) => {
  const { fields, name, t, ownerList, values }    = props;
  const firstNameField        = `${name}.${FieldNames.ownerFirstName}`;
  const middleNameField       = `${name}.${FieldNames.ownerMiddleName}`;
  const lastNameField         = `${name}.${FieldNames.ownerLastName}`;
  const suffixNameField       = `${name}.${FieldNames.ownerSuffixName}`;
  const titleOfPositionField  = `${name}.${FieldNames.ownerTitleHeld}`;
  const percentOwnershipField = `${name}.${FieldNames.ownerPercentage}`;
  const guarantorField        = `${name}.${FieldNames.ownerGuarantor}`;
  const showERGQuestions      = isCommercialForm(props.meta.form);

  const percentValidator = { type: 'isPercentage', param: '' };

  const renderFields = () =>
    fields.map((field, index) => {
      return (
        <div key={ `${ field }-${ index }` } style={ { paddingBottom: 20 } }>
          <Name
            isOptional={ false }
            label={ localizeLabel(firstNameField, t, 'First Name') }
            name={ `${ field }.${ FieldNames.ownerFirstName }` }
          />
          <Name
            isOptional={ true }
            label={ localizeLabel(middleNameField, t, 'Middle Name') }
            name={ `${ field }.${ FieldNames.ownerMiddleName }` }
          />
          <Name
            isOptional={ false }
            label={ localizeLabel(lastNameField, t, 'Last Name') }
            name={ `${ field }.${ FieldNames.ownerLastName }` }
          />
          <Select
            label={ localizeLabel(suffixNameField, t, 'Suffix') }
            name={ `${ field }.${ FieldNames.ownerSuffixName }` }
            options={ NAME_SUFFIX_OPTIONS }
            isOptional={ true }
          />
          <Name
            isOptional={ false }
            label={ localizeLabel(titleOfPositionField, t, 'Title of Position') }
            name={ `${ field }.${ FieldNames.ownerTitleHeld }` }
          />
          <TextInput
            label={ localizeLabel(percentOwnershipField, t, '% Ownership') }
            name={ `${ field }.${ FieldNames.ownerPercentage }` }
            validator={ percentValidator }
          />
          <div style={ { maxWidth: '60%' } }>
            <SwitchToggle
              label={ localizeLabel(guarantorField, t, 'Guarantor') }
              name={ `${ field }.${ FieldNames.ownerGuarantor }` }
            />
          </div>
          { showERGQuestions ?
            <div>
              <DeclarationCheckboxes
                label={ 'Ethnicity' } name={ `${ field }.${FieldNames.ethnicity}` } options={ ETHNICITY_OPTIONS } values={ values }/>
              {
                ownerList[index][FieldNames.ethnicity].includes(Ethnicity.HISPANIC) ?
                  <CheckboxGroup label={ 'Hispanic or Latino' } name={ `${ field }.${FieldNames.ethnicityHispanic}` } options={ ETHNICITY_HISPANIC_OPTIONS }/>
                  :<></> }
              {
                ownerList[index][FieldNames.ethnicityHispanic]?.includes(EthnicityHispanic.OTHER) ?
                  <DeclarationText label={ 'Other Hispanic or Latino' } name={ `${ field }.${FieldNames.ethnicityOther}` } />
                  :<></>
              }
              <DeclarationCheckboxes
                label={ 'Race' } name={ `${ field }.${FieldNames.race}` } options={ RACE_OPTIONS } values={ values }/>
              {
                ownerList[index][FieldNames.race].includes(Race.ASIAN) ?
                  <CheckboxGroup label={ 'Asian' } name={ `${ field }.${ FieldNames.raceAsian }` }
                    options={ RACE_ASIAN_OPTIONS }/>
                  : <></>
              }
              {
                ownerList[index][FieldNames.raceAsian]?.includes(RaceAsian.OTHER) ?
                  <DeclarationText label={ 'Other Asian' } name={ `${ field }.${FieldNames.raceAsianOther}` } />
                  :<></>
              }
              {
                ownerList[index][FieldNames.race].includes(Race.ISLANDER) ?
                  <CheckboxGroup label={ 'Native Hawaiian or Other Pacific Islander' } name={ `${ field }.${ FieldNames.raceIslander }` }
                    options={ RACE_ISLANDER_OPTIONS }/>
                  : <></>
              }
              {
                ownerList[index][FieldNames.raceIslander]?.includes(RaceIslander.OTHER) ?
                  <DeclarationText label={ 'Other Pacific Islander' } name={ `${ field }.${FieldNames.raceIslanderOther}` } />
                  :<></>
              }
              <DeclarationCheckboxes
                label={ 'Sex' } name={ `${ field }.${FieldNames.sex}` } options={ SEX_OPTIONS } values={ values }/>
            </div>
            : <></> }
          { fields.length > 1 && (
            <Button
              disableRipple
              color='primary'
              classes={ {
                root: 'link',
                text: 'remove',
              } }
              onClick={ () => fields.remove(index) }
            >
              { t(`question.${ name }.button.remove`, { defaultValue: 'Remove' }) }
            </Button>
          ) }
        </div>
      );
    });

  return (
    <div>
      <h2>Tell us about all of the Business Owners that have at least 25%</h2>
      {renderFields()}
      <Button
        color    = "primary"
        disabled = {props.meta.invalid}
        variant  = "outlined"
        classes  = {{ root: 'add' }}
        onClick  = {() => fields.push(commercialOwnerInitialValue)}
      >
        {t(`question.${name}.button.add`, { defaultValue: '+ Add Owner' })}
      </Button>
    </div>
  );
};

export const Owners = ({ ...props }) => {
  const { name, options, values } = props;
  const { t }    = useTranslation();
  return (
    <FormControl fullWidth>
      <FieldArray
        component = {renderOwners}
        name      = {name}
        props     = {{ name, t, options, ownerList: values[name], values }}
        validate  = {[
          requiredArray([]),
        ]}
      />
    </FormControl>
  );
};
