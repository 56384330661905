import { RadioField } from 'app/components/FormFields/RadioField';
import {
  AG_EQUIPMENT_TYPE_OPTIONS,
  COMMERCIAL_PURCHASE_TIMING_OPTIONS,
} from 'app/models/options/options';
import { FieldNames } from 'app/models/fields/names';
import { Currency } from 'app/components/FormFields/Currency';
import { CurrencyAllowZero } from '../../components/FormFields/CurrencyAllowZero';
import { TextInput } from '../../components/FormFields/TextInput';
import { Select } from '../../components/FormFields/Select';
import { Integer } from '../../components/FormFields/Integer';
import { Questions } from './Questions';
import { AG_SHARED } from './shared';

export const AGRICULTURAL_PURCHASE_EQUIPMENT_QUESTIONS: Questions = new Questions([
  {
    id         : 1000,
    component  : Select,
    label      : 'Equipment Type',
    name       : FieldNames.agEquipmentType,
    options   : AG_EQUIPMENT_TYPE_OPTIONS,
  },
  {
    id        : 1001,
    component : TextInput,
    label     : 'Additional Equipment Description',
    name      : FieldNames.commercialDescription,
    isOptional : true,
  },
  {
    id        : 1002,
    component : Currency,
    label     : 'Expected Cost',
    name      : FieldNames.commercialExpectedCost,
  },
  {
    id        : 1003,
    component : CurrencyAllowZero,
    label     : 'Down Payment',
    name      : FieldNames.downDollar,
  },
  {
    id           : 1004,
    component    : Integer,
    endAdornment : 'Years',
    label        : 'Equipment Life Expectancy',
    name         : FieldNames.commercialLifeExpectancy,
    validator: {
      type : 'isGreaterThanZero',
      param: FieldNames.commercialLifeExpectancy,
    },
  },
  {
    id        : 1005,
    component : RadioField,
    label     : 'Purchase Timing',
    name      : FieldNames.commercialLoanTiming,
    options   : COMMERCIAL_PURCHASE_TIMING_OPTIONS,
  },
  ...AG_SHARED,
]);
