import { Grid, Slide, Snackbar, makeStyles } from '@material-ui/core';
import { Alert, Color } from '@material-ui/lab';
import { NotificationLevel } from 'app/providers/notifications/Notification';
import { useNotifications } from 'app/providers/notifications/NotificationProvider';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
}));

/**
 * This component is responsible for displaying any user notifications.  It uses a simple Snackbar component and
 * allows you to customize the message, color, and icon.  It relies on the NotificationProvider and the hook `useNotifications()`
 * to receive the notification data from other components. 
 */
export const NotificationComponent = () => {
  const classes = useStyles();
  const { notification, isOpen, setIsOpen } = useNotifications();

  if (!notification) {
    return null;
  }

  const getSeverity = (): Color => {
    if (notification.level === NotificationLevel.SUCCESS) {
      return 'success';
    }
    if (notification.level === NotificationLevel.WARN) {
      return 'warning';
    }
    if (notification.level === NotificationLevel.ERROR) {
      return 'error';
    }
    return 'info';
  };

  return (
    <div className={classes.root}>
      <Snackbar 
        open={isOpen} 
        onClose={() => setIsOpen(false)} 
        TransitionComponent={Slide}
      >
        <Alert 
          variant='filled' 
          onClose={() => setIsOpen(false)} 
          severity={getSeverity()}
          icon={notification.icon} 
          style={{
            alignItems: 'center', 
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
          }}
        >
          <Grid container justifyContent='center' alignItems='center' alignContent='center' spacing={1}>
            <Grid item> {notification.message} </Grid>
          </Grid>
        </Alert>
      </Snackbar>
    </div>
  );
};