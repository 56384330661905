import { LiabilityAccount } from 'app/api/credit-report';
import { Endpoints } from 'app/api/helpers';
import { mapBorrowers, mapSubjectProperty, toSqlDate } from 'app/api/loan-application/mappings';
import {
  AutoValuationModel,
  PurchaseTurboFormRequest,
  RefinanceTurboFormRequest,
  TurboFormRequest,
  TurboProperties,
  UniversalPrefillResponse,
} from 'app/api/loan-application/types';
import { LoanOfficerDetail } from 'app/api/loan-officer';
import { client } from 'app/api/_client';
import {
  isCollectOtherHomeLiabilties,
  isHomeowner,
  isNo,
  isOwningOtherHomes,
  isPrimaryHome,
  isRefinance,
  isTakingCashOut,
  isYes,
} from 'app/models/fields/conditionals';
import { FieldNames } from 'app/models/fields/names';
import {
  EscrowUsage,
  FinancialVerificationIntegration,
  LiabilityUsage,
  PropertyUsage,
  YesOrNo,
} from 'app/models/options/enums';
import { getSlug } from 'app/util/headers';
import { logger } from 'app/util/logger';
import { toNumber } from 'app/util/parsers';
import { ClosingCostType, FormName, LivingSituation } from '@lenderful/domain';

/**
 * This method maps data on the form to the shape of the request for the
 * turbo endpoint to accept.
 *
 * @param formData
 * @param {FormName} formName
 * @param {LoanOfficerDetail} loanOfficer
 * @param {string} borrowerCustomerId
 * @param {string} coBorrowerCustomerId
 * @param {string} orderId
 * @param {number} avmReportId
 * @returns {TurboFormRequest}
 */
export const mapTurboFormRequest = (
  formData,
  formName            : FormName,
  loanOfficer         : LoanOfficerDetail,
  borrowerCustomerId  : string,
  coBorrowerCustomerId: string,
  orderId             : string,
  avmReportId         : number): TurboFormRequest | RefinanceTurboFormRequest | PurchaseTurboFormRequest => {

  const isRefi = isRefinance(formData);
  const properties: TurboProperties[] = [];
  const realEstateLiabilities: LiabilityAccount[] = formData[FieldNames.realEstateLiabilities] || [];

  if (isRefi) {
    // add primary property because we will always have one
    properties.push({
      isCollateral    : isPrimaryHome(formData),
      isPrimary       : true,
      propertyUsage   : PropertyUsage.PRIMARY,
      propertyType    : isPrimaryHome(formData) ? formData[FieldNames.propertyType] : formData[FieldNames.livingPropertyType],
      annualTaxes     : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearTaxes]) : toNumber(formData[FieldNames.livingYearTaxes]),
      annualInsurance : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearInsure]) : toNumber(formData[FieldNames.livingYearInsure]),
      annualHOA       : isPrimaryHome(formData) ? toNumber(formData[FieldNames.yearAssociate]) : toNumber(formData[FieldNames.livingYearAssociate]),
      mortgages: realEstateLiabilities
        .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.PRIMARY_HOME),
    });

    // if main property isn't primary we need to add secondary house as collateral
    if (!isPrimaryHome(formData)) {
      properties.push({
        isCollateral    : true,
        isPrimary       : false,
        propertyUsage   : formData[FieldNames.propertyUsage],
        propertyType    : formData[FieldNames.propertyType],
        annualTaxes     : toNumber(formData[FieldNames.yearTaxes]),
        annualInsurance : toNumber(formData[FieldNames.yearInsure]),
        annualHOA       : toNumber(formData[FieldNames.yearAssociate]),
        mortgages       : realEstateLiabilities
          .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.COLLATERAL_HOME),
      });
    }

    // add other homes on liabilities
    realEstateLiabilities
      .filter((liability: LiabilityAccount) => liability.liabilityUsage === LiabilityUsage.OTHER_HOME)
      .forEach((liability: LiabilityAccount) => {
        properties.push({
          isCollateral    : false,
          isPrimary       : false,
          annualTaxes     : toNumber(formData[FieldNames.liabilityCombinedYearTaxes]),
          annualInsurance : toNumber(formData[FieldNames.liabilityCombinedYearInsure]),
          annualHOA       : toNumber(formData[FieldNames.liabilityCombinedYearAssociate]),
          mortgages       : [{
            liabilityName    : liability.liabilityName,
            liabilityAmount  : liability.liabilityAmount,
            liabilityBalance : liability.liabilityBalance,
            liabilityUsage   : LiabilityUsage.OTHER_HOME,
          }],
        });
      });
  } else { // Purchase Turbo
    // Purchase Property
    properties.push({
      isCollateral    : undefined,
      isPrimary       : true,
      propertyUsage   : formData[FieldNames.propertyUsage],
      propertyType    : formData[FieldNames.propertyType],
      annualTaxes     : toNumber(formData[FieldNames.yearTaxes]),
      annualInsurance : toNumber(formData[FieldNames.yearInsure]),
      annualHOA       : toNumber(formData[FieldNames.yearAssociate]),
      mortgages       : [],
    });
    // Current Resident
    properties.push({
      isCollateral    : undefined,
      isPrimary       : false,
      propertyType    : formData[FieldNames.livingPropertyType],
      mortgages       : [],
    });
  }

  const totalNumberOfProperties = getNumberOfProperties(formData, isRefi);

  // if they answered that they own other homes, add one final property
  if ( (isOwningOtherHomes(formData) || isCollectOtherHomeLiabilties(formData)) && (totalNumberOfProperties > properties.length) ) {
    properties.push({
      isCollateral    : false,
      isPrimary       : false,
      annualTaxes     : toNumber(formData[FieldNames.liabilityCombinedYearTaxes]),
      annualInsurance : toNumber(formData[FieldNames.liabilityCombinedYearInsure]),
      annualHOA       : toNumber(formData[FieldNames.liabilityCombinedYearAssociate]),
      mortgages       : [],
    });
  }

  const loanAmount = getLoanAmount(formData, formName);

  return {
    avm                        : avmReportId ? { ...mapAutoValuationModel(formData), avmReportId } : undefined,
    borrowerCustomerId,
    borrowers                  : mapBorrowers(formData),
    buyingProcess              : formData[FieldNames.buyingProcess],
    cashOut                    : isTakingCashOut(formData) ? formData[FieldNames.cashOut] : undefined,
    closingCostType            : ClosingCostType.EXPRESS,
    coBorrowerCustomerId,
    coBorrowerSsn              : formData[FieldNames.coBorrowerSsn],
    creditReportId             : formData[FieldNames.creditReportId],
    currentInterestRate        : formData[FieldNames.currentInterestRate],
    currentLoanTerm            : formData[FieldNames.currentLoanTerm],
    currentLoanType            : formData[FieldNames.currentLoanType],
    currentMonthlyPayment      : formData[FieldNames.currentMonthlyPayment],
    downPayment                : isRefi ? undefined : formData[FieldNames.downDollar],
    downPaymentShortfall       : isRefi || (formData[FieldNames.financialVerificationIntegration] === FinancialVerificationIntegration.TRUV) ? undefined : calculatePurchaseShortfall(formData[FieldNames.assets], formData[FieldNames.downDollar]) as YesOrNo,
    downPaymentShortfallAmount : isRefi || (formData[FieldNames.financialVerificationIntegration] === FinancialVerificationIntegration.TRUV) ? undefined : calculatePurchaseShortfall(formData[FieldNames.assets], formData[FieldNames.downDollar], true) as number,
    expectedClosingDate        : toSqlDate(formData[FieldNames.expectedClosingDate]),
    formName,
    hasEscrow                 : formData[FieldNames.livingEscrowUsage] === EscrowUsage.YES,
    homeEquityLoanPurpose     : formData[FieldNames.homeEquityLoanPurpose],
    homeEquityLoanPurposeOther: formData[FieldNames.homeEquityLoanPurposeOther],
    loanAmount,
    loanPurpose                : formData[FieldNames.loanPurpose],
    loanOfficerId              : loanOfficer.id,
    loanTerm                   : formData[FieldNames.loanTerm],
    loanType                   : formData[FieldNames.loanType],
    monthlyPayment             : formData[FieldNames.monthlyPayment],
    orderId,
    pointFee                   : formData[FieldNames.fee],
    properties,
    rate                       : formData[FieldNames.rate],
    realEstateLiabilities,
    ssn                        : formData[FieldNames.ssn],
    subjectProperty            : mapSubjectProperty(formData),
    totalNumberOfProperties,
    willHaveEscrow             : formData[FieldNames.escrowUsage],
    willSellHome               : formData[FieldNames.homeSoldYN],
  };
};

const mapAutoValuationModel = (formData): AutoValuationModel | undefined => ({
  avmHomeValue: formData[FieldNames.avmHomeValue],
  avmHomeValueHighLimit: formData[FieldNames.avmHomeValueHighLimit],
  avmHomeValueLowLimit: formData[FieldNames.avmHomeValueLowLimit],
});

/**
 * This API call emails the loan estimate and underwriting PDFs to the loan officer
 *
 * @param {TurboFormRequest} request
 * @returns {any}
 */
export const postTurbo = (request: TurboFormRequest): Promise<any> => {
  const slug = getSlug();
  return client
    .post<any>(Endpoints.EXPRESS_FORM, { ...request, slug })
    .catch((error) => {
      logger.error('ERROR: Failed to send turbo PDFs');
      throw error;
    });
};

/**
 * This API call gets the prefilled data for a home equity turbo application
 *
 * @param {string} hash
 * @returns {Promise<any>}
 */
export const getHomeEquityTurboPrefill = (hash: string): Promise<UniversalPrefillResponse> => {
  const slug = getSlug();
  const endpoint = `${Endpoints.LOANS}/${hash}`;
  return client
    .get<UniversalPrefillResponse>(endpoint, { params: { slug } })
    .then((response) => response.data)
    .catch((error) => {
      logger.error('ERROR: Failed to get home equity turbo prefill data');
      throw error;
    });
};

const getLoanAmount = (formData, formName) => {
  let loanAmount = toNumber(formData[FieldNames.loanAmount]);

  if(isTakingCashOut(formData)) {
    loanAmount += toNumber(formData[FieldNames.cashOut]);
  } else if(formName === FormName.PURCHASE_TURBO) {
    loanAmount = formData[FieldNames.homeValue] - formData[FieldNames.downDollar];
  }
  return loanAmount;
};

/**
 * Calculates the number of properties based on if it is a Refinance/HE or Purchase turbo form
 * @param formData
 * @param isRefinance
 */
const getNumberOfProperties = (formData, isRefinance): number => {
  if(isRefinance) {
    return (isPrimaryHome(formData) ? 1 : 2) + toNumber(formData[FieldNames.liabilityCombinedNumberOtherHomes]);
  } else {
    let total = 1; // Purchase Property;
    if(isHomeowner(formData) && isNo(formData[FieldNames.homeSoldYN])) {
      total += 1;
    }
    if(isYes(formData[FieldNames.coBorrowerYN]) &&
      isNo(formData[FieldNames.coBorrowerSameAddress]) &&
      formData[FieldNames.coBorrowerLivingRentOrOwn] === LivingSituation.OWN) {
      total += 1;
    }
    formData[FieldNames.addlProperties]?.forEach(property => total += 1);
    return total;
  }
};

const calculatePurchaseShortfall = (assets: [], downPayment: number, returnValue?: boolean): YesOrNo | number => {
  const total = assets.reduce((amount, asset) => {
    return amount + asset[FieldNames.accountBalance];
  }, 0);
  const shortfallAmount = total - downPayment;
  if(returnValue) {
    return shortfallAmount >= 0 ? undefined : total - downPayment;
  }
  return shortfallAmount >= 0  ? YesOrNo.NO : YesOrNo.YES;
};

