import React from 'react';
import {
  Grid,
  Typography,
  Paper,
  withTheme,
} from '@material-ui/core';

export const TileBox = withTheme(({ color, children, ...props }: any) => {

  const tileBoxHeader = {
    background: props.theme.palette[color].main,
    padding: '18px 15px',
  };

  return (
    <Paper elevation={2} square={true}>
      <Grid container style={tileBoxHeader}>
        <Grid item xs={12}>
          <Typography
            align     = "center"
            variant   = "body2"
            component = "h2"
            classes   = {{
              root: 'tileBoxTitle',
            }}
            style = {{
              color: props.theme.palette[color].contrastText,
            }}
          >
            {props.title}
          </Typography>
        </Grid>
      </Grid>
      <Grid container {...props.tileBoxProps}>
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
});
