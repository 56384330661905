import React from 'react';
import { Field } from 'redux-form';
import { renderDateBase } from 'app/components/FormFields/_renderDateBase';
import { mapAutoCompleteName } from 'app/util/autocomplete';
import { required, noopValidate, isValidDate, VALIDATORS } from 'app/util/validators';
import moment from 'moment';

export const SingleDate = ({ ...props }) => {
  const { name, isOptional, label, inputClasses, labelClasses } = props;
  /* If the question isOptional, replace the required validator */
  const isRequired =  isOptional ? noopValidate : required;
  const { type, param } = props.validator || { type: '', param: '' };
  const conditionalValidator = VALIDATORS[type]
    ? VALIDATORS[type](param)
    : noopValidate;
  return (
    <Field
      autoComplete = {mapAutoCompleteName(name)}
      component    = {renderDateBase}
      format       = {(date) => date}
      normalize    = {(date: any) => moment(date, 'MM-DD-YYYY').isValid() ? moment(date, 'MM-DD-YYYY').toISOString() : date }
      inputClasses = {inputClasses}
      label        = {label}
      labelClasses = {labelClasses}
      name         = {name}
      validate     = {[isRequired, isValidDate, conditionalValidator]}
    />
  );
};
