import { client } from 'app/api/_client';
import { Endpoints } from 'app/api/helpers';
import { FieldName } from 'app/models/fields/names';
import { getSlug } from 'app/util/headers';
import { getFormName } from '../routes/helpers';
import { FormName } from '@lenderful/domain';

export interface ChecklistRequest {
  formName   : FormName;
  formValues: {
    [key in FieldName]?: any;
  },
  slug: string;
}

export const mapChecklistRequest = (formData, state): ChecklistRequest => {
  const slug = getSlug();

  return {
    formName   : getFormName(state.router.location.pathname),
    formValues : formData,
    slug,
  };
};

export const postChecklist = (body: ChecklistRequest) => {
  return client
    .post(Endpoints.CHECKLIST, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};
